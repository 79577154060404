import { ContextMenuData } from "../Api/map/types";
import { GenericEvent, GenericTask, GenericValue } from "../generics";
import { GenericController } from "./generics";

interface ContextMenuClick {
  menuId: string;
  menuAction: string;
  payload: any;
}
export interface IContextMenu {
  click: GenericEvent<ContextMenuClick>;
  activeMenuId: GenericValue<string>;
  activeMenuData: ContextMenuData;
  data: {
    userIconMenu: GenericValue<ContextMenuData>;
    mapMenu: GenericValue<ContextMenuData>;
    markerMenu: GenericValue<ContextMenuData>;
  };
}
interface openMenuAction {
  contextMenuCoords: [number, number];
  mapSize: { x: number; y: number };
  payload: any;
  contextMenuId: string;
}
export class ContextMenu extends GenericController {
  public activeMenuId: GenericValue<"userIconMenu" | "mapMenu" | "markerMenu">;

  public click: GenericEvent<ContextMenuClick>;

  public open: GenericEvent<openMenuAction>;

  public data: {
    userIconMenu: GenericValue<ContextMenuData>;
    mapMenu: GenericValue<ContextMenuData>;
    markerMenu: GenericValue<ContextMenuData>;
  };

  public get activeMenuData(): ContextMenuData {
    if (!this.activeMenuId.value) {
      return undefined;
    }
    return this.data[this.activeMenuId.value].value;
  }

  constructor(setTriggers: (triggers: any) => void) {
    super();
    const triggers = {} as any;

    this.open = new GenericEvent<openMenuAction>(t => {
      triggers.open = t;
    });

    this.activeMenuId = new GenericValue<
      "userIconMenu" | "mapMenu" | "markerMenu"
    >(
      t => {
        triggers.activeMenuId = t;
      },
      undefined,
      1
    );

    this.click = new GenericEvent<ContextMenuClick>(
      trigger => (triggers.click = trigger)
    );

    triggers.data = {} as any;
    this.data = {
      userIconMenu: new GenericValue<ContextMenuData>(
        trigger => {
          triggers.data.userIconMenu = trigger;
        },
        undefined,
        1
      ),
      markerMenu: new GenericValue<ContextMenuData>(
        trigger => {
          triggers.data.markerMenu = trigger;
        },
        undefined,
        1
      ),
      mapMenu: new GenericValue<ContextMenuData>(
        trigger => {
          triggers.data.mapMenu = trigger;
        },
        undefined,
        1
      )
    };

    Object.keys(this.data).forEach(menuKey => {
      (this.data[menuKey] as GenericValue<ContextMenuData>).subscribeAsWorker(
        params => {
          if (this.activeMenuId.workersCount) {
            this.activeMenuId.require(undefined);
          }
          triggers.data[menuKey](params);
        }
      );
    });

    setTriggers(triggers);
  }
}
