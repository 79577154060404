import { RouteContent } from "multigo-map-api";
// import { apiTerminalObjects } from "../../../utils/api";
import { IPlainRoute, PlainRoute } from "./route";
import { PoiWithScroller } from "./types";
import { FilterSet } from "multigo-map-api/types/Controllers/Api/map/types";

export interface IBuiltRoute extends IPlainRoute {
  filterSets: FilterSet[];
  buildStatus: BuildStatus;
  buildContent: RouteContent;
  activePOI: number;
  resetBuild: () => void;
  isViewAll: boolean;
  setActiveMarker: (id: string) => void;
  // api: I
}

export interface BuildStatus {
  statusErr?: string;
  err?: number;
  statusLast?: string;
  end: number;
  udt?: string;
  percent?: number;
}
interface BasicSet {
  type: number;
  prop: string;
  name: string;
  min?: number;
  max?: number;
  values?: number[] | Array<{ _id: number; name: string }>;
}

interface Vehicle {
  filterTypes: number[];
  id: string;
  name: string;
  routerProp: string;
  routerValue: number;
}

export interface SettingsRow extends BasicSet {
  value: string | number;
  handler: (newValue: string | number) => void;
}
export interface RouterSet extends BasicSet {
  visible: 1 | 0;
  defValue: number;
  ctrl: {
    enable: 0 | 1;
    if: string;
  };
}
export class BuiltRoute extends PlainRoute implements IBuiltRoute {
  public get isViewAll(): boolean {
    return this._isViewAll;
  }
  public set isViewAll(value: boolean) {
    if (this._isViewAll !== value) {
      this._isViewAll = value;
      this.api.drawRouteById(this._content._id, value);
      this.update();
    }
  }

  public set buildContent(newContent: RouteContent) {
    this._content = newContent;
    this.api.setMarkersDisplayState(!Boolean(newContent));
    this.update();
  }

  public get buildContent(): RouteContent {
    return this._content;
  }
  public get activePOI() {
    return this._activePOI;
  }
  public set activePOI(value) {
    this._activePOI = value;
    const poi = this._content.list[value] as PoiWithScroller;
    if (poi) {
      if (poi.lat || poi.latOnRoad) {
        this.api.setViewPort({
          center:
            poi.lat && poi.lng
              ? [poi.lat, poi.lng]
              : ([poi.latOnRoad, poi.lngOnRoad] as [number, number]),
          zoom: 14
        });
      }
      if (poi.scrollInList) {
        poi.scrollInList();
      }
      if (poi.poi_id) {
        this.api.setActiveMarker(poi.poi_id as string);
      }
      this.update();
    }
  }
  public set buildStatus(data: BuildStatus) {
    this._buildStatus = data;
    this.update();
  }
  public get buildStatus(): BuildStatus {
    return this._buildStatus;
  }

  public filterSets: FilterSet[] = [];
  private _isViewAll: boolean = false;

  protected _content: RouteContent;

  private _activePOI: number;
  protected _buildStatus: BuildStatus;
  public setActiveMarker(id: string) {
    const poiIndex = this._content.list.findIndex(p => p.poi_id === id);
    if (poiIndex) {
      this.activePOI = poiIndex;
    }
  }

  public resetBuild = () => {
    this.api.removeRouteById(this.buildContent._id);

    this._buildStatus = undefined;
    this._content = undefined;
    this.api.setMarkersDisplayState(true);
    this._isViewAll = false;
    this.update();
  };
}
