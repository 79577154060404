import { InitData, MapData } from "../Api/map/types";
import { GenericEvent, GenericTask, GenericValue } from "../generics";
import { ContextMenu, IContextMenu } from "./contextMenu";
import { GenericController } from "./generics";
import { ILayerController, LayersController } from "./layers";
import { IRoadsController, RoadsController } from "./roads";
import { MarkerEvent } from "./types";

export interface IMapController {
  init: GenericTask<{ apiKey?: string }, Partial<InitData>>;

  mapLoaded: GenericEvent<{ apiKey: string; updater: () => void }>;
  markerAdd: GenericEvent<MarkerEvent>;

  markerRemove: GenericTask<string, boolean>;
  zoomInc: GenericTask<void, number>;
  zoomDec: GenericTask<void, number>;
  setFilter: GenericTask<object, boolean>;
  setLocatorFilter: GenericTask<{ filter: object; vt: string }, boolean>;

  userCoordinates: GenericValue<[number, number]>;
  userDragEnd: GenericEvent<[number, number]>;
  zoom: GenericValue<number>;
  fuelsSwitchButton: GenericValue<number>;
  center: GenericValue<[number, number]>;
  setViewPort: GenericTask<{ center: [number, number]; zoom: number }, boolean>;
  activeMarker: GenericValue<{
    id?: string;
    coords?: [number, number];
    _id: string;
  }>;
  redraw: GenericTask<void, void>;

  layers: ILayerController;
  data: MapData;
  contextMenu: IContextMenu;
  roads: IRoadsController;
  updater: () => void;
}

export class MapController extends GenericController implements IMapController {
  public init: GenericTask<{ apiKey?: string }, Partial<InitData>>;

  public mapLoaded: GenericEvent<{
    apiKey: string;
    updater: () => void;
  }>;

  public markerAdd: GenericEvent<MarkerEvent>;

  public markerRemove: GenericTask<string, boolean>;

  public zoomInc: GenericTask<void, number>;

  public zoomDec: GenericTask<void, number>;

  public setFilter: GenericTask<object, boolean>;

  public setLocatorFilter: GenericTask<{ filter: object; vt: string }, boolean>;

  public hoverMarker: GenericValue<boolean>;

  public userCoordinates: GenericValue<[number, number]>;

  public userDragEnd: GenericEvent<[number, number]>;

  public zoom: GenericValue<number>;

  public center: GenericValue<[number, number]>;

  public redraw: GenericTask<void, void>;

  public setViewPort: GenericTask<
    { center: [number, number]; zoom: number },
    boolean
  >;

  public fuelsSwitchButton: GenericValue<number>;

  public activeMarker: GenericValue<{
    id?: string;
    coords?: [number, number];
    _id: string;
  }>;

  public layers: LayersController;

  public contextMenu: IContextMenu;

  public roads: RoadsController;

  public data: MapData;

  constructor(setMapTriggers: any, defaultState: any) {
    super();
    const triggers = {} as any;

    this.activeMarker = new GenericValue<{
      id: string;
      coords: [number, number];
      _id: string;
    }>(
      t => {
        triggers.activeMarker = t;
      },
      undefined,
      2
    );

    this.setFilter = new GenericTask<object, boolean>(t => {
      triggers.setFilter = t;
    }, 1);

    this.setLocatorFilter = new GenericTask<
      { filter: object; vt: string },
      boolean
    >(t => {
      triggers.setLocatorFilter = t;
    }, 1);

    this.markerAdd = new GenericEvent<MarkerEvent>(t => {
      triggers.markerAdd = t;
    });

    this.markerRemove = new GenericTask<string, boolean>(t => {
      triggers.markerRemove = t;
    }, 1);

    this.userCoordinates = new GenericValue<[number, number]>(trigger => {
      triggers.userCoordinates = trigger;
    }, defaultState.userCoordinates);

    this.userDragEnd = new GenericEvent<[number, number]>(trigger => {
      triggers.userDragEnd = trigger;
    });

    this.fuelsSwitchButton = new GenericValue<number>(trigger => {
      triggers.fuelsSwitchButton = trigger;
    }, defaultState.fuelsSwitchButton);

    this.zoom = new GenericValue<number>(trigger => {
      triggers.zoom = trigger;
    }, defaultState.zoom);

    this.hoverMarker = new GenericValue<boolean>(trigger => {
      triggers.hoverMarker = trigger;
    }, true);

    this.zoomInc = new GenericTask<void, number>(trigger => {
      triggers.zoomInc = trigger;
    }, 1);

    this.zoomDec = new GenericTask<void, number>(trigger => {
      triggers.zoomDec = trigger;
    }, 1);

    this.center = new GenericValue<[number, number]>(trigger => {
      triggers.center = trigger;
    }, undefined);

    this.setViewPort = new GenericTask<
      { center: [number, number]; zoom: number },
      boolean
    >(trigger => (triggers.setViewPort = trigger), 1);

    this.redraw = new GenericTask<void, void>(
      trigger => (triggers.redraw = trigger),
      1
    );

    this.init = new GenericTask<{ apiKey?: string }, Partial<InitData>>(
      trigger => {
        triggers.init = trigger;
      },
      2
    );

    this.mapLoaded = new GenericEvent<{
      apiKey: string;
      updater: () => void;
    }>(trigger => {
      triggers.mapLoaded = trigger;
    });

    this.layers = new LayersController(layerTriggers => {
      triggers.layers = layerTriggers;
    }, defaultState.layers || {});
    ["userLayer", "markersLayer", "roadsLayer"].forEach(layerName => {
      (this.layers[layerName] as GenericValue<{
        display: boolean;
      }>).subscribeSelf(() => this.updater());
    });

    this.contextMenu = new ContextMenu(contextMenuTriggers => {
      triggers.contextMenu = contextMenuTriggers;
    });

    this.roads = new RoadsController(roadTriggers => {
      triggers.roads = roadTriggers;
    });

    triggers.data = (newData: MapData) => {
      this.data = newData;
    };

    const workers = [];
    this.zoom.subscribeSelf(() => {
      this.updater();
    });
    this.center.subscribeSelf(() => {
      this.updater();
    });
    this.userCoordinates.subscribeSelf(() => {
      this.updater();
    });
    this.activeMarker.subscribeSelf(() => {
      this.updater();
    });
    this.hoverMarker.subscribeSelf(() => {
      this.updater();
    });

    workers.push(
      this.zoomInc.subscribeAsWorker(async () => {
        const newZoom = await this.zoom.setValue(this.zoom.value + 1);
        triggers.zoomInc(newZoom);
        this.updater();
      })
    );

    workers.push(
      this.zoomDec.subscribeAsWorker(async () => {
        const newZoom = await this.zoom.setValue(this.zoom.value - 1);
        triggers.zoomDec(newZoom);
        this.updater();
      })
    );

    setMapTriggers(triggers);
  }

  public updater = () => {
    //
  };
}
