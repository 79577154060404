import { settings } from "cluster";
import { vi } from "date-fns/locale";
import { Vehicle } from "multigo-map-api/types/Controllers/Api/map/types";
import { ClassType } from "../../../utils/types/classType";
import { BuiltRoute, IBuiltRoute } from "./builtRoute";
import { ISettingsMixin, SettingsMixin } from "./settingsMixin";

export interface IRouteWithSettingsMixin extends IBuiltRoute, ISettingsMixin {}

export interface IRouteWithSettings extends IRouteWithSettingsMixin {
  runRoute: () => void;
  getSavedRoute: (routeId: string, viewMode: boolean) => void;
  stopRouteBuild: () => void;
  savedVehicles: Vehicle[];
  selectedVehicleId?: string;
  selectedVehicle?: Vehicle;
}
class RouteWithSettingsMixed extends SettingsMixin(BuiltRoute) {
  public set savedVehicles(vehicleList: Vehicle[]) {
    if (vehicleList && !("isFake" in vehicleList)) {
      this._savedVehicles = vehicleList;
      this.update();
    }
  }
  public get savedVehicles(): Vehicle[] {
    return this._savedVehicles || [];
  }
  private _selectedVehicleId: string;
  public get selectedVehicleId(): string {
    return this._selectedVehicleId;
  }
  public set selectedVehicleId(value: string) {
    this._selectedVehicleId = value;
  }
  public get selectedVehicle(): Vehicle {
    return this.savedVehicles.find((v) => v.id === this._selectedVehicleId);
  }
  private _savedVehicles: Vehicle[] = [];

  public runRoute = () => {
    const params = {
      ll: this.points
        .map((point) => `${point.coords.lat},${point.coords.lng}`)
        .join(";"),
      options: JSON.stringify(this.getSettingsDict()),
      controlledOptions: JSON.stringify(this.getSettingsDict(true)),
      ...(this._selectedVehicleId
        ? { vehicleId: this._selectedVehicleId }
        : {}),
    };

    this.api.runRoute(params);
  };
  public stopRouteBuild = async () => {
    await this.api.stopRouteBuild();
    this._content = undefined;
    this._buildStatus = undefined;

    this.update();
  };
  public getSavedRoute = (routeId: string, viewMode: boolean) => {
    this.api.getRoute(routeId, viewMode);
  };
}

export const RouteWithSettings = RouteWithSettingsMixed as ClassType<
  IRouteWithSettings
>;
