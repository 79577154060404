import { SelectField } from "multigo-ui";
import * as React from "react";

export const ValueSelector = props => {
  return (
    <>
      <p className="route-settings-name">{props.field.name}</p>
      <SelectField
        // itemLabel="_id"
        // id={props.field.prop}
        value={props.field.value}
        onChange={props.field.handler}
        // className="md-cell"
        menuItems={
          props.field.values.every(value => typeof value === "number")
            ? props.field.values.map(v => ({ id: v, name: v }))
            : props.field.values.map(({ _id, name }) => ({ id: _id, name }))
        }
        // simplifiedMenu={true}
        // {...(
      />
    </>
  );
};
