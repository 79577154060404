import propTypes from "prop-types";
import * as React from "react";

(window as any).react = React;
window["prop-types"] = propTypes;

export class MapLoader {
  // private static stagingUrl = "https://map-debug.multigo.ru/staging";
  // private static productionUrl = "https://api7.multigo.ru/static/map/1.0";

  private static stagingUrl = "https://api7d.multigo.ru/static/map/1.2";
  private static productionUrl = "https://api7.multigo.ru/static/map/1.2";


  private debug: boolean;

  private _callback: () => void;

  constructor(debug: boolean, callback?: () => void) {
    this.debug = debug;
    this._callback = callback;
  }

  public load() {
    const existingScript = document.getElementById("MultigoMap");
    if (!existingScript) {
      const script = this.loadJS();
      script.onload = () => {
        this.callback();
      };
      this.loadCSS();
    } else {
      this.callback();
    }
  }

  private loadJS(): HTMLScriptElement {
    const script = document.createElement("script");
    script.src = this.getURL("index.js");
    script.id = "multigoMap";
    script.async = true;
    document.body.appendChild(script);
    return script;
  }

  private getURL(fileName: string) {
    return `${
      this.debug ? MapLoader.stagingUrl : MapLoader.productionUrl
    }/${fileName}?${this.getSalt()}`;
  }

  private getSalt(): string {
    return Math.random()
      .toString(36)
      .slice(2);
  }

  private loadCSS() {
    const css = document.createElement("link");
    css.href = this.getURL("styles.css");
    css.rel = "stylesheet";
    css.type = "text/css";
    document.body.appendChild(css);
  }

  private callback() {
    if (this._callback) {
      this._callback();
    }
  }
}
