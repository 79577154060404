export class GenericController {
  constructor() {
    return new Proxy(this, {
      get(target, name) {
        if (name === "isFake") {
          return target[name] === undefined;
        }
        if (typeof name !== "symbol" && typeof target[name] === "undefined") {
          if (name === "subscribeAsListener" || name === "subscribe") {
            return () => ({ remove: () => {}, isFakeListener: true });
          } else {
            return new GenericController();
          }
        } else {
          return target[name];
        }
      }
    });
  }
}
