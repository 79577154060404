import { buildChildren } from "@babel/types";
import { RouteContent } from "multigo-map-api";
import { RouterSettingsInfo } from "multigo-map-api/types/Controllers/Api/types";
import { WithTooltip } from "multigo-ui";
import { object } from "prop-types";
import React from "react";
import { useIsMobile } from "../../../../../../utils/hooks/useMobileDetect";
import { Label } from "./Label";
import { RTOObj, RTOSetting } from "./RTOSettings";
// S - серверные настройки, P,U - пользовательские, P - видимые, U - нет

const SimpleSetting: React.FC<{
  obj: RouterSettingsInfo;
}> = props => {
  if (props.obj.type < 3) {
    return (
      <div
        className={`route-info__item ${
          props.obj.type === 2 ? "subtitle" : "title"
        }`}
      >
        {props.obj.name}
      </div>
    );
  }
  const getValue = (): string => {
    if (!props.obj.val) {
      return "";
    }
    if (typeof props.obj.val === "string") {
      return props.obj.val;
    }
    return (props.obj.val || []).reduce(
      (acc, curr) =>
        acc + Object.keys(curr).reduce((val, key) => val + " " + curr[key], ""),
      ""
    );
  };
  return (
    <div className={"route-info__item" + (props.obj.src === 0 ? " error" : "")}>
      <div className="col_1">
        <WithTooltip
          style={{ maxWidth: "350px", width: "150px" }}
          text={props.obj.tooltip}
        >
          {props.obj.name}
        </WithTooltip>
      </div>
      <div className="col_2">{getValue()}</div>
      <div className="col_3">
        <Label label={props.obj.src} />
      </div>
    </div>
  );
};

export const RouteSettings: React.FC<{
  buildContent: RouteContent;
}> = props => {
  const isMobile = useIsMobile();
  return (
    <div
      className={`route-info__group route-settings${isMobile ? " mobile" : ""}`}
    >
      {props.buildContent.routerSettingsInfo.map((block, index) => (
        <React.Fragment key={String(block.prop || "" + index)}>
          <SimpleSetting obj={block} />
          {block.prop === "calcRTO" &&
          ((props.buildContent.info.optionsExt.RTO as any) as RTOObj) &&
          ((props.buildContent.info.optionsExt.RTO as any) as RTOObj).value
            .length ? (
            <RTOSetting
              src={block.src}
              obj={(props.buildContent.info.optionsExt.RTO as any) as RTOObj}
            />
          ) : (
            ""
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
