import { APIController, IAPIController } from "./Api";
import { IMapController, MapController } from "./Map";
import { IRouterController } from "./Router/router";
import { RouterBuilder } from "./Router/routerBuilder";

export interface IController {
  api: IAPIController;
  map: IMapController;
  router: IRouterController;
  unsafeMapTriggers: any;
}

export class Controller implements IController {
  public api: IAPIController;

  public map: IMapController;

  public router: IRouterController;

  public unsafeMapTriggers: any;

  constructor(debug: boolean, setMapTriggers: any) {
    const mapData = {} as any;
    this.api = new APIController(debug) as IAPIController;
    this.map = new MapController(newMapTriggers => {
      mapData.triggers = newMapTriggers;
    }, {}) as IMapController;
    this.router = new RouterBuilder(
      newRouterTriggers => (mapData.triggers.router = newRouterTriggers),
      this.api
    ).build() as IRouterController;
    // settings api handlers
    const initWorker = this.map.init.subscribeAsWorker(async initData => {
      try {
        const apiRes = await this.api.map.init(initData.apiKey);
        mapData.triggers.data({
          terminalId: apiRes.terminalId,
          icons: apiRes.icons || [],
          tileLayers: {
            tiles: apiRes.tileLayers ? apiRes.tileLayers.tiles : []
          },
          fuelDictList: apiRes.fuelDictList,
          meIcon: apiRes.meIcon,
          locator: apiRes.locator,
          mapSettings: apiRes.mapSettings || {
            controlPanel: {
              position: {
                x: "right", // ['left','right']
                y: "middle" // ['middle', 'top', 'bottom']
              },
              hoverTiles: true
            }
          }
        });

        this.api.geocoder.setKey(
          apiRes.locator ? apiRes.locator.geocoderKey : ""
        );
        try {
          this.map.layers.activeTileId.setIfEmpty(
            apiRes.activeTileId,
            mapData.triggers.layers.activeTileId
          );
          this.map.fuelsSwitchButton.setIfEmpty(
            apiRes.fuelsSwitchButton,
            mapData.triggers.fuelsSwitchButton
          );
          this.map.zoom.setIfEmpty(apiRes.viewPort.zoom, mapData.triggers.zoom);
          this.map.center.setIfEmpty(
            apiRes.viewPort.center,
            mapData.triggers.center
          );
          ["mapMenu", "markerMenu", "userIconMenu"].forEach(contextMenuName => {
            this.map.contextMenu.data[contextMenuName].setIfEmpty(
              apiRes.mapContextMenus[contextMenuName],
              mapData.triggers.contextMenu.data[contextMenuName]
            );
          });
        } catch (err) {
          //
        }

        mapData.triggers.init(apiRes);
      } catch (err) {
        mapData.triggers.init(err);
      }
    });

    const activeFuelWorker = this.map.fuelsSwitchButton.subscribeAsWorker(
      async newFuelId => {
        await this.api.map.setFuel(newFuelId);
        mapData.triggers.fuelsSwitchButton(newFuelId);
      }
    );

    const mapLoadedListener = this.map.mapLoaded.subscribe(async data => {
      this.map.updater = data.updater;
      this.map.updater();
    });

    const filterListener = this.map.setFilter.subscribeAsWorker(
      async params => {
        await this.api.map.setFilter(params);
        mapData.triggers.setFilter(true);
      }
    );

    const locatorFilterListener = this.map.setLocatorFilter.subscribeAsWorker(
      async (params: { filter: object; vt: string }) => {
        await this.api.map.setLocatorFilter(params);
        mapData.triggers.setLocatorFilter(true);
      }
    );

    setMapTriggers(mapData.triggers);
    this.unsafeMapTriggers = mapData.triggers;
  }
}
