import { format, parseISO } from "date-fns";
import { RouteContent } from "multigo-map-api";
import React, { useEffect, useState } from "react";
import Clock from "../../../../../assets/icons/calendar.svg";
import { RoutePoint } from "../../../data/point";
import { IRouteWithSettings } from "../../../data/routeWithSettings";

export const RoutePoints: React.FC<{
  buildContent: RouteContent;
  reverseGeocoder: (ll: { lat: number; lng: number }) => Promise<string>;
  points: RoutePoint[];
}> = (props) => {
  const [middlePoints, setmiddlePoints] = useState(
    [] as Array<{
      name: string;
      date: string;
      time: string;
    }>
  );
  const getMiddlePoint = (point, name) => {
    const date = parseISO(point.timeTimestamp); // new Date(point.timeTimestamp);

    return {
      time: point.timeTimestamp ? format(date, "HH:mm") : "",
      date: point.timeTimestamp ? format(date, "dd.MM.yyyy") : "",
      name,
    };
  };

  const getMiddlePoints = async () => {
    const builtMiddlePoints = props.buildContent.list.filter(
      (obj) => obj.type_id == 34 || obj.type_id == 35 || obj.type_id == 36
    );

    if (props.points.length === builtMiddlePoints.length) {
      setmiddlePoints(
        builtMiddlePoints.map((point, idx) => {
          return getMiddlePoint(point, props.points[idx].name);
        })
      );
    } else {
      Promise.all(
        builtMiddlePoints.map(async (point, idx) => {
          const name = await props.reverseGeocoder({
            lat: point.lat,
            lng: point.lng,
          });
          point.name = name;
          return getMiddlePoint(point, name);
        })
      ).then(setmiddlePoints);
    }
  };
  /**
  |--------------------------------------------------
  | Хуки используются из-за того, что есть вероятность, что число точек в форме не совпадет
  | с числом точек в построенном маршруте, тогда используется геокодер
  |--------------------------------------------------
  */
  useEffect(() => {
    getMiddlePoints();
  }, []);
  return (
    <div className="route-puncts">
      <div className="wrapper">
        {middlePoints.map((item, idx) => {
          return (
            <div key={idx} className="route-puncts__item">
              <span className="pin">{idx + 1}</span>
              <div className="route-puncts__content">
                <div className="address">{item.name}</div>
                {item.time && item.date && (
                  <>
                    <Clock />
                    <p className="time-year">
                      <span className="time">{item.time}</span>
                      <span className="year">{item.date}</span>
                    </p>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
