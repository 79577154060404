import React, { useContext, useState } from "react";
import { PoiContext } from "./poiContext";
import { cn } from "@bem-react/classname";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import CopyIcon from "../../assets/icons/copy-icon.svg";
import DoneIcon from "../../assets/icons/done.svg";
import "./style/navigation.scss";
import { roundPlus } from "../RoutePanel/components/BuiltRoute/utils/converters";
const cN = cn("routecard-navigation");

export const copyToClipboard = (text: string) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

export const Navigation: React.FC = () => {
  const [copyDone, setCopyDone] = useState(false);
  const poiContext = useContext(PoiContext);
  const visibleParts = poiContext.visibleParts;

  const poi = poiContext.poiContent;
  if (!(poi && poi.ll)) {
    return null;
  }
  const googleUrl = `https://www.google.com/maps/dir//${poi.ll.join(
    ","
  )}/@${poi.ll.join(",")},12z`;
  const yandexUrl = `https://yandex.ru/maps/?ll=${poi.ll
    .reverse()
    .join("%2C")}&mode=routes&rtext=~${poi.ll
    .reverse()
    .join("%2C")}&rtt=auto&ruri=~&z=10`;

  const llString = poi.ll.map((x) => roundPlus(x, 6)).join(", ");
  function onCopyLL() {
    const isCopied = copyToClipboard(llString);
    setCopyDone(isCopied);
  }

  return (
    <>
      {visibleParts.showObjectLL === 1 && (
        <div
          className={cN("link")}
          title="Кликните для копирования в буффер обмена"
        >
          <div onClick={onCopyLL} className={cN("string")}>
            {llString} {!copyDone && <CopyIcon className={cN("copy")} />}
            {copyDone && <DoneIcon className={cN("copy-done")} />}
          </div>
        </div>
      )}
      <NavigationString name="Яндекс карты" url={yandexUrl} />
      <NavigationString name="Google Maps" url={googleUrl} />
    </>
  );
};

const NavigationString: React.FC<{ name: string; url: string }> = (props) => (
  <a href={props.url} target="_blank" className={cN("link")}>
    <div className={cN("string")}>
      {props.name} <ArrowLeft className={cN("arrow-right")} />
    </div>
  </a>
);
export default Navigation;
