import {
  FullPoiData,
  FullPoiInfo,
} from "multigo-map-api/types/Controllers/Api/map/types";
import {
  DefaultResponse,
  RefuelInfo,
} from "multigo-map-api/types/Controllers/Api/types";
import { createContext } from "react";
import { RouteNavigationProps } from "../RoutePanel/components/BuiltRoute/RouteCard/RouteInfo/RouteNavigation";
import { PoiFuelInfo } from "./FuelTankInfo";
import { NavigationPoints, NavigationSchedule } from "./RouteNavigation";

interface IPoiContext {
  poiId: string;
  points: NavigationPoints;
  schedule: NavigationSchedule;
  getPoiInfo: (
    poiId: string
  ) => Promise<DefaultResponse<{ object: FullPoiData }>>;
  navigation?: RouteNavigationProps;
  poi?: PoiFuelInfo;
  poiContent?: FullPoiInfo;
  fuelPriceColors?: Array<{
    min: number;
    max: number;
    color: string;
    bg: string;
  }>;
  fuelName?: string;
  fuelIndex?: number;
  avgPrice?: number;
  price?: number;
  fuelCard?: string;
  mapNavigation: (center: [number, number]) => void;
  refuelsList?: RefuelInfo[];
  vCatData?: RefuelInfo;
  visibleParts: {
    hideTP?: number;
    hideYPay?: number;
  };
}
export const PoiContext = createContext(undefined as IPoiContext);
