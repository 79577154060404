import * as React from "react";
import { useRef, useState } from "react";

import { Autocomplete } from "multigo-ui";
import pDebounce from "p-debounce";

import { GeocoderParams } from "../api";

interface EditPointProps {
  name?: string;
  isValidName?: boolean;
  geocoder: (
    q: string,
    params?: GeocoderParams
  ) => Promise<Array<{ ll: [number, number]; address: string }>>;
  editPointHandler: (
    latlng: { lat: number; lng: number },
    name: string,
    pointId?: number
  ) => void;
}
export const EditPoint = (props: EditPointProps) => {
  const [query, setQuery] = useState([]);
  const [poiQuery, setPoiQuery] = useState([]);

  const ref = useRef(null);

  // useEffect(() => {
  //   if (ref.current && ref.current.addEventListener) {
  //     ref.current.addEventListener("mousedown", event => {
  //       event.preventDefault();
  //     });
  //   }
  // }, [ref.current]);

  const getQueryDebounced = async (text: string) => {
    const queryset = await props.geocoder(text);
    const set = queryset.map((point, index) => ({
      ...point,
      text: point.address,
      id: index,
    }));
    setQuery(set);
    return set;
    // TODO: add user poi
    // if (queryset) {
    //   setPoiQuery(
    //     queryset.map((point, index) => ({ ...point, id: "poi" + index }))
    //   );
    // } else {
    //   setPoiQuery([]);
    // }
  };

  const getQuery = pDebounce(getQueryDebounced, 250);

  const handleAutocomplete = (e: string) => {
    if (e === "") {
      return;
    }
    const match = ("" + e).match(/poi(\d+)/);
    if (match) {
      props.editPointHandler(
        { lat: poiQuery[match[1]].ll[0], lng: poiQuery[match[1]].ll[1] },
        poiQuery[match[1]].text
      );
    } else {
      props.editPointHandler(
        { lat: query[e].ll[0], lng: query[e].ll[1] },
        query[e].text
      );
    }
  };

  return (
    <div className="edit-point-autocomplete">
      <Autocomplete
        {...ref}
        editablePlaceholder={props.isValidName !== false && Boolean(props.name)}
        defaultText={props.name || "Город, улица, адрес"}
        onAutocomplete={handleAutocomplete}
        getSuggests={getQuery}
      />
    </div>
  );
};
