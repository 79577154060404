import React from "react";
import { IRouteWithSettings } from "../../../data/routeWithSettings";
import { Button, Checkbox } from "multigo-ui";
import ArrowLeft from "../../../../../assets/icons/arrow-left.svg";
import { useMobileDetect } from "../../../../../utils/hooks/useMobileDetect";

interface RouteControlPanelProps {
  closeRoute: () => void;
  isViewAll: boolean;
  setIsViewAll: (newValue: boolean) => void;
  viewMode: boolean;
}
export const RouteControlPanel: React.FC<RouteControlPanelProps> = (props) => {
  const viewAllHandler = () => {
    props.setIsViewAll(!props.isViewAll);
  };
  const mobileDetector = useMobileDetect();

  return (
    <>
      {!props.viewMode && mobileDetector.isDesktop() ? (
        <div className="built-route-header">
          <div onClick={props.closeRoute} className="close-route-button">
            <ArrowLeft />
          </div>
          <span>Назад</span>
        </div>
      ) : (
        ""
      )}

      <div className="buit-route-show-more">
        <Checkbox
          text="Показать подробно"
          checked={props.isViewAll}
          onChange={viewAllHandler}
        />
      </div>
      {/* <Button
        primary={true}
        size="m"
        // href={props.route.yandexURL}
      >
        Открыть в Я.Навигаторе
      </Button> */}
    </>
  );
};
