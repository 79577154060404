import "./routeInfo.scss";

import React, { useState } from "react";

import { RouteContent } from "multigo-map-api";
import { LocatorSettings } from "multigo-map-api/types/Controllers/Api/map/types";
import { SaveRouteParams } from "multigo-map-api/types/Controllers/Router/routerBuilder";
import { Accordion, Button } from "multigo-ui";
import { FuelType } from "src/components/RoutePanel/data/routeFilters";

import Arrowleft from "../../../../../assets/icons/arrow-left.svg";
import { useMobileDetect } from "../../../../../utils/hooks/useMobileDetect";
import { RoutePoint } from "../../../data/point";
import { SendToWialon } from "../../../data/types";
import { distanceKm } from "../utils/converters";
import { SaveSend } from "./ProcessRoute/SaveSend";
import { RouteParams } from "./RouteParams";
import { RoutePoints } from "./RoutePoints";
import { RoutePuncts } from "./RoutePuncts";
import { RouteSettings } from "./RouteSettings";
import { SimpleProcessRoute } from "./SimpleProcessRoute";

interface RouteInfoProps {
  buildContent: RouteContent;
  reverseGeocoder: (ll: { lat: number; lng: number }) => Promise<string>;
  points: RoutePoint[];
  locatorSettings: LocatorSettings;
  excludedIds: string[];
  saveRoute: (
    params: SaveRouteParams
  ) => Promise<{ shortId: string; shortUrl: string }>;
  sendToWialon?: SendToWialon;
  fuelTypes: FuelType[];
  viewMode: boolean;
}

export const RouteInfo: React.FC<RouteInfoProps> = (props) => {
  const { info, isDriver } = props.buildContent;
  const visible = props.buildContent.visible || {};

  // Данные для аккордиона
  const mobileDetector = useMobileDetect();
  const [sendRouteIsOpen, setSendRouteIsOpen] = useState(false);
  const open = () => setSendRouteIsOpen(true);
  const close = () => setSendRouteIsOpen(false);
  const items = [
    {
      title: "Пункты маршрута",
      body: <RoutePuncts buildContent={props.buildContent} />,
    },
  ];

  if (visible.hideRSets !== 1) {
    items.push({
      title: "Условия планирования",
      body: <RouteSettings buildContent={props.buildContent} />,
    });
  }

  if (visible.hidePlan !== 1) {
    items.push({
      title: "Плановые показатели",
      body: (
        <RouteParams
          buildContent={props.buildContent}
          fuelTypes={props.fuelTypes}
        />
      ),
    });
  }

  // items.push({
  //   title: "Сохранить / отправить",
  //   body: (
  //     <SaveSend
  //       sendToWialon={props.sendToWialon}
  //       saveRoute={props.saveRoute}
  //       excludedIds={props.excludedIds}
  //       buildContent={props.buildContent}
  //       points={props.points}
  //       locatorSettings={props.locatorSettings}
  //     />
  //   ),
  //   withAccent: true,
  // });

  // if (info)
  //   items.push({
  //     title: "Пункты маршрута",
  //     body: info ? this.renderRoutePuncts() : null
  //   });
  // items.push({ title: "Настройки фильтров", body: this.renderUsedFilters() });
  // if (info)
  //   items.push({
  //     title: "Условия планирования",
  //     body: info ? this.renderSettings() : null
  //   });
  // if (info)
  //   items.push({
  //     title: "Плановые показатели",
  //     body: info ? this.renderInfo() : null
  //   });

  const isMobile = mobileDetector.isMobile();
  return (
    <div className={"route-info" + (isMobile ? " mobile" : "")}>
      <RoutePoints
        buildContent={props.buildContent}
        points={props.points}
        reverseGeocoder={props.reverseGeocoder}
      />
      {info && (
        <div className="route-info__group total">
          {isFinite(info.realTotalDist) && (
            <div className="route-info__item">
              <div className="col_1">Расстояние:</div>
              <div className="col_2">
                <span className="total-distance">
                  {distanceKm(info.realTotalDist, true)}
                </span>
              </div>
            </div>
          )}
          {isFinite(info.totalPrice) && visible.hideSumm !== 1 && (
            <div className="route-info__item">
              <div className="col_1">Всего затрат:</div>
              <div className="col_2">
                <span className="total-price ruble">
                  {Math.floor(info.totalPrice).toLocaleString("ru-Ru")}
                </span>
              </div>
            </div>
          )}
          {isDriver && (
            <Button
              onClick={open}
              className="route-info-navigator-button"
              size="xl"
              primary={true}
            >
              Открыть в навигаторе <Arrowleft />
            </Button>
          )}

          {!isDriver && (
            <SaveSend
              isButton
              sendToWialon={props.sendToWialon}
              saveRoute={props.saveRoute}
              excludedIds={props.excludedIds}
              buildContent={props.buildContent}
              points={props.points}
              locatorSettings={props.locatorSettings}
            />
          )}

          {sendRouteIsOpen && (
            <SimpleProcessRoute close={close} list={props.buildContent.list} />
          )}
        </div>
      )}
      <Accordion
        headSize={isMobile ? 56 : 44}
        items={items}
        onlyOneOpened={true}
      />
      <SaveSend
        isButton={false}
        sendToWialon={props.sendToWialon}
        saveRoute={props.saveRoute}
        excludedIds={props.excludedIds}
        buildContent={props.buildContent}
        points={props.points}
        locatorSettings={props.locatorSettings}
      />
    </div>
  );
};
