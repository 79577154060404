import * as React from "react";
import { format, parseISO } from "date-fns";
import { getDistance } from "../../../utils";

import "./css/toll.scss";

import { PoiWithScroll } from "../../RouteListItem";
export const TollItemCard = (props: { item: PoiWithScroll }) => {
  const name = (() => {
    if (props.item.point_type === "PVP") {
      return props.item.name;
    }
    return props.item.type_id < 53 ? "ПЛАТОН" : "Платная автодорога";
  })();
  const desc = (() => {
    if (props.item.point_type === "PVP") {
      return "Пункт взимания платы";
    }
    return props.item.type_id % 2 ? "въезд на участок" : "cъезд c участка";
  })();
  const dist = getDistance(props.item);
  // const date = props.item.timeTimestamp && new Date(props.item.timeTimestamp);
  const date = props.item.timeTimestamp && parseISO(props.item.timeTimestamp);
  const timeStr = props.item.timeTimestamp && format(date, "HH:mm");
  const dateStr = props.item.timeTimestamp && format(date, "dd.MM.yyyy");

  return (
    <div className="mgo-toll-road-block">
      <div className="block-1">
        <div className="name">{name}</div>
        <div className="desc">
          {props.item.timeTimestamp && (
            <div className="time-year dark">
              <span className="time">{timeStr}</span>
              <span className="date">{dateStr}</span>
            </div>
          )}
        </div>
        <div className="desc">{desc}</div>
        {props.item.needPay && (
          <div className="price">
            <span className="ruble">{`${props.item.needPay}`}</span>
          </div>
        )}
      </div>
      <div className="block-2">
        {props.item.road_id && (
          <div className="road">
            <div className="mgo-trassa-name">
              <div className="inner">{props.item.road_id}</div>
            </div>
          </div>
        )}
      </div>
      <div className="block-3">
        <div className="km">{dist}</div>
      </div>
    </div>
  );
};
