import { RefuelInfo } from "multigo-map-api/types/Controllers/Api/types";

export const fuelColorFactory = (
  refuelsList: RefuelInfo[],
  // avgPrice: number,
  fuelPriceColors: Array<{
    min: number;
    max: number;
    color: string;
    bg: string;
  }>
) => (vCatId: number | undefined, price: number) => {
  const defautlData = {
    color: "#000000",
    bg: "#ffffff",
    index: undefined
  };
  if (!(price && fuelPriceColors)) {
    return defautlData;
  }
  const vCatData = refuelsList.find(r => r.vcat === vCatId);
  if (!vCatData) {
    return defautlData;
  }
  const { avgPrice } = vCatData;

  const delta = (price / avgPrice - 1) * 100;
  const foundIndex = fuelPriceColors.findIndex(
    (curr, idx) =>
      (idx === 0 || delta >= curr.min) &&
      (idx === fuelPriceColors.length - 1 || delta < curr.max)
  );
  return foundIndex >= 0
    ? {
        color: fuelPriceColors[foundIndex].color,
        bg: fuelPriceColors[foundIndex].bg,
        index: foundIndex
      }
    : {
        color: "#000000",
        bg: "#ffffff",
        index: undefined
      };
};
