import { cn } from "@bem-react/classname";
import { PoiDetails } from "multigo-map-api/types/Controllers/Api/types";
import { WithTooltip } from "multigo-ui";
import React, { useContext } from "react";
import { PoiContext } from "./poiContext";
import "./style/fuelTank.scss";
import { RouteListContext } from "../RoutePanel/components/BuiltRoute/utils/context";

const cN = cn("routecard-fuel-tank");

export interface PoiFuelInfo {
  fuelVolumeInTank: number;
  purpose: string;
  litersMax: number;
  fuelVolume: number;
  price: number;
  point_type: string;
  details?: PoiDetails;
  listVCat?: number[];
}
export interface FuelTankInfoProps {
  poi: PoiFuelInfo;
}
export const FuelTankInfo: React.FC<FuelTankInfoProps> = (props) => {
  //   const baseWidth = 348;
  const poiContext = useContext(PoiContext);

  const { vCatData, visibleParts } = poiContext;
  const litersMax = vCatData.litersMax || 0;

  if (!(poiContext.poi.fuelVolume && poiContext.poi.fuelVolumeInTank)) {
    return null;
  }
  const beforeWidth =
    (100 * (poiContext.poi.fuelVolumeInTank - poiContext.poi.fuelVolume)) /
    litersMax;

  const filledWidth = (100 * poiContext.poi.fuelVolume) / litersMax;

  const emptyWidth = 100 - filledWidth - beforeWidth;
  return (
    <div className={cN("wrapper")}>
      <div className={cN("capacity")}>
        <span className={cN("capacity-name")}>Объем бака</span>
        <span className={cN("capacity-value")}>{litersMax} л</span>
      </div>
      <div className={cN("volume")}>
        <div
          className={cN("section", { type: "before" })}
          style={{
            width: `${beforeWidth}%`,
          }}
        >
          <p className={cN("section-name")}>остаток </p>
          <p className={cN("section-value")}>
            {poiContext.poi.fuelVolumeInTank - poiContext.poi.fuelVolume} л
          </p>
        </div>
        <div
          className={cN("section", { type: "filled" })}
          style={{ width: `${filledWidth}%` }}
        >
          <p className={cN("section-name")}>залить </p>
          <p className={cN("section-value")}>{poiContext.poi.fuelVolume} л</p>
        </div>
        <div
          className={cN("section", { type: "empty" })}
          style={{ width: `${emptyWidth}%` }}
        />
      </div>

      <div className={cN("info-wrapper")}>
        <div className={cN("info")}>
          <div className="name">в баке станет</div>
          <div className="value">{poiContext.poi.fuelVolumeInTank} л</div>
        </div>
        {visibleParts.hideYPay !== 1 && (
          <div className={cN("info")}>
            <div className="name">вы заплатите</div>
            <div className="value ruble">
              {Math.floor(
                poiContext.poi.price * poiContext.poi.fuelVolume
              ).toLocaleString("ru-Ru")}{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FuelTankInfo;
