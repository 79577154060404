import { RouteListRecord } from "multigo-map-api";

export const extractParams = (list: RouteListRecord[]) =>
  list.reduce((acc, curr) => {
    if (
      curr.type_id === 34 ||
      curr.type_id === 35 ||
      curr.type_id === 36 ||
      (curr.recom && curr.place_index === 0)
    ) {
      acc.push([curr.lat, curr.lng]);
    }
    return acc;
  }, []);

export const getUrlYa = (list: RouteListRecord[]) => {
  return `https://yandex.ru/maps/?mode=routes&rtext=${extractParams(list)
    .map(ll => ll.join(","))
    .join("~")}&rtt=auto&z=7`;
};

export const getUrlGoogle = (list: RouteListRecord[]) => {
  const puncts = extractParams(list).map(ll => ll.join(","));
  return `https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${
    puncts[0]
  }&destination=${puncts.slice(-1)[0]}&waypoints=${puncts
    .slice(1, -1)
    .join("|")}`;
};

export const getUrlNav = (list: RouteListRecord[]) => {
  const puncts = extractParams(list);
  const formattedPuncts = [
    ...puncts.slice(0, 1),
    ...puncts.slice(-1),
    ...puncts.slice(1, -1)
  ];
  const getPostfix = (index: number) => {
    if (index === 0) {
      return "from";
    }
    if (index === 1) {
      return "to";
    }
    return `via_${index - 2}`;
  };
  return `yandexnavi://build_route_on_map?${formattedPuncts
    .map(
      (ll, index) =>
        `lat_${getPostfix(index)}=${ll[0]}&lon_${getPostfix(index)}=${ll[1]}`
    )
    .join("&")}`;
};
