import * as React from "react";

import { IMapAPI } from "multigo-map-api";
import { CreateRouteBaseComponent } from "../RoutePanel/CreateRouteComponent";
import { useMobileDetect } from "../../utils/hooks/useMobileDetect";

export interface TerminalObj {
  _id: string;
  address: string;
  cat_id: number;
  name: string;
  lat: number;
  lng: number;
}

interface RoutePanelProps {
  mapApi: IMapAPI;
  routeId?: string;
  vehicleId?: string;
  points?: string;
  isMobile?: boolean;
  viewMode?: boolean;
}
export const RoutePanel = (props: RoutePanelProps) => {
  const mobileDetector = useMobileDetect();

  return (
    <div
      className={"route-panel" + (mobileDetector.isMobile() ? " mobile" : "")}
    >
      <CreateRouteBaseComponent
        routeId={props.routeId}
        vehicleId={props.vehicleId}
        points={props.points}
        viewMode={props.viewMode}
        mapApi={props.mapApi}
      />
    </div>
  );
};
