import { IMapAPI, MapAPI } from "multigo-map-api";
import React, { useEffect, useState } from "react";

export interface MapAPIWrapperProps {
  mapKey: string;
}
export const withMapAPI = (mapKey: string, debug: boolean) => (
  Child: React.FC<{ mapApi: IMapAPI; mapKey?: string; mapLoaded: boolean }>
) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [error, setError] = useState("");
  const [mapAPI] = useState(
    () =>
      new MapAPI({
        debug:
          debug !== undefined ? debug : process.env.NODE_ENV === "development",
      }) as IMapAPI
  );

  const initMap = async () => {
    if (mapKey) {
      setMapLoaded(false);
      try {
        await mapAPI.controller.map.init.require({
          apiKey: mapKey,
        });
        setMapLoaded(true);

        console.log(
          "Map API Version",
          mapAPI.versions.version,
          mapAPI.versions.description
        );
      } catch (e) {
        const str = e.toString();
        // setError(str);
      }
    }
  };
  useEffect(() => {
    initMap();
  }, [mapKey]);
  return (
    <>
      {error && (
        <div className="init-map-error">
          Произошла ошибка при инициализации карты. Код ошибки: <p>{error}</p>
        </div>
      )}
      <Child mapLoaded={mapLoaded} mapApi={mapAPI} mapKey={mapKey} />
    </>
  );
};
