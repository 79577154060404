import "./builtRoute.scss";
import "./RouteList/style.scss";

import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";

import { format, parseISO } from "date-fns";
// import { RouteList } from "./";
import { RouteContent, RouteListRecord } from "multigo-map-api";
import {
  FullPoiData,
  LocatorSettings,
} from "multigo-map-api/types/Controllers/Api/map/types";
import { DefaultResponse } from "multigo-map-api/types/Controllers/Api/types";
import { SaveRouteParams } from "multigo-map-api/types/Controllers/Router/routerBuilder";

import { fuelColorFactory } from "../../../../utils/helpers/fuelColorFactory";
import { useIsMobile } from "../../../../utils/hooks/useMobileDetect";
import { PoiCard } from "../../../PoiCard";
import { RoutePoint } from "../../data/point";
import { FuelType } from "../../data/routeFilters";
import { SendToWialon } from "../../data/types";
import { RouteControlPanel } from "./ControlPanel";
import { FuelColorSpectre } from "./FuelColors";
import { RouteInfo } from "./RouteInfo";
import { RouteList } from "./RouteList";

const geoPointTypes = [
  "near_city",
  "in_city",
  "out_city",
  "in_region",
  "river",
];
const tollPointsTypes = [
  "in_platon",
  "out_platon",
  "in_toll",
  "out_toll",
  "PVP",
];

const FUEL_VOL_MIN = -9999999;

export interface BuiltRouteProps {
  locatorSettings: LocatorSettings;
  closeRoute: () => void;
  buildContent: RouteContent;
  isViewAll: boolean;
  setIsViewAll: (newValue: boolean) => void;
  activePOI: number;
  setActivePOI: (newPOI: number) => void;
  // route: IRouteWithSettings;
  // style: React.CSSProperties;
  getPoiInfo: (
    poiId: string
  ) => Promise<DefaultResponse<{ object: FullPoiData }>>;
  reverseGeocoder: (ll: { lat: number; lng: number }) => Promise<string>;
  points: RoutePoint[];
  drawPoisOnMap: (poiIds: string[]) => void;
  fuel_id?: string;
  saveRoute: (
    params: SaveRouteParams
  ) => Promise<{ shortId: string; shortUrl: string }>;
  mapNavigation: (center: [number, number]) => void;
  sendToWialon?: SendToWialon;
  fuelTypes: FuelType[];
  viewMode: boolean;
}

export const BuiltRouteComponent = (props: BuiltRouteProps) => {
  const scrollContainerRef = useRef(null);
  const getScrollContainerRef = () => scrollContainerRef;

  const [activeCardId, setActiveCardId] = useState(undefined); // FIXME

  const poi = useMemo(() => {
    return props.buildContent.list[activeCardId];
  }, [activeCardId]);

  useEffect(() => {
    if (props.viewMode && props.buildContent.routeId && document) {
      document.title = `MultiGO Рейс - ${props.buildContent.routeId}`;
    }
  }, [props.buildContent.routeId]);

  const onClickMoreHandler = (e: React.MouseEvent, idx: number) => {
    setActiveCardId(idx);
  };

  const [colorMask, setColorMask] = useState(() => {
    const filterColor =
      Number(props.buildContent.query.options.filterColor) || 0;

    return [...new Array(props.locatorSettings.fuelPriceColors.length)].map(
      (_, i) => i < filterColor
    );
  });
  const { refuesList } = props.buildContent.info;

  const getFuelColor = fuelColorFactory(
    refuesList,
    props.locatorSettings.fuelPriceColors
  );

  const [excludedIds, setExcludedIds] = useState([] as string[]);
  const getExcludedIdsList = (newMask: boolean[]) => {
    if (newMask.length && newMask.some((v) => v)) {
      return (props.buildContent.list as RouteListRecord[]).reduce(
        (acc, curr) => {
          if (curr.price) {
            const { index } = getFuelColor(
              (curr.listVCat && curr.listVCat[0]) || 0,
              curr.price
            );
            if (Number.isFinite(index) && !newMask[index]) {
              acc.push(curr.poi_id);
            }
          }
          return acc;
        },
        [] as string[]
      );
    }
    return [];
  };
  const colorMaskHandle = (newMask: boolean[]) => {
    setColorMask(newMask);
    setExcludedIds(getExcludedIdsList(newMask));
  };
  const getPoiForRouteCard = (direction: "prev" | "next") => {
    if (!activeCardId) {
      return undefined;
    }
    const found = props.buildContent.list.reduce(
      (acc, currentPoi, index) => {
        const poiInList = props.isViewAll || currentPoi.inShortList;
        const indexOutOfRange =
          (direction === "prev" && index >= activeCardId) ||
          (direction === "next" && (index <= activeCardId || acc[0]));

        if (!poiInList || indexOutOfRange) {
          return acc;
        }
        const isPurposeMatched = () =>
          poi.purpose && currentPoi.purpose === poi.purpose;

        const isTypesMatched = () => currentPoi.point_type === poi.point_type;
        const isGeoPoints = () =>
          geoPointTypes.includes(currentPoi.point_type) &&
          geoPointTypes.includes(poi.point_type);
        const isTollPoints = () =>
          tollPointsTypes.includes(currentPoi.point_type) &&
          tollPointsTypes.includes(poi.point_type);

        if (
          isPurposeMatched() ||
          isTypesMatched() ||
          isGeoPoints() ||
          isTollPoints()
        ) {
          if (direction === "prev" || !acc[0]) {
            return [currentPoi, index];
          }
        }
        return acc;
      },
      [undefined, undefined] as [RouteListRecord, number]
    );

    return found && found[0]
      ? {
          dist: (found[0] as RouteListRecord).dist,
          onClick: () => {
            setActiveCardId(found[1]);
          },
        }
      : undefined;
  };
  const isMobile = useIsMobile();

  const [
    showPan,
    noAzsCount,
    delLimitsCount,
    fuelEndPath,
    fuelEndPath2,
  ] = useMemo(() => {
    const rList = props.buildContent.list.filter((x) => x.recom);
    const noAzsCount = rList.filter((x) => x.purpose === "refuel" && !x.poi_id)
      .length;
    const delLimitsCount = rList.filter(
      (x) => x.purpose === "refuel" && x?.delLimits?.noFilter
    ).length;

    let fuelEndPath = FUEL_VOL_MIN;
    let fuelEndPath2 = FUEL_VOL_MIN;
    const { refuesList } = props.buildContent.info;
    (refuesList || []).forEach((rl) => {
      if (rl.vcat === 2) fuelEndPath = rl.fuelEndPath;
      else fuelEndPath2 = rl.fuelEndPath;
    });

    // show/hide fuels vol
    if (fuelEndPath > 0) {
      const fvDelta =
        (props.buildContent.info.options.litersEndPath || 0) * 0.05;
      if (
        props.buildContent.info.options.litersEndPath - fvDelta <
        fuelEndPath
      ) {
        fuelEndPath = FUEL_VOL_MIN;
      }
    }
    if (fuelEndPath2 > 0) {
      const fvDelta =
        (props.buildContent.info.options.litersEndPath2 || 0) * 0.05;
      if (
        props.buildContent.info.options.litersEndPath2 - fvDelta <
        fuelEndPath2
      ) {
        fuelEndPath2 = FUEL_VOL_MIN;
      }
    }

    const showPan =
      fuelEndPath > FUEL_VOL_MIN ||
      fuelEndPath2 > FUEL_VOL_MIN ||
      noAzsCount > 0 ||
      delLimitsCount > 0;

    return [showPan, noAzsCount, delLimitsCount, fuelEndPath, fuelEndPath2];
  }, [props.buildContent._id]);

  return (
    <>
      <div
        className={`control-panel-wrapper${isMobile ? " mobile" : ""}`}
        ref={scrollContainerRef}
      >
        <div className="control-panel-content">
          {isFinite(activeCardId) ? (
            <PoiCard
              mapNavigation={props.mapNavigation}
              poiId={poi.poi_id}
              getPoiInfo={props.getPoiInfo}
              navigation={{
                points: {
                  current: { dist: poi.dist },
                  next: getPoiForRouteCard("next"),
                  prev: getPoiForRouteCard("prev"),
                },
                schedule: {
                  purpose: poi.purpose as any,
                  timeTimestamp: poi.timeTimestamp,
                  deltaTime: poi.deltaTime,
                },
              }}
              poi={poi as any}
              closeCard={() => setActiveCardId(undefined)}
              fuelPriceColors={props.locatorSettings.fuelPriceColors}
              tarifPolProps={props.locatorSettings.tp}
              avgPrice={props.buildContent.info.avgPriceByRoute}
              fuelIndex={
                getFuelColor((poi.listVCat && poi.listVCat[0]) || 0, poi.price)
                  .index
              }
              refuelsList={props.buildContent.info.refuesList}
              visibleParts={props.buildContent.visible || {}}
            />
          ) : (
            <>
              <div id="process-route-anchor" />

              {props.viewMode && (
                <div className="built-route-info-header">
                  <table>
                    <tbody>
                      <tr>
                        <td>РЕЙС:</td>
                        <td>{props.buildContent.routeId}</td>
                      </tr>
                      <tr>
                        <td>ТС:</td>
                        <td>{props.buildContent.vehicle.name}</td>
                      </tr>
                      <tr>
                        <td>Плановый старт:</td>
                        <td>
                          {format(
                            parseISO(props.buildContent.info.dateBegin),
                            "dd.MM.yyyy HH:mm"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Плановый финиш:</td>
                        <td>
                          {format(
                            parseISO(props.buildContent.info.dateEnd),
                            "dd.MM.yyyy HH:mm"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Спланирован:</td>
                        <td>
                          {format(
                            parseISO(props.buildContent.cdt),
                            "dd.MM.yyyy HH:mm"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          {props.buildContent.name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {showPan && (
                <div
                  className="built-route-info-header"
                  style={{ backgroundColor: "#ffe74c", marginTop: 4 }}
                >
                  <table>
                    <tbody>
                      {noAzsCount > 0 && (
                        <tr>
                          <td>Участки без АЗС:</td>
                          <td>{noAzsCount}</td>
                        </tr>
                      )}
                      {delLimitsCount > 0 && (
                        <tr>
                          <td>Есть дополнительные АЗС:</td>
                          <td>{delLimitsCount}</td>
                        </tr>
                      )}
                      {fuelEndPath > FUEL_VOL_MIN && (
                        <tr>
                          <td>Остаток на финише (Дт):</td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {fuelEndPath} (нужно{" "}
                            {props.buildContent.info.options.litersEndPath})
                          </td>
                        </tr>
                      )}
                      {fuelEndPath2 > FUEL_VOL_MIN && (
                        <tr>
                          <td>Остаток на финише (Газ):</td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {fuelEndPath2} (нужно{" "}
                            {props.buildContent.info.options.litersEndPath2})
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              <RouteControlPanel
                closeRoute={props.closeRoute}
                isViewAll={props.isViewAll}
                setIsViewAll={props.setIsViewAll}
                viewMode={props.viewMode}
              />

              <RouteInfo
                points={props.points}
                saveRoute={props.saveRoute}
                locatorSettings={props.locatorSettings}
                reverseGeocoder={props.reverseGeocoder}
                buildContent={props.buildContent}
                excludedIds={excludedIds}
                sendToWialon={props.sendToWialon}
                fuelTypes={props.fuelTypes}
                viewMode={props.viewMode}
              />
              {props.locatorSettings.fuelPriceColors &&
              props.buildContent.info &&
              props.buildContent.info.options &&
              props.buildContent.info.options.spanAZS === 0 &&
              props.buildContent.info.options.color_make === 1 &&
              props.buildContent.info.options.litersPerDistance === 0 &&
              props.buildContent.info.options.filterColor !== undefined ? (
                <FuelColorSpectre
                  baseFilterColor={Number(
                    props.buildContent.query.options.filterColor
                  )}
                  colorMask={colorMask}
                  setColorMask={colorMaskHandle}
                  colors={props.locatorSettings.fuelPriceColors}
                />
              ) : null}
              <RouteList
                drawPoisOnMap={props.drawPoisOnMap}
                locatorSettings={props.locatorSettings}
                getScrollContainerRef={getScrollContainerRef}
                buildContent={props.buildContent}
                fuel_id={8} // TODO: fix this!
                onClickMoreHandler={onClickMoreHandler}
                activePOI={props.activePOI}
                setActivePOI={props.setActivePOI}
                isViewAll={props.isViewAll}
                colorMask={colorMask}
                excludedIds={excludedIds}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
