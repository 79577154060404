import * as React from "react";
import { useEffect, useMemo, useState } from "react";

import { Tabs } from "multigo-ui";
import { ISettingsMixin } from "../data/settingsMixin";
import { getFieldComponent } from "./fields";
interface RouteQuickSettingsProps {
  style?: React.CSSProperties;
  route: ISettingsMixin;
}

export const RouteQuickSettings = (props: RouteQuickSettingsProps) => {
  return (
    <div className="tabs-content" style={{ height: "auto" }}>
      {props.route.settingsList
        .filter((field) => field.fast === 1)
        .map((field, index) => getFieldComponent(field, index))}
    </div>
  );
};
