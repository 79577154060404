import React from "react";
import { IRouteWithSettings } from "../../../data/routeWithSettings";
import { RouteContent } from "multigo-map-api";
import { useIsMobile } from "../../../../../utils/hooks/useMobileDetect";
import { distanceKm, roundPlus } from "../utils/converters";

export const RoutePuncts: React.FC<{ buildContent: RouteContent }> = (
  props
) => {
  const { info, list, visible } = props.buildContent;

  const isMobile = useIsMobile();
  if (info && info.story) {
    let len = info.story.length;
    let { story } = info;

    return (
      <div className="route-adminlevel">
        {story.map((obj, idx) => {
          if (obj.type != 50) {
            const poi = list[obj.index];
            if (poi) {
              const poiOut = [];
              let poiStr = roundPlus(poi.dist / 1000, 1) + " км. - ";

              // name
              if (poi?.details?.brand?.name) {
                poiStr += poi?.details?.brand?.name + ", ";
              } else {
                poiStr += poi.name + ", ";
              }

              // address
              poiStr += poi?.details?.address;
              poiOut.push(poiStr);

              if (poi.fuelVolume > 0) {
                // fuelValue
                poiStr = poi.fuelVolume + " л. ";

                // fuelId
                const fuels = poi?.details?.fuels || [];
                if (fuels.length > 0) {
                  poiStr += fuels[0].fuelId + " - ";
                }

                // fuelCard
                const fuelCards = poi?.details?.fuelCards || [];
                if (fuelCards.length > 0) {
                  let fc = fuelCards.find((ff) => ff.tarifSelected === 1);
                  if (!fc) {
                    fc = fuelCards[0];
                  }
                  poiStr += fc.name;
                }
                poiOut.push(poiStr);
              }

              // location
              if (visible.showObjectLL === 1) {
                poiOut.push(
                  roundPlus(poi.lat, 6) + ", " + roundPlus(poi.lng, 6)
                );
              }

              return (
                <div key={idx} className="wrap">
                  <span
                    className={`route-adminlevel__item type_${obj.type}${
                      isMobile ? " mobile" : ""
                    }`}
                  >
                    {poiOut.map((s, i) => (
                      <div key={i}>
                        {s}{" "}
                        {poiOut.length - 1 === i && len - 1 > idx && <b>→</b>}
                      </div>
                    ))}
                  </span>
                </div>
              );
            }

            return (
              <div key={idx} className="wrap">
                <span
                  className={`route-adminlevel__item type_${obj.type}${
                    isMobile ? " mobile" : ""
                  }`}
                >
                  {obj.name} {len - 1 > idx && <b>→</b>}{" "}
                </span>
              </div>
            );
          } else {
            return (
              <span
                key={idx}
                className={`route-adminlevel__item type_${obj.type}${
                  isMobile ? " mobile" : ""
                }`}
              >
                {obj.name} {len - 1 > idx && <b>→</b>}{" "}
              </span>
            );
          }
        })}
      </div>
    );
  }

  return null;
};
