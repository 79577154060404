import React, { useContext } from "react";
import { PoiContext } from "./poiContext";

import { cn } from "@bem-react/classname";

import "./style/services.scss";

const cN = cn("routecard-services");

export const Services: React.FC = () => {
  const poiContext = useContext(PoiContext);
  const poi = poiContext.poiContent;
  return (
    <div className={cN("wrapper")}>
      {poi.services.map(service => (
        <ServiceBlock
          key={service.name}
          name={service.name}
          imgUrl={service.imgUrl}
        />
      ))}
    </div>
  );
};

const ServiceBlock: React.FC<{ imgUrl: string; name: string }> = props => (
  <div className={cN("row")}>
    <img src={props.imgUrl} />
    <p>{props.name}</p>
  </div>
);
export default Services;
