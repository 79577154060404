import React, { useContext } from "react";
import { PoiContext } from "./poiContext";
import { format, parseISO, addSeconds } from "date-fns";

import InIcon from "../../assets/icons/punkt_pribitie.svg";
import OutIcon from "../../assets/icons/punkt_ubitie.svg";
import { cn } from "@bem-react/classname";
const cN = cn("route-card-schedule");
import "./style/schedule.scss";

export interface RouteScheduleProps {}
export const RouteSchedule: React.FC<RouteScheduleProps> = props => {
  const poiContext = useContext(PoiContext);
  const inTime = parseISO(poiContext.schedule.timeTimestamp); // new Date(poiContext.schedule.timeTimestamp);
  const outTime = addSeconds(inTime, poiContext.schedule.deltaTime || 0);
  const getStopType = () => {
    switch (poiContext.schedule.purpose) {
      case "sleep":
        return "Ночевка";
      case "rest":
        return "Cтоянка";
      case "refuel":
        return "Заправка";
    }
  };
  const getStopTime = () => {
    if (!poiContext.schedule.deltaTime) {
      return "";
    }
    const hours = Math.floor(poiContext.schedule.deltaTime / 3600);
    const minutes = Math.floor((poiContext.schedule.deltaTime % 3600) / 60);
    return `${hours ? hours + " ч " : ""}${minutes} мин`;
  };
  return (
    <div className={cN("wrapper")}>
      <div className={cN("inner")}>
        <TimeBlock time={inTime} type="in" />
        {poiContext.schedule.deltaTime && (
          <>
            <div className={cN("stop")}>
              <div
                className={cN("stop-name", {
                  purpose: poiContext.schedule.purpose
                })}
              >
                {getStopType()}
              </div>
              <div className={cN("stop-time")}>{getStopTime()}</div>
            </div>
          </>
        )}
        <TimeBlock time={outTime} type="out" />
      </div>
    </div>
  );
};

const TimeBlock: React.FC<{ time: Date; type: "in" | "out" }> = props => {
  return (
    <div
      className={cN("time-block")}
      style={{ float: props.type === "in" ? "left" : "right" }}
    >
      <div className="time">
        {props.type === "in" ? <InIcon /> : <OutIcon />}
        {format(props.time, "HH:mm")}
      </div>
      <div className="date"> {format(props.time, "dd.MM.yyyy")}</div>
    </div>
  );
};
