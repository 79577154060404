import { Checkbox } from "multigo-ui";
import * as React from "react";
export const BinarySelector = props => {
  const boolHandler = (newValue: boolean) => {
    props.field.handler(newValue ? 1 : 0);
  };
  return (
    <Checkbox
      text={props.field.name}
      // text={props.field.prop}
      checked={props.field.value === 1}
      onChange={boolHandler}
    />
  );
};
