import { RoutePoiProps } from "../Router/router";
import { IFetcher } from "./fetcher";
import { TilePoints } from "./map/types";
import { DefaultResponse } from "./types";
import { RouteContent } from "./types";
export * from "./types";

interface SaveParams {
  routeId: string;
  name: string;
  desc?: string;
  type?: number;
  hideIds?: string;
}
export interface StaticIcon {
  i: string;
  j: number[];
}
export type StaticItemList = "noSleep" | "noRest" | "noRefuel";
export interface IRouterAPI {
  run: (payload: {
    ll: string | number;
    options: string;
    controlledOptions?: string;
  }) => Promise<DefaultResponse<{ routeId: string }>>;

  vrun: (payload: {
    // vehicle run
    ll: string;
    vehicleId: string;
  }) => Promise<DefaultResponse<{ routeId: string }>>;

  check: (
    routeId: string
  ) => Promise<
    DefaultResponse<{
      udt: string;
      percent: number;
      end: number;
      err?: number;
      statusErr: string;
      statusLast: string;
    }>
  >;
  get: (routeId: string) => Promise<DefaultResponse<RouteContent>>;
  save: (
    params: SaveParams
  ) => Promise<DefaultResponse<{ shortId: string; shortUrl: string }>>;
  getPoiIcons: (
    routeId: string
  ) => Promise<
    DefaultResponse<{
      list: RoutePoiProps[];
      routeStaticIcons: { [key in StaticItemList]: StaticIcon };
    }>
  >;
}

export function RouterAPI(fetcher: IFetcher) {
  this.run = (payload: { ll: string; options: string }) => {
    return fetcher.protectedGet("map/router/run", payload);
  };
  this.vrun = (payload: { ll: string; vehicleId: string }) =>
    fetcher.protectedGet("map/router/vrun", payload);

  this.check = (routeId: string) =>
    fetcher.protectedGet("map/router/check", { routeId });
  this.get = (routeId: string) =>
    fetcher.protectedGet("map/router/get", { routeId });

  this.save = (params: SaveParams) =>
    fetcher.protectedGet("map/router/shortid", params);

  this.getPoiIcons = (routeId: string) =>
    fetcher.protectedGet("map/router/poi/icons", { routeId });
}

// const mock = require("./mock.json");
// export function RouterAPI(fetcher) {
//   this.run = (payload: {
//     ll: string;
//     options: string;
//     controlledOptions?: string;
//   }) =>
//     Promise.resolve({
//       data: {
//         routeId: mock.data._id
//       },
//       err: 0
//     });
//   this.vrun = (payload: {
//     ll: string;
//     options: string;
//     controlledOptions?: string;
//   }) =>
//     Promise.resolve({
//       data: {
//         routeId: mock.data._id
//       },
//       err: 0
//     });

//   this.check = (routeId: string) =>
//     Promise.resolve({
//       data: {
//         udt: "2019-05-30T15:01:40.114Z",
//         end: 1,
//         percent: 100,
//         statusErr: "OK",
//         statusLast: "Расчет окончен"
//       },
//       err: 0
//     });

//   this.get = (routeId: string) => new Promise(res => res(mock));
// }
