import * as React from "react";
import { useContext, useMemo } from "react";
import { ToRenderItem } from "../common";

import { RouteListContext } from "../../../../utils/context";

export const ServicesBlock = (props: { item: ToRenderItem }) => {
  const { services } = props.item.details;
  const context = useContext(RouteListContext);
  return useMemo(
    () => (
      <div className="mgo-services-block">
        {services &&
          services.map((service, idx) => {
            return (
              <img
                key={idx}
                src={service.imgUrl}
                alt={service.name}
                title={service.name}
              />
            );
          })}
      </div>
    ),
    [props.item.dist]
  );
};
