import React, { useRef, useState } from "react";
import useOnClickOutside from "../../../../utils/hooks/useOnClickOutside";
import { parseSeconds } from "../BuiltRoute/utils/converters";
import { CommonProps, RtoRow } from "./props";
import "./rtoStyle.scss";

interface RtoField extends CommonProps {
  value: 0 | RtoRow[];
  handler: (newValue: RtoRow[]) => void;
  values: RtoRow[];
}
const translate = {
  sleep: "сон",
  work: "работа",
  rest: "отдых"
};

export const RtoSelector: React.FC<{ field: RtoField }> = props => {
  const value =
    props.field.value === 0 ? props.field.values : props.field.value;
  const handlerFactory = (insertingIndex: number) => (row: RtoRow) => {
    if (row) {
      props.field.handler(
        value.map((v, index) => (index === insertingIndex ? row : v))
      );
    } else {
      props.field.handler(value.filter((_, index) => index !== insertingIndex));
    }
  };
  const addRow = () => {
    props.field.handler([...value, { type: "rest", time: 15 }]);
  };
  return (
    <>
      <p className="route-settings-name">{props.field.name}</p>

      <div className="rto-list">
        {value.map((val, index) => (
          <RtoRowComponent
            row={val}
            index={index}
            key={index}
            handler={handlerFactory(index)}
          />
        ))}
      </div>
      <button onClick={addRow}> Добавить блок РТО</button>
    </>
  );
};

const RtoRowComponent: React.FC<{
  row: RtoRow;
  index: number;
  handler: (row: RtoRow | null) => void;
}> = props => {
  const [selectorOpened, setSelectorOpened] = useState(false);
  const openSelector = () => setSelectorOpened(true);
  const selectorHandlerFactory = (type: "rest" | "work" | "sleep") => () => {
    props.handler({ ...props.row, type });
    setSelectorOpened(false);
  };
  const handlerFactory = (type: "hours" | "mins") => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const parsedNumber = Number.parseInt(e.target.value, 10);

    const parsed = Number.isFinite(parsedNumber) ? parsedNumber : 0;
    const time =
      type === "hours"
        ? parsed * 60 + (props.row.time % 60)
        : Math.floor(props.row.time / 60) * 60 + parsed;
    props.handler({ ...props.row, time });
  };

  const removeRow = () => {
    props.handler(null);
  };
  const selectorRef = useRef(null);
  useOnClickOutside(selectorRef, () => setSelectorOpened(false));
  return (
    <div className="rto-row">
      <div
        onClick={openSelector}
        className={`rto-block rto-block-${props.row.type}`}
      >
        {translate[props.row.type]}
      </div>
      {selectorOpened && (
        <div ref={selectorRef} className="rto-block-selector">
          {Object.keys(translate).map((key: "rest" | "sleep" | "work") => (
            <div
              key={key}
              className={` rto-selector-row rto-block-${key}`}
              onClick={selectorHandlerFactory(key)}
            >
              {translate[key]}
            </div>
          ))}
        </div>
      )}
      <div className="rto-value">
        <input
          type="number"
          min="0"
          value={Math.floor(props.row.time / 60) || ""}
          onChange={handlerFactory("hours")}
        />
        ч
        <input
          type="number"
          min="0"
          max="60"
          value={props.row.time % 60}
          onChange={handlerFactory("mins")}
        />
        мин
      </div>
      <div className="rto-remover" onClick={removeRow} />
    </div>
  );
};
