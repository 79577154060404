import { default as fetchJsonp } from "fetch-jsonp";
import { DefaultResponse } from "./types";

const BASE_URL = "https://api7.multigo.ru/3/";
const BASE_URL_DEBUG = "https://api7d.multigo.ru/3/";

export interface IFetcher {
  hasToken: boolean;
  setToken: (token: string) => void;
  protectedGet: <T>(
    url: string,
    params?: object
  ) => Promise<{ data?: T; err?: number; errmsg?: string }>;
  get: <T>(
    url: string,
    params?: object
  ) => Promise<{ data?: T; err?: number; errmsg?: string }>;
}

export function Fetcher(debug: boolean, baseURL?: string) {
  let _token = "";
  const currentBaseUrl = (() => {
    if (baseURL) {
      return baseURL;
    }
    return debug ? BASE_URL_DEBUG : BASE_URL;
  })();

  this.hasToken = false;

  this.setToken = token => {
    _token = token;
    this.hasToken = !!token;
  };

  this.errorChecker = data => {
    if (data.err) {
      throw new Error(`${data.err}: ${data.errmsg}`);
    }
    return data;
  };
  this.protectedGet = <T>(url: string, params?: object) => {
    if (!this.hasToken) {
      throw new Error("Access denied: no token");
    }
    return (this.get as <R>(
      url: string,
      params?: object
    ) => Promise<DefaultResponse<R>>)<T>(url, params);
  };
  this.get = <T>(url: string, params?: object): Promise<DefaultResponse<T>> => {
    const fullUrl =
      currentBaseUrl +
      url +
      (_token ? `?token=${_token}` : "?") +
      (params
        ? Object.keys(params)
            .map(key => `&${key}=${params[key]}`)
            .join("")
        : "");

    return new Promise((resolve, reject) => {
      // fetchJsonp(fullUrl, { timeout: debug ? 5000 : 15000 })
      fetchJsonp(fullUrl, { timeout: 60000 })
        .then(res => res.json())
        .then(this.errorChecker)
        .then(resolve)
        .catch(reject);
    });
  };
}
