import * as React from "react";
import { useContext } from "react";

import "./css/common.scss";
import { OTHER_NAMES } from "../../../utils/consts";
import { RouteListContext } from "../../../utils/context";
import {
  AddressBlock,
  TimeBlock,
  FuelCardsBlock,
  ServicesBlock,
  TankBlock,
} from "./parts";
import { PoiWithScroll } from "../../RouteListItem";
import { NoFiltersMark } from "./parts/NoFiltersMark";

export interface ToRenderItem extends PoiWithScroll {
  litersMax?: number;
  brandName?: string;
  imgUrl?: string;
}
export const CommonItemCard = (props: {
  item: PoiWithScroll;
  imgUrl: string;
}) => {
  const context = useContext(RouteListContext);

  const toRender = props.item as ToRenderItem;
  // Максимальный объём бака
  toRender.litersMax = context.getLitersMax();

  // Брэнд или название
  toRender.brandName =
    (toRender.details &&
      toRender.details.brand &&
      (toRender.details.brand.name || toRender.details.name)) ||
    toRender.name;

  if (toRender.type_id == 18 && toRender.point_type == "PVP") {
    toRender.brandName = toRender.name || "ПВП";
    // toRender.address = toRender.details && toRender.details.address || toRender.description;
    toRender.imgUrl = context.getPvPImageUrl(toRender.type_id);
  }
  if (toRender.details && toRender.details.brand) {
    toRender.imgUrl = toRender.details.brand.imgUrl;
  } else {
    if (toRender.details) {
      toRender.imgUrl =
        (toRender.details.subCategory && toRender.details.subCategory.imgUrl) ||
        (toRender.details.category && toRender.details.category.imgUrl) ||
        "";
    }
  }
  // Блок прибывания на POI
  const shouldRenderTimeBLock = (obj) => {
    return Boolean(obj.timeTimestamp && obj.place_index === 0);
  };

  // Топливные карты
  const shouldRenderFuelCardsBlock = (obj: ToRenderItem) => {
    return Boolean(
      obj.details &&
        obj.details.fuelCards &&
        obj.details.fuelCards.length > 0 &&
        obj.place_index === 0
    );
  };

  // Сервисы
  const shouldRenderServicesBlock = (obj: ToRenderItem) => {
    const { details } = obj;
    // Не показывать, если нет сервисов
    return Boolean(
      details &&
        details.services &&
        obj.purpose !== "refuel" &&
        obj.place_index === 0
    );
  };

  // Блок информаии кол. залитого топлива
  const shouldRenderTankBlock = (obj: ToRenderItem) => {
    /*  Показываем цены на топливо у всего, у чего есть цены на топливо
     */
    const { details } = obj;
    return Boolean(details && details.fuels);
    // return Boolean(
    //   obj.purpose === "refuel" &&
    //     ((obj.litersMax && obj.fuelVolume && obj.price) ||
    //       (fuel_id && details && details.fuels))
    // );
  };

  const shouldRenderNoFilterMark = (obj: ToRenderItem) => {
    const { delLimits } = obj;
    return Boolean(delLimits && delLimits.noFilter);
  };

  const ItemCard = (props) => (
    <div className="item-card-row">{props.children}</div>
  );

  return (
    <div className="mgo-common-item-card">
      <ItemCard>
        <AddressBlock item={toRender} />
      </ItemCard>

      {shouldRenderNoFilterMark(toRender) && (
        <ItemCard>
          <NoFiltersMark item={toRender} />
        </ItemCard>
      )}

      {shouldRenderTimeBLock(toRender) && (
        <ItemCard>
          <TimeBlock item={toRender} />
        </ItemCard>
      )}

      {shouldRenderFuelCardsBlock(toRender) && (
        <ItemCard>
          <FuelCardsBlock item={toRender} />
        </ItemCard>
      )}

      {shouldRenderServicesBlock(toRender) && (
        <ItemCard>
          <ServicesBlock item={toRender} />
        </ItemCard>
      )}

      {shouldRenderTankBlock(toRender) && (
        <ItemCard>
          <TankBlock item={toRender} />
        </ItemCard>
      )}
    </div>
  );
};
