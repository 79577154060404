import * as React from "react";
import { useContext } from "react";

import { WithTooltip } from "multigo-ui";
import { RouteListContext } from "../../../../utils/context";
import { ItemPoint } from "../../content";
import { ToRenderItem } from "../common";

export const FuelPriceBlock = (props: { item: ToRenderItem }) => {
  const { fuels, fuelCards } = props.item.details;
  const context = useContext(RouteListContext);
  const { visible } = context;

  // Нет данных о топливе
  if (!fuels) {
    return null;
  }
  const activeFuel = fuels.find((f) => f.selected === 1);

  if (activeFuel) {
    // hide TP
    if (visible.hideTP === 1) {
      const fuelPrice = activeFuel.origPrice || activeFuel.fuelPrice;
      return (
        <div className="fuel-price">
          <div className="name">{activeFuel.fuelId}</div>
          <div className={`value ${activeFuel.fuelPrice && ""}`}>
            {fuelPrice ? fuelPrice.toFixed(2) : "есть"}
          </div>
        </div>
      );
    }

    const vCat = (props.item.listVCat && props.item.listVCat[0]) || 0;
    const fCard =
      activeFuel.fuelCard && fuelCards.find((fcard) => fcard.tarifSelected);
    const fuelColor = context.getFuelColor(vCat, activeFuel.fuelPrice);

    const tarifZero = Math.abs(parseFloat(activeFuel.tarif)) < 0.01;
    return (
      <div className="fuel-price">
        <div className="name">
          {activeFuel.fuelId}
          {activeFuel.origPrice && (
            <div
              className={`percent-decorator ${
                activeFuel.origPrice > activeFuel.fuelPrice
                  ? "positive"
                  : activeFuel.origPrice === activeFuel.fuelPrice
                  ? "neutral"
                  : "negative"
              }`}
            >
              <WithTooltip
                text={`тарифная политика ${tarifZero ? "цена стеллы" : activeFuel.tarif}${
                  fCard ? " по карте " + fCard.name : ""
                }`}
              >
                %
              </WithTooltip>
            </div>
          )}
        </div>
        <div
          // className={`value ${activeFuel.fuelPrice && "ruble"}`}
          className={`value ${activeFuel.fuelPrice && ""}`}
          style={{
            backgroundColor: fuelColor.bg,
            color: fuelColor.color,
          }}
        >
          {activeFuel.tarif ? (
            <WithTooltip
              text={`тарифная политика ${tarifZero ? "цена стеллы" : activeFuel.tarif}${
                fCard ? " по карте " + fCard.name : ""
              }`}
            >
              {activeFuel.fuelPrice ? activeFuel.fuelPrice.toFixed(2) : "есть"}
            </WithTooltip>
          ) : activeFuel.fuelPrice ? (
            activeFuel.fuelPrice.toFixed(2)
          ) : (
            "есть"
          )}
        </div>
      </div>
    );
  }

  // // Если нет обычного смотрим брендовое топливо.
  // const fId = (fuelx[fuel_id] && fuel_id) || (fuelx[fuel_id + 1] && fuel_id + 1);

  // if (fuelx[fId] && fuelx[fId].v) {
  //   const price = fuelx[fId].v.toFixed(2);

  //   return (
  //     <div className={`fuel-price${fcolor ? ` fl-cr-type-${fcolor}` : ''}`}>
  //       <div className="name">{context.getFuelName(fuel_id)}</div>
  //       <div className="value">{price}</div>
  //     </div>
  //   );
  // }
  // if (fuels.indexOf(fuel_id) > -1 || fuels.indexOf(fuel_id + 1) > -1) {
  //   return (
  //     <div className="fuel-price">
  //       <div className="name">{context.getFuelName(fuel_id)}</div>
  //       <div className="value">есть</div>
  //     </div>
  //   );
  // }
  return null;
};
