import React from "react";
import { FuelPriceColor } from "multigo-map-api/types/Controllers/Api/map/types";
import { cn } from "@bem-react/classname";

export interface FuelColorLabelProps {
  color: FuelPriceColor;
  checked: boolean;
  checkHandler: (newChecked: boolean) => void;
  length: number;
}
export const FuelColorLabel: React.FC<FuelColorLabelProps> = props => {
  const cN = cn("fuel-color-spectre");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.checkHandler(e.target.checked);
  };
  return (
    <div className={cN("block")} style={{ width: `${100 / props.length}%` }}>
      <label>
        <div
          style={{ backgroundColor: props.color.bg }}
          className={cN("color-block") + " noselect"}
        />
        <input type="checkbox" checked={props.checked} onChange={onChange} />
      </label>
    </div>
  );
};
