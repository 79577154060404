import * as React from "react";
import { useContext, useEffect, useState } from "react";

import { Button, Tabs } from "multigo-ui";
// import { PaginatedListData } from "../../utils/dataClasses/paginatedList";
import { useForceUpdate } from "../../utils/hooks/useForceUpdate";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { RoutesAPI } from "./api";
import { IPlainRoute } from "./data/route";
import { RouteFiltersData } from "./data/routeFilters";
import {
  IRouteWithSettings,
  RouteWithSettings,
} from "./data/routeWithSettings";

import { IMapAPI } from "multigo-map-api";

import { PointsList } from "./components/pointsList";
import { RouteFilters } from "./components/routeFilters";
import { RouteProgress } from "./components/routeProgress";
import { RouteSettings } from "./components/routeSettings";
import { VehicleSelectComponent } from "./components/vehicleSelect";

import FilterSvg from "../../assets/icons/filter.svg";
import RouteSvg from "../../assets/icons/route.svg";
import SettingsSvg from "../../assets/icons/settings.svg";

import { SaveRouteParams } from "multigo-map-api/types/Controllers/Router/routerBuilder";
import { useBooleanState } from "../../utils/hooks/useBooleanState";
import { BuiltRouteComponent } from "./components/BuiltRoute";
import { RouteQuickSettings } from "./components/routeQuickSettings";

export interface TerminalObj {
  _id: string;
  address: string;
  cat_id: number;
  name: string;
  lat: number;
  lng: number;
}

interface CreateRouteProps {
  mapApi: IMapAPI;
  routeId?: string;
  vehicleId?: string;
  points?: string;
  viewMode?: boolean;
}
export const CreateRouteBaseComponent = (props: CreateRouteProps) => {
  const rerender = useForceUpdate();
  const [objectSelectorState, setObjectSelectorState] = useState(null);
  // const [paginatedData] = useState(() => {
  //   return new PaginatedListData<TerminalObj>(
  //     apiTerminalObjects.getTerminalObjects
  //   );
  // });

  const [route, setRoute] = useState(new RouteWithSettings());
  const [filters] = useState(new RouteFiltersData());

  const winSize = useWindowSize();

  route.rerender = rerender;
  filters.updater = rerender;

  useEffect(() => {
    return () => {
      route.unmount();
      setRoute(null);
    };
  }, []);

  const iconStyle = {
    display: "inline",
    position: "relative",
    top: "3px",
    paddingRight: "8px",
    height: "16px",
    width: "16px",
  };

  const controlPanelList = [
    {
      id: "geometry",
      // name: "Геометрия",
      child: () => (
        <>
          <RouteSvg style={iconStyle} />
          Маршрут
        </>
      ),
    },
    {
      id: "settings",
      // name: "Настройки",
      child: () => (
        <>
          <SettingsSvg style={iconStyle} />
          Настройки
        </>
      ),
    },
    {
      id: "filters",
      // name: "Фильтры",
      child: () => (
        <>
          <FilterSvg style={iconStyle} /> Фильтры
        </>
      ),
    },
  ];
  const [activePanelId, setActivePanelId] = useState("geometry");
  const panelClickHandler = (id) => {
    setActivePanelId(id);
  };

  const hide = () => {
    setObjectSelectorState(null);
  };

  useEffect(() => {
    new RoutesAPI(props.mapApi.controller, route, filters);
    if (props.routeId) {
      route.getSavedRoute(props.routeId, props.viewMode);
      route.buildStatus = {
        end: 1,
        statusLast: "загрузка маршрута",
        percent: 100,
      };
    }
  }, []);

  const applySettings = () => {
    // tslint:disable-next-line: no-console
    route.buildStatus = {
      err: 0,
      statusErr: "OK",
      statusLast: "начато построение",
      udt: new Date().toISOString(),
      end: 0,
      percent: 0,
    };
    route.runRoute();
  };
  const drawPoisOnMap = (poiIds: string[]) => {
    route.api.drawPoisOnMap(route.buildContent._id, poiIds);
  };

  return (
    <>
      {route.buildStatus !== undefined ? (
        route.buildContent !== undefined ? (
          <BuiltRouteComponent
            viewMode={props.viewMode}
            fuelTypes={filters.fuelTypes}
            locatorSettings={route.api.locatorSettings}
            closeRoute={() => route.resetBuild()}
            saveRoute={(params: SaveRouteParams) =>
              route.api.saveRouteToShorLink(params)
            }
            mapNavigation={props.mapApi.simpleController.setCenter}
            getPoiInfo={(routeId) => route.api.getPoiInfo(routeId)}
            drawPoisOnMap={drawPoisOnMap}
            buildContent={route.buildContent}
            activePOI={route.activePOI}
            setActivePOI={(newPOI: number) => (route.activePOI = newPOI)}
            reverseGeocoder={(ll) => route.api.reverseGeocoder(ll)}
            points={route.points}
            isViewAll={route.isViewAll}
            setIsViewAll={(p) => (route.isViewAll = p)}
            sendToWialon={route.api.sendToWialon}
          />
        ) : (
          <RouteProgress
            status={route.buildStatus}
            cancel={route.stopRouteBuild}
          />
        )
      ) : (
        <>
          <VehicleSelectComponent
            vehicleId={props.vehicleId}
            filters={filters}
            route={route}
          />

          <Tabs
            list={controlPanelList}
            activeId={activePanelId}
            handler={panelClickHandler}
            primary={true}
          />
          <div className="tabs-content">
            {activePanelId === "geometry" && (
              <>
                <PointsList
                  points={props.points}
                  route={route}
                  routeSets={route}
                  setObjectSelectorState={setObjectSelectorState}
                  applySettings={applySettings}
                />
              </>
            )}
            {activePanelId === "settings" && <RouteSettings route={route} />}
            {activePanelId === "filters" && <RouteFilters filters={filters} />}
            {/* <div className="route-buttons-panel">
              <Button
                disabled={
                  !(!route.buildStatus && route.startPoint && route.finishPoint)
                }
                size="xl"
                primary={true}
                onClick={applySettings}
                style={{ width: "172px", float: "right" }}
              >
                построить
              </Button>
            </div> */}
          </div>
        </>
      )}
    </>
  );
};
