import { GenericEvent } from "./genericEvent";

export class GenericTask<Params, Res> extends GenericEvent<Res> {
  public get workersCount(): number {
    return this.workers.length;
  }

  public get maxWorkersCount(): number {
    return this._maxWorkersCount;
  }

  protected workers = [];

  protected _maxWorkersCount: number;

  constructor(
    setTrigger: (trigger: (newValue: Res) => void) => void,
    maxWorkersCount: number
  ) {
    super(setTrigger);
    this._maxWorkersCount = maxWorkersCount;
  }

  public subscribeAsWorker(
    workTrigger: (params: Params) => void
  ): { remove: () => void } {
    if (this.maxWorkersCount === this.workersCount) {
      throw new Error("Worker queue is full");
    }

    this.workers.push(workTrigger);
    return {
      remove: () => {
        this.workers = this.workers.filter(worker => worker !== workTrigger);
      }
    };
  }

  public require(params: Params): Promise<Res> {
    return new Promise((resolve, reject) => {
      if (!this.workers.length) {
        reject("Worker queue is empty");
      }
      const sub = this.subscribe((newValue: Res) => {
        if (newValue instanceof Error) {
          reject(newValue);
        }
        sub.remove();
        resolve(newValue);
      });

      this.workers.forEach(worker => {
        setTimeout(() => {
          try {
            worker(params);
          } catch (err) {
            // reject(err)
          }
        }, 0);
      });
    });
  }
}
