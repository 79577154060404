import React, { useEffect } from "react";

import "./style.scss";
declare namespace Ya {
  function share2(e: Element, params: any): void;
}

export const Share: React.FC<{ link: string; title: string }> = props => {
  const yaShare = () => {
    // Yandex share
    const shareContainer = document.querySelector(".ya-share");
    // const Ya = (window as any).Ya;
    if (Ya && shareContainer) {
      Ya.share2(shareContainer, {
        content: {
          url: props.link
        },
        theme: {
          services: "whatsapp,viber"
        }
      });
    }
  };
  const createYaScript = async () => {
    const script = document.getElementById("yaShare");
    if (!script) {
      const newScript = document.createElement("script");
      newScript.id = "yaShare";
      newScript.className = "yandex-share";
      newScript.type = "text/javascript";
      newScript.async = true;
      newScript.src = "https://yastatic.net/share2/share.js";
      document.head.appendChild(newScript);
      newScript.onload = () => yaShare();
      return;
    } else {
      yaShare();
    }
  };
  useEffect(() => {
    createYaScript();
  }, [props.link]);

  const goToLink = () => {
    window.open(props.link);
  };

  const onCopy = e => {
    e.preventDefault();
    const link = document.getElementById("share-link") as HTMLInputElement;
    if (link) {
      link.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.warn("No support copy command");
      }
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
    }
  };

  return (
    <div className="share">
      <label className="share__label">{props.title}</label>
      <input id="share-link" type="text" readOnly value={props.link} />
      <div className="share__item share__item-hovered" onClick={onCopy}>
        Скопировать
      </div>
      <div className="share__item">
        Поделиться
        <div className="ya-share" />
      </div>
      <div className="share__item share__item-hovered" onClick={goToLink}>
        Перейти
      </div>
    </div>
  );
};
