import React, { useEffect, useState, useContext } from "react";
import { PoiContext } from "./poiContext";
import { cn } from "@bem-react/classname";

import "./style/photo.scss";
const GOOGLE_KEY_PANORAM = "AIzaSyDc69nqKGMG1gRNrevhfpu7J3k-1ex_m28";

export const CardPhoto: React.FC = () => {
  const cN = cn("routecard-photo");
  const poiContext = useContext(PoiContext);
  const [isPanorama, setIsPanorama] = useState(false);
  const [isPhoto, setIsPhoto] = useState(false);

  useEffect(() => {
    setIsPanorama(false);
  }, [poiContext.poiId]);

  if (!poiContext.poiContent) {
    return null;
  }
  const { ll } = poiContext.poiContent;
  //   const { settings } = props;
  const photos = [
    poiContext.poiId
      ? `https://cdn-img.multigo.ru/1/img/poi/${poiContext.poiId}/main/400`
      : ""
  ];

  // Нет фото для категорий
  let className = "";
  //   if (props.poi.sub_id === 2202) className += " wash";
  //   if (props.poi.sub_id === 2203) className += " tire";

  // Фото с Booking (только для отелей)
  const { booking_ext_data } = poiContext.poiContent;
  const navigate = () => {
    poiContext.mapNavigation(ll);
  };
  if (
    booking_ext_data &&
    booking_ext_data.hotel_data &&
    booking_ext_data.hotel_data.hotel_photos
  ) {
    booking_ext_data.hotel_data.hotel_photos.map((obj, idx) => {
      photos.push(obj.url_original);
    });
  }
  return (
    <>
      <div
        className={
          "zoom " +
          cN(isPanorama ? "panorama" : "photo", {
            purpose: (poiContext.poi && poiContext.poi.purpose) || "refuel"
          })
        }
      >
        {!isPanorama || !ll ? (
          <img src={photos[0]} />
        ) : (
          <iframe
            src={`https://www.google.com/maps/embed/v1/streetview?key=
              ${GOOGLE_KEY_PANORAM}&language=ru&location=
              ${ll[0]},${ll[1]}&pitch=10&heading=${poiContext.poiContent
              .bearing || 300}&fov=100`}
            width="100%"
            height="300"
            frameBorder="0"
            style={{ verticalAlign: "bottom" }}
            allowFullScreen
          />
        )}

        {ll ? (
          <div className={cN("buttons")}>
            <button
              className={cN("control")}
              onClick={() => {
                setIsPanorama(!isPanorama);
              }}
            >
              {isPanorama ? "закрыть" : "панорама"}
            </button>

            <button onClick={navigate} className={cN("control")}>
              карта
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CardPhoto;
