import { GenericEvent, GenericTask } from "../generics";
import { GenericController } from "./generics";

export interface IRoadsController {
  createRoad: GenericTask<RoadData, number | string>;
  pointMoved: GenericEvent<{
    routeId: number | string;
    pointId: number;
    latlng: { lat: number; lng: number };
  }>;
  pointCreated: GenericEvent<{
    routeId: number | string;
    insertIndex: number;
    latlng: { lat: number; lng: number };
  }>;
  removeRoad: GenericTask<number | string, boolean>;
}

interface RoadData {
  id: number | string;
  latlngs: any;
  viaPoints: Array<[number, number]>;
}
export class RoadsController extends GenericController
  implements IRoadsController {
  public createRoad: GenericTask<RoadData, number | string>;

  public pointMoved: GenericEvent<{
    routeId: number | string;
    pointId: number;
    latlng: { lat: number; lng: number };
  }>;

  public pointCreated: GenericEvent<{
    routeId: number | string;
    insertIndex: number;
    latlng: { lat: number; lng: number };
  }>;

  public removeRoad: GenericTask<number | string, boolean>;

  constructor(setTriggers: (triggers: any) => void) {
    super();
    const triggers = {} as any;
    const createTrigger = triggerName => trigger => {
      triggers[triggerName] = trigger;
    };
    this.createRoad = new GenericTask<RoadData, string>(
      createTrigger("createRoad"),
      1
    );
    this.removeRoad = new GenericTask<number | string, boolean>(
      createTrigger("removeRoad"),
      1
    );
    this.pointMoved = new GenericEvent<{
      routeId: number | string;
      pointId: number;
      latlng: { lat: number; lng: number };
    }>(createTrigger("pointMoved"));
    this.pointCreated = new GenericEvent<{
      routeId: number | string;
      insertIndex: number;
      latlng: { lat: number; lng: number };
    }>(createTrigger("pointCreated"));

    setTriggers(triggers);
  }
}
