import "./style.scss";

import React from "react";

import { RouteContent } from "multigo-map-api";
import { LocatorSettings } from "multigo-map-api/types/Controllers/Api/map/types";
import { SaveRouteParams } from "multigo-map-api/types/Controllers/Router/routerBuilder";
import { Button } from "multigo-ui";
import { RoutePoint } from "src/components/RoutePanel/data/point";

import { useBooleanState } from "../../../../../../../utils/hooks/useBooleanState";
import { SendToWialon } from "../../../../../data/types";
import { ExternalRoute } from "../ExternalRoute";
import FloatBox from "../FloatBox";
import { ShortRouteLink } from "../ShortRouteLink";

const defaultProps = {
  //     /*  Частный случай, так как у меня уже
  //         есть печатная форма и я её пока не переношу, использую внешнюю */
  getPrintForm: () => null,
  //     // Название маршрута
  routeName: "",
  //     // Id построенного маршрута
  routeId: "",
  //     // Разрешение на сохранение маршрута
  saveRoute: false,
  //     // Маршрутный лист
  routeList: [],
};
export const SaveSend: React.FC<{
  isButton: boolean;
  buildContent: RouteContent;
  locatorSettings: LocatorSettings;
  points: RoutePoint[];
  excludedIds: string[];
  saveRoute: (
    params: SaveRouteParams
  ) => Promise<{ shortId: string; shortUrl: string }>;
  sendToWialon?: SendToWialon;
}> = (props) => {
  // static
  const { routeId, visible } = props.buildContent;

  // constructor( props ){
  //     super( props );

  //     this.state = {
  //         isOpenShareForm: false,
  //         isOpenSaveRouteForm: false
  //     }

  // }

  const [
    isOpenShareForm,
    setIsOpenShareForm,
    toggleIsOpenShareForm,
  ] = useBooleanState(false);
  const [
    isOpenWialonForm,
    setIsOpenWialonForm,
    toggleIsOpenWialonForm,
  ] = useBooleanState(false);
  const [
    isOpenSaveRouteForm,
    setIsOpenSaveRouteForm,
    toggleIsOpenSaveRouteForm,
  ] = useBooleanState(false);

  // const { saveRoute } = props;
  return (
    <React.Fragment>
      {props.isButton && (
        <Button
          onClick={toggleIsOpenSaveRouteForm}
          className="route-info-navigator-button"
          size="xl"
          primary={true}
        >
          Запланировать рейс
        </Button>
      )}

      <div className="save-send">
        {/* <div
          className="save-send__item"
          // onClick={onOpenPrintForm}
        >
          <div className="save-send__name">Распечатать</div>
          <img
            className="save-send__img"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAQAAAAOXfQCAAAAxklEQVR4AdWUOwrCQBCGJw8QvKCd5fxpLMwJttbOW2RrPVBAMZUHyMM4wSom+wqCOH8zDPuxu/MiGhlr7g3STBYzYoOcYED8x6BKVaoTnahYxX0kmgd3Kxz4Zk3Kh3DNlEoJx1HwwTXXaNBwyy06dPwUTeBMEe5yfAvytwzDre5vGzJhBXERzfgeIJ95xneCi5/qCXpV8augf6++wUrKuUEAiFz8knDyGdhJy+2J14JWQWCJvI+sf3HH/xZcsh5RGBdGMR72Fy5pibWmQShzAAAAAElFTkSuQmCC"
          />
        </div> */}

        {!props.isButton && (
          <div className="save-send__item" onClick={toggleIsOpenShareForm}>
            <div className="save-send__name">Посмотреть в Яндекс/Google</div>
            <img
              className="save-send__img"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAABHElEQVR4AZ2UMWsCQRCFLwkkkDRJlZBc2iRl6kDaFPkRaQLzvNNDCxutrBT/hxxq75UiWFpYWdhYKJ5YiCBaKa7L3gqiwo7ONlvMG2a/ebOWZQzPhk8hhfA92+Kk04SEPhOGRFYXFHi2FAYk4BsFshkR1ZUSQaEhPfmIJVvgPCBPc9W7uaX0HWVpqpJXNDM82rtBEmOVsEbFeVdYhztYNecBSonn6hX+0Y/qoeZ+GjjLul19b7hfLM5K1qIfFmf3VQkK7MFoQVFcsEZPH1TX/bfxyzQXxujoe9P5NtlXYc1d4o96WhQcxXoYuWuKY6QHV46/xV72BncUxS0ykTWk+WbMfUjdS/MtmObbhvt0gr3PWqAzVvTkT2D/m9kA5PX/yiQfbxsAAAAASUVORK5CYII="
            />
          </div>
        )}

        {/* Разрешено ли сохранять ссылку на маршрут  */}
        {/* {visible.hideRPlan !== 1 && (
          <div className="save-send__item" onClick={toggleIsOpenSaveRouteForm}>
            <div className="save-send__name">Запланировать рейс</div>
            <img
              className="save-send__img"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAUElEQVR4Ae3RQQoAIAgEQF8bPcOfF9VByVXyFpF73YlKIjOlSehkPngdVNZVncop4tYxCeuWgLp9mpC9PrvoLxaxp7tgEHT3APgbuhVkkgYdRT4NWBI4SuwAAAAASUVORK5CYII="
            />
          </div>
        )} */}

        {/* {props.sendToWialon &&
          props.buildContent.vehicle &&
          props.buildContent.vehicle.wialonId && (
            <div className="save-send__item" onClick={toggleIsOpenWialonForm}>
              <div className="save-send__name">Отправить в Wialon</div>
              <img
                className="save-send__img"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAABHElEQVR4AZ2UMWsCQRCFLwkkkDRJlZBc2iRl6kDaFPkRaQLzvNNDCxutrBT/hxxq75UiWFpYWdhYKJ5YiCBaKa7L3gqiwo7ONlvMG2a/ebOWZQzPhk8hhfA92+Kk04SEPhOGRFYXFHi2FAYk4BsFshkR1ZUSQaEhPfmIJVvgPCBPc9W7uaX0HWVpqpJXNDM82rtBEmOVsEbFeVdYhztYNecBSonn6hX+0Y/qoeZ+GjjLul19b7hfLM5K1qIfFmf3VQkK7MFoQVFcsEZPH1TX/bfxyzQXxujoe9P5NtlXYc1d4o96WhQcxXoYuWuKY6QHV46/xV72BncUxS0ykTWk+WbMfUjdS/MtmObbhvt0gr3PWqAzVvTkT2D/m9kA5PX/yiQfbxsAAAAASUVORK5CYII="
              />
            </div>
          )} */}
      </div>
      {isOpenShareForm && (
        <FloatBox title="Отправка маршрута" close={toggleIsOpenShareForm}>
          <ExternalRoute list={props.buildContent.list} />
        </FloatBox>
      )}
      {/* {isOpenWialonForm && (
        <FloatBox title="Отправка маршрута" close={toggleIsOpenWialonForm}>
          <SendToWialonForm
            routeId={props.buildContent._id}
            sendToWialon={props.sendToWialon}
            close={toggleIsOpenWialonForm}
            description={getRouteDescription(props.buildContent)}
          />
        </FloatBox>
      )} */}

      {isOpenSaveRouteForm && (
        <FloatBox
          title={routeId ? "Запланирован новый рейс" : "Сохранение рейса"}
          close={toggleIsOpenSaveRouteForm}
        >
          <ShortRouteLink
            excludedIds={props.excludedIds}
            saveRoute={props.saveRoute}
            routeId={props.buildContent._id}
            routeName=""
            isRouteIdExists={!!routeId}
            routeInfo={props.buildContent}
            onClose={toggleIsOpenSaveRouteForm}
          />
        </FloatBox>
      )}
    </React.Fragment>
  );
};
