import React, { useContext } from "react";
import { PoiContext } from "../poiContext";
import { cn } from "@bem-react/classname";

export const PoiName: React.FC = () => {
  const poiContext = useContext(PoiContext);
  if (!poiContext.poiContent) {
    return null;
  }

  const cN = cn("routecard-name");
  const poi = poiContext.poiContent;
  const subCat = (poi.subCategory && poi.subCategory.name) || "";
  const imgUrl =
    (poi.brand && poi.brand.imgUrl) ||
    (poi.subCategory && poi.subCategory.imgUrl) ||
    (poi.category && poi.category.imgUrl) ||
    "";
  const { rating } = poi;
  const stars =
    (rating &&
      rating.count &&
      rating.value &&
      Math.min(Math.floor(rating.value * 2), 10)) ||
    0;

  return (
    <div className={cN("wrapper")}>
      <div className={cN("inner")}>
        <img src={imgUrl} className={cN("image")} />
        <p className={cN("name")}>
          {poi.brand ? (
            <>
              {poi.brand.name}
              <br />
            </>
          ) : (
            ""
          )}

          {poi.name || ""}
        </p>
      </div>
      <div className={cN("under-name")}>
        {rating && (
          <div
            className={cN("rating")}
            style={{ backgroundPositionY: `${-14 * stars - 1}px` }}
          >
            {rating && rating.count ? `(${rating.count})` : ""}
          </div>
        )}
        {/* <button className="routecard-main-link">Написать отзыв</button> */}
      </div>
      {poiContext.poi && poiContext.poi.purpose !== "refuel" && (
        <div className={cN("subcat")}> {subCat}</div>
      )}
      <div></div>
    </div>
  );
};

export default PoiName;
