import * as React from "react";
import { TimeField } from "../../../../utils/sharedComponents/timeField";

export const TimeSelector = props => {
  const timeHandler = newTime => {
    props.field.handler(newTime);
  };
  return (
    <>
      <p className="route-settings-name">{props.field.name}</p>
      <TimeField
        style={{ width: "60px" }}
        value={props.field.value || "00:00"}
        onChange={timeHandler}
      />
    </>
  );
};
