import * as React from 'react';
import { IController } from '../Controllers';
import { MapLoader } from '../loader';

export interface Props {
  apiKey: string;
  mapTriggers: any;
  controller: IController;
  debug: boolean;
}
export interface State {
  mapLoaded: boolean;
}

export class MapComponent extends React.Component<Props, State> {
  public MultiGoMap: any;

  public state: State;

  private mapLoader: MapLoader;

  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,
    };
    this.mapLoader = new MapLoader(props.debug, () => {
      this.MultiGoMap = (window as any).MultiGoMap.default;
      this.setState({
        mapLoaded: true,
      });
    });
  }

  public componentDidMount() {
    this.mapLoader.load();
  }

  public render() {
    const { mapLoaded } = this.state;
    const { children, apiKey } = this.props;
    return (
      <React.Fragment>
        {mapLoaded ? (
          <this.MultiGoMap
            apiKey={apiKey}
            mapTriggers={this.props.mapTriggers}
            controller={this.props.controller}
          />
        ) : (
          <div>{children} </div>
        )}
      </React.Fragment>
    );
  }
}
