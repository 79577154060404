// Контент карточки списка
import * as React from "react";
import { useContext, useMemo } from "react";
import { TollItemCard, PunctItemCard, CommonItemCard } from "./items";
import { TOLL_NAMES, PUNCT_NAMES, LANDMARK } from "../../utils/consts";
import { getDistance } from "../../utils";
import { RouteListContext } from "../../utils/context";
import { PoiWithScroll } from "../RouteListItem";
import { checkPropTypes } from "prop-types";

interface ItemPointProps {
  item: PoiWithScroll;
  numPunct: number;
}
let renderCount = 0;
export const ItemPoint = (props: ItemPointProps) => {
  const context = useContext(RouteListContext);
  const memoItem = useMemo(() => {
    // Картинка брэнда

    const imgUrl = context.getBrandImageUrl(props.item);
    const { info } = context;

    // Платные дороги, Платон
    if (TOLL_NAMES[props.item.type_id] || props.item.point_type === "PVP") {
      return <TollItemCard item={props.item} />;
    }

    if (
      PUNCT_NAMES[props.item.type_id] ||
      LANDMARK[props.item.type_id] ||
      props.item.admin_level
    ) {
      return (
        <PunctItemCard
          item={props.item}
          pinName={props.numPunct}
          imgUrl={imgUrl}
          contextInfo={info}
        />
      );
    }

    return <CommonItemCard item={props.item} imgUrl={imgUrl} />;
  }, [props.item.dist]);
  return memoItem;
};
