import { useState } from "react";

export const useBooleanState = (defaultState: boolean) => {
  const [value, setValue] = useState(defaultState);
  const toggle = () => setValue(!value);
  return [value, setValue, toggle] as [
    boolean,
    React.Dispatch<
      boolean | ((prevState: false) => false) | ((prevState: true) => true)
    >,
    () => void
  ];
};
