import {
  FullPoiData,
  FullPoiInfo,
  TarifPolProps,
} from "multigo-map-api/types/Controllers/Api/map/types";
import {
  DefaultResponse,
  FuelCard,
  RefuelInfo,
} from "multigo-map-api/types/Controllers/Api/types";

import { Accordion } from "multigo-ui";
import React, { useEffect, useState } from "react";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import { useIsMobile } from "../../utils/hooks/useMobileDetect";
import { RoutePoint } from "../RoutePanel/data/point";
import FuelColors from "./FuelColors";
import { FuelPrice } from "./FuelPrice";
import { FuelTankInfo, PoiFuelInfo } from "./FuelTankInfo";
import { GenericCards } from "./genericCards";
import { MainBlock } from "./mainBlock";
import { Navigation } from "./Navigation";
import { CardPhoto } from "./Photo";
import { PoiContext } from "./poiContext";
import {
  NavigationPoints,
  NavigationSchedule,
  RouteNavigation,
} from "./RouteNavigation";
import { RouteSchedule } from "./Schedule";
import { Services } from "./Services";
import "./style/main.scss";
import { CardTrassa } from "./Trassa";
import { usePoiCache } from "./usePoiCache";
import { WarningRouteCard } from "./WarningCard";

export interface PoiCardProps {
  poiId: string;
  getPoiInfo: (
    poiId: string
  ) => Promise<DefaultResponse<{ object: FullPoiData }>>;
  navigation?: { points: NavigationPoints; schedule: NavigationSchedule };
  poi?: PoiFuelInfo;
  closeCard: () => void;
  fuelPriceColors?: Array<{
    min: number;
    max: number;
    color: string;
    bg: string;
  }>;
  fuelIndex?: number;
  avgPrice?: number;
  price?: number;
  tarifPolProps: TarifPolProps;
  mapNavigation?: (center: [number, number]) => void;
  refuelsList: RefuelInfo[];
  visibleParts: {
    hideTP?: number;
    hideYPay?: number;
    hideCSumm?: number;
    showObjectLL?: number;
  };
}

export const PoiCard: React.FC<PoiCardProps> = (props) => {
  const poiGetter = usePoiCache(props.getPoiInfo);
  const [poiContent, setPoiContent] = useState(undefined as FullPoiInfo);
  if (
    (props.poi.point_type === "refuel" ||
      props.poi.point_type === "rest" ||
      props.poi.point_type === "sleep") &&
    props.poi.purpose
  ) {
    return <WarningRouteCard poi={props.poi} />;
  }
  useEffect(() => {
    poiGetter(props.poiId).then((data) => {
      setPoiContent(data.details);
    });
  }, [props.poiId]);

  const selectedFuel =
    props.poi &&
    props.poi.details &&
    props.poi.details.fuels &&
    props.poi.details.fuels.find((f) => f.selected);

  const fuelName = selectedFuel && (selectedFuel.fuelId as string | undefined);
  const fuelCard = selectedFuel && selectedFuel.fuelCard;

  const isMobile = useIsMobile();

  const vCat = (props.poi && props.poi.listVCat && props.poi.listVCat[0]) || 0;
  const vCatData = props.refuelsList.find((r) => r.vcat === vCat);

  return (
    <PoiContext.Provider
      value={{
        poi: props.poi,
        fuelName,
        fuelCard,
        poiId: props.poiId,
        getPoiInfo: props.getPoiInfo,
        points: props.navigation && props.navigation.points,
        schedule: props.navigation && props.navigation.schedule,
        fuelPriceColors: props.fuelPriceColors,
        fuelIndex: props.fuelIndex,
        avgPrice: vCatData ? vCatData.avgPrice : props.avgPrice,
        price: props.poi && props.poi.price,
        mapNavigation: props.mapNavigation,
        poiContent,
        refuelsList: props.refuelsList,
        vCatData,
        visibleParts: props.visibleParts,
      }}
    >
      <div className={`route-card-wrapper ${isMobile ? " mobile" : ""}`}>
        <div className="built-route-header">
          <div className="close-route-button" onClick={props.closeCard}>
            <ArrowLeft />
          </div>
          <span>Назад</span>
        </div>
        <Accordion
          headSize={isMobile ? 56 : 44}
          items={[
            {
              title: "маршрут".toUpperCase(),
              defaultOpen: true,
              body: (
                <>
                  {props.navigation && <RouteNavigation />}
                  {props.poi && props.poi.purpose === "refuel" && (
                    <FuelTankInfo poi={props.poi} />
                  )}
                  {props.visibleParts.hideCSumm !== 1 &&
                  props.poi &&
                  props.poi.purpose === "refuel" &&
                  props.fuelPriceColors &&
                  props.fuelPriceColors.length ? (
                    <FuelColors />
                  ) : (
                    ""
                  )}
                  {props.navigation &&
                    props.navigation.schedule &&
                    props.navigation.schedule.timeTimestamp && (
                      <RouteSchedule />
                    )}
                </>
              ),
            },
          ]}
        />
        <CardPhoto />
        <MainBlock />
        <Accordion
          headSize={isMobile ? 56 : 44}
          items={[
            {
              doNotRender: !(poiContent && poiContent.road),
              title: "Трасса".toUpperCase(),
              defaultOpen: true,
              body: <CardTrassa />,
            },
            {
              doNotRender: !(poiContent && poiContent.ll),
              title: "навигация".toUpperCase(),
              defaultOpen: true,
              withAccent: true,
              body: <Navigation />,
            },

            {
              doNotRender: !(
                poiContent &&
                poiContent.services &&
                poiContent.services.length
              ),
              title: "услуги".toUpperCase(),
              defaultOpen: true,
              body: <Services />,
            },
            {
              doNotRender: !(
                poiContent &&
                poiContent.fuelCards &&
                poiContent.fuelCards.length
              ),
              title: "топливные карты".toUpperCase(),
              defaultOpen: true,
              body: (
                <GenericCards
                  tarifPolProps={props.tarifPolProps}
                  cards={poiContent ? poiContent.fuelCards : ([] as FuelCard[])}
                />
              ),
            },
            {
              doNotRender: !(
                poiContent &&
                poiContent.loyaltyCards &&
                poiContent.loyaltyCards.length
              ),
              title: "Карты лояльности".toUpperCase(),
              defaultOpen: false,
              body: (
                <GenericCards
                  tarifPolProps={props.tarifPolProps}
                  cards={
                    poiContent ? poiContent.loyaltyCards : ([] as FuelCard[])
                  }
                />
              ),
            },

            {
              doNotRender: !(poiContent && poiContent.fuels),
              title: "топливо по видам и ценам".toUpperCase(),
              defaultOpen: true,
              body: <FuelPrice />,
            },
          ]}
        />
      </div>
    </PoiContext.Provider>
  );
};

export default PoiCard;
