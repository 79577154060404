import React from "react";
import { Button } from "multigo-ui";

export const WarningRouteCard: React.FC<{ poi: any }> = props => {
  //   if (poiContext.poi.nearest_id) {
  //     const nearest = props.pointsList.find(
  //       p => props.poi.nearest_id === p.poi_id
  //     );
  //   }
  return (
    <div className="route-card">
      <Button primary={true} onClick={() => {}}>
        close
      </Button>
      <div className="route-list-item  shift poi-23 attention">
        <div className="card-head">
          <div className="title-attention">Внимание</div>
        </div>
        <div className="mgo-attention-item-card">
          <div className="mgo-attention-item-card__row">
            <div className="mgo-attention-item-card__left">
              <div className="mgo-attention-item-card__name">
                <img src="https://cdn-img.multigo.ru/1/img/category-icon/23/0/travel" />
                Не нашли места отдыха
              </div>
            </div>
            <div className="mgo-attention-item-card__right">
              <div className="mgo-attention-item-card__km">1000 км</div>
            </div>
          </div>
          <div className="mgo-attention-item-card__row">
            <div className="mgo-attention-item-card__near">
              Ближайшее к данной точке место отдыха
              <div className="mgo-attention__near-info">
                <div className="mgo-attention__near-row">
                  <div className="mgo-attention__near-name">
                    Площадка отдыха
                  </div>
                  <div className="mgo-attention__near-km">1023 км</div>
                </div>
                <div className="mgo-attention__near-row">
                  <div className="mgo-attention__near-address">
                    наб. Котельническая, д.1/15
                  </div>
                </div>
              </div>
              находится на расстоянии 23 км
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningRouteCard;
