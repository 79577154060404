import * as React from "react";
import { SettingsRow } from "../../data/builtRoute";
import { DisabledInput } from "./-1disabledInput";
import { RtoSelector } from "./12RtoProps";
import { NumberSelector } from "./1numberSelect";
import { ValueSelector } from "./2valuesSelect";
import { BinarySelector } from "./3binarySelect";
import { TimeSelector } from "./5timeSelect";
import { DatetimeSelector } from "./8datetimeSelect";

export const getFieldComponent = (field: SettingsRow, index: number) => {
  const innerComponent = (() => {
    switch (field.type) {
      case -1:
        return <DisabledInput field={field} />;
      case 1:
        return <NumberSelector field={field} />;
      case 11:
      case 2:
      case 7:
        return <ValueSelector field={field} />;
      case 3:
        return <BinarySelector field={field} />;
      case 4:
        return <DatetimeSelector field={field} showTime={false} />;
      case 5:
        return <TimeSelector field={field} />;
      case 8:
        return <DatetimeSelector field={field} showTime={true} />;
      case 9:
        return <hr />;
      case 12:
        return <RtoSelector field={field as any} />;
    }
    return <div>ERR</div>;
  })();
  return (
    <div key={index} className="route-settings-row">
      {innerComponent}
    </div>
  );
};
