import React from "react";

import Phone from "../../../assets/icons/routecards/phone.svg";
import Region from "../../../assets/icons/routecards/region.svg";
import Time from "../../../assets/icons/routecards/time.svg";
import Address from "../../../assets/icons/routecards/address.svg";
import Email from "../../../assets/icons/routecards/email.svg";
import Site from "../../../assets/icons/routecards/site.svg";
import { cn } from "@bem-react/classname";

export interface MainBlockIconProps {
  type: "region" | "address" | "time" | "phone" | "email" | "site";
}
const cN = cn("routecard-main");
export const MainBlockIcon: React.FC<MainBlockIconProps> = props => {
  switch (props.type) {
    case "region":
      return <Region className={cN("icon")} />;
    case "address":
      return <Address className={cN("icon")} />;
    case "time":
      return <Time className={cN("icon")} />;
    case "phone":
      return <Phone className={cN("icon")} />;
    case "email":
      return <Email className={cN("icon")} />;
    case "site":
      return <Site className={cN("icon", { withAccent: true })} />;
  }
  return null;
};

export default MainBlockIcon;
