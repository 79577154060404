import * as React from "react";
import { useEffect, useState } from "react";

import { IMapAPI, MapAPI } from "multigo-map-api";

import "../assets/scss/main.scss";
import { RoutePanel } from "./RoutePanel";
interface AppProps {
  mapKey: string;
  debug?: boolean;
  showControlPanel: boolean;
  routeId?: string;
  isMobile?: boolean;
  vehicleId?: string;
  points?: string;
  viewMode?: boolean;
}
import { useMobileDetect } from "../utils/hooks/useMobileDetect";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import { setApiCredentials } from "../utils/terminalApi/common";
import { withMapAPI } from "./MapApiWrapper";

interface LocatorProps {
  routeId?: string;
  vehicleId?: string;
  points?: string;
  mapApi: IMapAPI;
  isMobile?: boolean;
  mapLoaded: boolean;
  viewMode?: boolean;
}

export const Locator: React.FC<LocatorProps> = (props) => {
  const { terminalId } = props.mapApi.controller.map.data;

  useEffect(() => {
    setApiCredentials({ activeTerminalId: terminalId });
  }, []);

  const mobileDetect = useMobileDetect();
  const isMobile = mobileDetect.isMobile();

  return (
    <>
      {props.mapLoaded && (
        <RoutePanel
          mapApi={props.mapApi}
          routeId={props.routeId}
          vehicleId={props.vehicleId}
          viewMode={props.viewMode}
          points={props.points}
          isMobile={isMobile}
        />
      )}
      {/* показываем карту только на десктопе и когда она загружена  */}
      {!isMobile && props.mapLoaded ? (
        <div className="map-component-wrapper">{props.mapApi.MultigoMap()}</div>
      ) : null}
    </>
  );
};

export const LocatorApp = (props: AppProps) => {
  return withMapAPI(
    props.mapKey,
    props.debug
  )((newProps) => (
    <Locator
      mapLoaded={newProps.mapLoaded}
      routeId={props.routeId}
      vehicleId={props.vehicleId}
      points={props.points}
      mapApi={newProps.mapApi}
      isMobile={props.isMobile}
      viewMode={props.viewMode}
    />
  ));
};
