import React from "react";
import { WithTooltip } from "multigo-ui";

const srcDict = {
  0: {
    name: "E",
    tooltip: "Ошибка"
  },
  1: {
    name: "S",
    tooltip: "Серверная настройка"
  },
  2: {
    name: "U",
    tooltip: "Недоступна для редактирования в настройках маршрута"
  },
  3: {
    name: "P",
    tooltip: "Доступна для редактирования в настройках маршрута"
  }
};
// src
// 0 - не использовано
// 1 - точно есть у Димы
// 2 - options
// 3 - controlled optionalCallExpression

export const Label: React.FC<{
  label: number;
}> = props => {
  return (
    <WithTooltip
      position="left"
      text={(srcDict[props.label] || srcDict[0]).tooltip}
    >
      <div className={`label type-${props.label}`}>
        {(srcDict[props.label] || srcDict[0]).name}
      </div>
    </WithTooltip>
  );
};
