import { Button } from "multigo-ui";
import { Line } from "rc-progress";
import React from "react";
import { BuildStatus } from "../data/builtRoute";

export interface RouteProgressProps {
  status: BuildStatus;
  cancel?: () => void;
}
export const RouteProgress: React.FC<RouteProgressProps> = props => {
  return (
    <div className="progress-block">
      {isFinite(props.status.percent) && (
        <div className="progress-block__percent"> {props.status.percent}%</div>
      )}
      <Line
        className="progress-block__line"
        percent={props.status.percent}
        strokeWidth={3}
        strokeColor="#00afec"
      />
      <div className="progress-block__status">{props.status.statusLast}</div>
      {Number.isFinite(props.status.err) && props.status.err !== 0 && (
        <div className="progress-block__error">
          <p>Код ошибки: {props.status.err}</p>
          <p>{props.status.statusErr}</p>
        </div>
      )}
      {props.cancel && (
        <Button size="l" primary={true} onClick={props.cancel}>
          Отмена
        </Button>
      )}
    </div>
  );
};
