import * as React from "react";
import { useContext, useEffect, useState } from "react";

import { IRouteFiltersData } from "../data/routeFilters";
import { FilterChipBlock } from "./filterChipBlock";

interface RouteFiltersProps {
  style?: React.CSSProperties;
  filters: IRouteFiltersData;
}
export const RouteFilters = (props: RouteFiltersProps) => {
  useEffect(() => {
    return () => {
      props.filters.updater = undefined;
    };
  }, []);
  return (
    <div className="tabs-content">
      <div className="scroll-content">
        <div className="route-filters">
          {props.filters.chipBlocks &&
            props.filters.chipBlocks.map((chipBlock, chipBlockIndex) => (
              <FilterChipBlock
                chipBlock={chipBlock}
                key={chipBlockIndex}
                filters={props.filters}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
