import { SelectField } from "multigo-ui";
import * as React from "react";

export const DisabledInput = props => {
  const getValue = () => {
    if (
      !props.field.values.length ||
      props.field.values.every(value => typeof value === "number")
    ) {
      return props.field.value;
    } else {
      return props.field.values.find(({ _id }) => _id === props.field.value)
        .name;
    }
  };
  return (
    <>
      <p className="route-settings-name">{props.field.name}</p>
      <input value={getValue()} disabled={true} />
    </>
  );
};
