import { cn } from "@bem-react/classname";
import { RouteListRecord } from "multigo-map-api";
import { Button } from "multigo-ui";
import React from "react";
import ReactDOM from "react-dom";
import { getUrlGoogle, getUrlNav, getUrlYa } from "./ProcessRoute/utils";
const cN = cn("sprocess-route");

export const SimpleProcessRoute: React.FC<{
  list: RouteListRecord[];
  close: () => void;
}> = props => {
  const node = document.getElementById("process-route-anchor");

  const yaUrl = getUrlYa(props.list);
  const googleUrl = getUrlGoogle(props.list);
  const yaNavUrl = getUrlNav(props.list);

  return ReactDOM.createPortal(
    <div className={cN("wrapper")}>
      <button onClick={props.close} className={cN("close")}>
        Закрыть
      </button>
      <div className={cN("title")}>Поехали!</div>

      <a href={yaUrl} target="_blank">
        <Button primary={true} size="xxl">
          Яндекс Карты
        </Button>
      </a>
      <a href={yaNavUrl}>
        <Button primary={true} size="xxl">
          Яндекс Навигатор
        </Button>
      </a>
      <a href={googleUrl}>
        <Button primary={true} size="xxl">
          Google Maps
        </Button>
      </a>
    </div>,
    node
  );
};

export default SimpleProcessRoute;
