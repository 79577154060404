import { IAPIController } from "../Api";
import { GenericEvent, GenericTask, GenericValue } from "../generics";

import { StaticIcon, StaticItemList } from "../Api/router";
import {
  PlatonProps,
  PoiDetails,
  RouteContent,
  RouteListRecord,
  TollProps,
} from "../Api/types";
import { GenericController } from "../Map/generics";
import { iconsData as iconsDataDict } from "./icons";
import { SaveRouteParams } from "./routerBuilder";

export interface RoutePoiProps {
  _id?: string;
  cid?: number;
  a?: number;
  b?: number;
  p?: number;
  r?: number;
  lat: number;
  lng: number;
  i: string;
  j: [number];
  brandUrl?: string;
  index?: number;
}

export interface IRouterController {
  run: GenericTask<{ ll: string; options: string }, string>;
  vrun: GenericTask<{ ll: string; vehicleId: string }, string>;
  check: GenericTask<
    string,
    {
      udt: string;
      percent: number;
      end: number;
      statusLast: string;
      statusErr: string;
    }
  >;
  subscribeCheck: (routeId: string) => void;
  leafletSettings: GenericValue<any>;
  drawRoute: GenericTask<DrawRoute, string>;
  drawRouteById: (id: string, isViewAll?: boolean) => void;
  drawRoutePoiTask: GenericTask<{ routeId: string; poiIds: string[] }, string>;
  drawRoutePoi: (routeId: string, poiIds: string[]) => void;

  removeRoute: GenericTask<string, boolean>;
  setRoute: (routeData: RouteContent) => void;
  get: GenericTask<string, RouteContent>;
  poiClick: GenericEvent<{ poiIndex: number; routeId: string }>;
  routes: { [key: string]: RouteContent };
  setPaymentSegmentsState: GenericTask<
    { routeId: string; showToll: boolean; showPlaton: boolean },
    { routeId: string; showToll: boolean; showPlaton: boolean }
  >;
  loadRoutePoints: (
    routeId: string,
    list: RouteListRecord[]
  ) => Promise<PointsCache>;
  setPointsCache: (key: string, data: PointsCache) => void;
  save: GenericTask<
    SaveRouteParams,
    {
      shortId: string;
      shortUrl: string;
    }
  >;
  getRoutePois: (
    routeId: string
  ) => Promise<{
    list: RoutePoiProps[];
    routeStaticIcons: { [key in StaticItemList]: StaticIcon };
  }>;

  wialonExportTask: GenericTask<
    { routeId: string; vehicleId: number; name?: string; desc?: string },
    { err?: string }
  >;
}
export interface DrawRoute {
  id: string;
  poiList: RouteListRecord[];
  geometries: string[];
  geometriesCan2: object[];
  Toll: TollProps;
  Platon: PlatonProps;
  isViewAll?: boolean;
}

export interface DrawRoutePoints {
  id: string;
  poiIds: string[];
}

interface PointsCache {
  errorIcons: RoutePoiProps[];
  list: RoutePoiProps[];
  routeStaticIcons: {
    [key in StaticItemList]: StaticIcon;
  };
}

export class RouterController implements IRouterController {
  public get iconsData(): {
    [key: string]: {
      iconSize: number[];
      iconAnchor: number[];
      iconUrl: string;
    };
  } {
    return iconsDataDict;
  }

  public get routes(): { [key: string]: RouteContent } {
    return this._routes;
  }

  public get buildRoadsIds(): string[] {
    return Object.keys(this._routes);
  }

  public run: GenericTask<{ ll: string; options: string }, string>;
  public vrun: GenericTask<{ ll: string; vehicleId: string }, string>;

  public wialonExportTask: GenericTask<
    { routeId: string; vehicleId: number; name?: string; desc?: string },
    { err?: string }
  >;

  public drawRoute: GenericTask<DrawRoute, string>;

  public removeRoute: GenericTask<string, boolean>;

  public setPaymentSegmentsState: GenericTask<
    { routeId: string; showToll: boolean; showPlaton: boolean },
    { routeId: string; showToll: boolean; showPlaton: boolean }
  >;

  public check: GenericTask<
    string,
    {
      udt: string;
      percent: number;
      end: number;
      statusLast: string;
      statusErr: string;
    }
  >;
  public subscribeCheck: (routeId: string) => void;

  public leafletSettings: GenericValue<any>;

  public get: GenericTask<string, RouteContent>;

  public save: GenericTask<
    {
      routeId: string;
      name: string;
      desc?: string;
      hideIds: string[];
    },
    {
      shortId: string;
      shortUrl: string;
    }
  >;

  public poiClick: GenericEvent<{
    poiIndex: number;
    routeId: string;
  }>;

  public getRoutePois: (
    routeId: string
  ) => Promise<{
    list: RoutePoiProps[];
    routeStaticIcons: { [key in StaticItemList]: StaticIcon };
  }>;

  public drawRoutePoiTask: GenericTask<
    {
      routeId: string;
      poiIds: string[];
      routePois: {
        list: RoutePoiProps[];
        routeStaticIcons: { [key in StaticItemList]: StaticIcon };
      };
    },
    string
  >;

  private _routes: { [key: string]: RouteContent } = {};
  private _pointsCache: {
    [key: string]: PointsCache;
  } = {};

  public setPointsCache(key: string, data: PointsCache) {
    this._pointsCache[key] = data;
  }
  public getPointsCache(key: string): PointsCache | undefined {
    return this._pointsCache[key];
  }

  public drawRoutePoi = async (routeId: string, poiIds: string[]) => {
    if (!this._pointsCache[routeId]) {
      const pointsCache = await this.loadRoutePoints(
        routeId,
        this._routes[routeId].list
      );
      this.setPointsCache(routeId, pointsCache);
    }
    if (this.drawRoutePoiTask.workersCount) {
      this.drawRoutePoiTask.require({
        routeId,
        poiIds,
        routePois: this._pointsCache[routeId],
      });
    }
  };

  public drawRouteById = async (routeId: string, isViewAll?: boolean) => {
    if (this._routes[routeId]) {
      if (this.removeRoute.workersCount) {
        await this.removeRoute.require(routeId);
      }
      await (this.drawRoute.workersCount &&
        this.drawRoute.require({
          id: routeId,
          poiList: this._routes[routeId].list,
          geometries: this._routes[routeId].info.geometries,
          geometriesCan2: this._routes[routeId].info.geometriesCan2 || [],
          Platon: this._routes[routeId].info.Platon,
          Toll: this._routes[routeId].info.Toll,
          isViewAll,
        }));
      await (isViewAll &&
        this.setPaymentSegmentsState.workersCount &&
        this.setPaymentSegmentsState.require({
          routeId,
          showPlaton: true,
          showToll: true,
        }));
    }
  };

  public setRoute = (routeData: RouteContent) => {
    this._routes[routeData._id] = routeData;
  };

  public loadRoutePoints = async (routeId: string, list: RouteListRecord[]) => {
    const res = await this.getRoutePois(routeId);
    if (res) {
      const errorIcons = list.reduce((acc, poi, index) => {
        if (
          (poi.point_type === "refuel" ||
            poi.point_type === "rest" ||
            poi.point_type === "sleep") &&
          poi.purpose
        ) {
          // генерим иконки с канистрой, передавая потом их для рендера

          acc.push({
            lat: poi.latOnRoad || poi.lat,
            lng: poi.lngOnRoad || poi.lng,
            index,
            ...(res.routeStaticIcons[
              "no" + poi.point_type[0].toUpperCase() + poi.point_type.slice(1)
            ] as StaticIcon),
          });
        }
        return acc;
      }, []);

      const getBrandUrl = (obj: PoiDetails) => {
        if (obj && obj.brand && obj.brand.imgUrl) {
          return obj.brand.imgUrl;
        }
        return (obj && obj.subCategory && obj.subCategory.imgUrl) || "";
      };

      return {
        routeStaticIcons: res.routeStaticIcons,
        errorIcons,
        list: [
          ...res.list.map((poi) => {
            const routeRecordIndex = list.findIndex(
              (p) => p.poi_id === poi._id
            );
            if (routeRecordIndex >= 0) {
              const poiData = list[routeRecordIndex];
              const fuel =
                poiData &&
                poiData.details &&
                poiData.details.fuels &&
                poiData.details.fuels.find((f) => f.selected);
              poi.p = (fuel && fuel.fuelPrice) || poi.p;

              poi.brandUrl = getBrandUrl(list[routeRecordIndex].details);
              poi.index = routeRecordIndex;
            }
            return poi;
          }),
        ],
      };
    }
  };
}
