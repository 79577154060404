import jsonp from "jsonp";
import React from "react";

// import { Share } from "../Share";
import { format, parseISO } from "date-fns";

import { SaveRouteParams } from "multigo-map-api/types/Controllers/Router/routerBuilder";
import { Button } from "multigo-ui";
import "./style.scss";

export class ShortRouteLink extends React.Component<
  {
    routeName: string;
    routeId: string;
    excludedIds: string[];
    isRouteIdExists: boolean;
    routeInfo: any;
    onClose: any;
    saveRoute: (
      params: SaveRouteParams
    ) => Promise<{
      shortId: string;
      shortUrl: string;
      type: number;
    }>;
  },
  { name: string; desc: string; type: string; shortUrl: string; routeInfo: any }
> {
  private mount: boolean;
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.routeName || "",
      desc: "",
      type: "1",
      shortUrl: "",
      routeInfo: {
        ...props.routeInfo,
      },
    };
  }
  public componentDidMount() {
    this.mount = true;
  }

  public componentWillUnmount() {
    this.mount = false;
  }

  public onChange(e) {
    let val = e.target.value;
    let name = e.target.name;
    let newState = {};
    newState[name] = val;
    this.setState(newState);
  }

  public fieldsIsCorrect() {
    return this.state.name.replace(/\s/g, "").length > 0;
  }

  public async getShortUrl(e) {
    e.preventDefault();
    const res = await this.props.saveRoute({
      routeId: this.props.routeId,
      name: this.state.name,
      desc: this.state.desc,
      type: parseInt(this.state.type, 10),
      hideIds: this.props.excludedIds,
    });
    if (this.mount) {
      this.setState({ shortUrl: res.shortUrl, routeInfo: res.routeInfo });
    }
  }

  public gotoSendRoute(e) {
    e.preventDefault();

    // send message to TS
    const { routeInfo } = this.props;
    if (window.parent) {
      window.parent.postMessage(
        {
          source: "locator2",
          payload: {
            do: "go2route",
            routeId: routeInfo._id,
          },
        },
        "*"
      );
    }

    this.props.onClose();
  }

  public renderForm() {
    return (
      <div className="short-route-link">
        <form onSubmit={this.getShortUrl.bind(this)}>
          <div className="short-route-link__item">
            <label htmlFor="name-route">
              Название рейса <span className="important-field">*</span>
            </label>
            <input
              id="name-route"
              type="text"
              name="name"
              placeholder="Введите название рейса"
              onChange={this.onChange.bind(this)}
              value={this.state.name}
            />
          </div>
          <div className="short-route-link__item">
            <label htmlFor="description-route">Описание рейса</label>
            <textarea
              id="description-route"
              name="desc"
              placeholder="Введите описание рейса"
              onChange={this.onChange.bind(this)}
              value={this.state.desc}
            />
          </div>
          <div className="short-route-link__item">
            <label htmlFor="type-route">Тип рейса</label>
            <select
              id="type-route"
              name="type"
              onChange={this.onChange.bind(this)}
              value={this.state.type}
            >
              <option value={1}>коммерческий</option>
              <option value={2}>подача</option>
              <option value={3}>порожний</option>
            </select>
          </div>
          <div className="short-route-link__button-place">
            <Button disabled={!this.fieldsIsCorrect()} primary={true} size="xl">
              Запланировать
            </Button>
          </div>
        </form>
        <p className="short-route-link__info">
          <span className="important-field">*</span> поле обязательное для
          заполнения
        </p>
      </div>
    );
  }

  public renderInfo() {
    const { routeInfo } = this.props;

    const planDt = parseISO(routeInfo.cdt);
    const startDt = parseISO(routeInfo.info.dateBegin);
    const endDt = parseISO(routeInfo.info.dateEnd);

    return (
      <div className="short-route-link">
        <div className="short-route-link__item">
          <b>Запланирован:</b> <span>{format(planDt, "dd.MM.yyyy HH:mm")}</span>
        </div>
        <div className="short-route-link__item">
          <b>Плановый старт:</b>{" "}
          <span>{format(startDt, "dd.MM.yyyy HH:mm")}</span>
        </div>
        <div className="short-route-link__item">
          <b>Плановый финиш:</b>{" "}
          <span>{format(endDt, "dd.MM.yyyy HH:mm")}</span>
        </div>
        <div className="short-route-link__item">
          <b>Номер рейса:</b> <span>{routeInfo.routeId}</span>
        </div>
        <div className="short-route-link__item">
          <b>Рейс для тс:</b> <span>{routeInfo?.vehicle?.name}</span>
        </div>
        <div className="short-route-link__item">
          <b>Тип рейса:</b>&nbsp;
          {routeInfo.routeType === 1 && <span>коммерческий</span>}
          {routeInfo.routeType === 2 && <span>подача</span>}
          {routeInfo.routeType === 3 && <span>порожний</span>}
        </div>
        <div className="short-route-link__item">
          <b>Название рейса:</b> <span>{routeInfo.name}</span>
        </div>
        <div className="short-route-link__item">
          <b>Описание рейса:</b> <span>{routeInfo.desc}</span>
        </div>
        <div className="short-route-link__button-place">
          <Button
            primary={true}
            size="xl"
            onClick={this.gotoSendRoute.bind(this)}
          >
            Ok
          </Button>
        </div>
      </div>
    );
  }

  public render() {
    // const { shortUrl } = this.state;

    if (this.state.routeInfo.routeId) {
      // return <Share title="Ссылка на маршрут" link={shortUrl} />;
      return this.renderInfo();
    } else {
      return this.renderForm();
    }
  }
}
