import { GenericTask } from "./genericTask";

export class GenericValue<T> extends GenericTask<T, T> {
  public get value(): any {
    return this._value;
  }

  public subscribeSelf: (callback?: (params?: T) => void) => void;
  protected _value: T;

  constructor(setTrigger: (trigger: (newValue: T) => void) => void, defaultValue: T, maxWorkerCount?: number) {
    let subscribtionTrigger;
    super(superTrigger => {
      subscribtionTrigger = superTrigger;
    }, maxWorkerCount || 1);
    this._value = defaultValue;
    const trigger = (newValue: T) => {
      this._value = newValue;
      subscribtionTrigger(newValue);
    };

    this.subscribeSelf = (callback: (params?: T) => void) => {
      this.subscribeAsWorker((params: T) => {
        trigger(params);
        if (callback) {
          callback(params);
        }
      });
    };

    setTrigger(trigger);
  }

  public async setValue(newValue: T) {
    this._value = await this.require(newValue);
    return this._value;
  }
  public async setIfEmpty(newValue: T, trigger: (newValue: T) => void) {
    if (!this._value) {
      trigger(newValue);
    }
    return this._value;
  }
}
