import { Checkbox, ChipPicker, SelectField } from "multigo-ui";
import * as React from "react";
import { ChipBlock, IRouteFiltersData } from "../data/routeFilters";

interface FilterChipBlockProps {
  chipBlock: ChipBlock;
  filters: IRouteFiltersData;
}

export const FilterChipBlock = (props: FilterChipBlockProps) => {
  return (
    <div className="filter-chip-block">
      {props.chipBlock.controllable ? (
        <Checkbox
          checked={!props.chipBlock.hide}
          // tslint:disable-next-line: jsx-no-lambda
          onChange={val => {
            props.chipBlock.setHideState(!val);
          }}
        >
          <p style={{ fontWeight: 700, fontSize: "16px", color: "#333333" }}>
            {props.chipBlock.name}
          </p>
        </Checkbox>
      ) : (
        <p style={{ fontWeight: 700, fontSize: "16px", color: "#333333" }}>
          {props.chipBlock.name}
        </p>
      )}
      <div className="filter-chippicker-list">
        {(!props.chipBlock.controllable || !props.chipBlock.hide) &&
          props.chipBlock.showFilters &&
          props.chipBlock.chips.map((chip, chipIndex) =>
            !props.filters.useMultiFuels && chip.fieldName === "__fuelIds" ? (
              <SelectField
                key={chipIndex}
                menuItems={chip.list.map(({ id, name }) => ({
                  id: id.toString(),
                  name
                }))}
                value={chip.selected[0] ? chip.selected[0].toString() : ""}
                // tslint:disable-next-line: jsx-no-lambda
                onChange={value => chip.chipsSetter([Number(value)])}
              />
            ) : (
              <ChipPicker
                chipPickerName={chip.selectorName}
                key={chipIndex}
                list={chip.list}
                selected={chip.selected}
                selectorName={chip.selectorName}
                chipsSetter={chip.chipsSetter}
              />
            )
          )}
      </div>
      <hr />
    </div>
  );
};
