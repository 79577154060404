import React, { useEffect, useState } from "react";

import { RouteListRecord } from "multigo-map-api";
import { SelectField } from "multigo-ui";
import { Share } from "../Share";
import { getUrlGoogle, getUrlNav, getUrlYa } from "../utils";
import "./style.scss";
export const ExternalRoute: React.FC<{ list: RouteListRecord[] }> = props => {
  const [link, setLink] = useState(() => getUrlYa(props.list));
  const [value, setValue] = useState(0);
  const onChange = (newValue: string) => {
    setValue(Number(newValue));
  };

  const getUrl = () => {
    if (value === 0) {
      return getUrlYa(props.list);
    }
    if (value === 1) {
      return getUrlGoogle(props.list);
    }
    if (value === 2) {
      return getUrlNav(props.list);
    }
  };

  useEffect(() => {
    setLink(getUrl());
  }, [value]);

  return (
    <div className="external-route">
      <SelectField
        onChange={onChange}
        value={"" + value}
        menuItems={[
          { id: "0", name: "Яндекс карты" },
          { id: "1", name: "Google карты" },
          { id: "2", name: "Яндекс навигатор" }
        ]}
      />
      <Share link={link} title="Ссылка" />
    </div>
  );
};
