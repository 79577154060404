import * as React from 'react';

const _urlClick = (e) => {
  e.stopPropagation();

  if (e.target.href == '#') {
    e.preventDefault();
  }
};

export const GoButton = ({ dist, url, isMobile }) => {
  if (isMobile) {
    return (
      <a href={url} onClick={_urlClick} target="_blank" className="mgo-go-button">
        {dist}
      </a>
    );
  }
  return <div className="km">{dist}</div>;
};
