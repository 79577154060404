import { listeners } from "cluster";
import { Autocomplete, SearchSelectField } from "multigo-ui";
import React, { useEffect, useRef } from "react";
import { useMemo, useState } from "react";
import { IRouteFiltersData } from "../data/routeFilters";
import { IRouteWithSettings } from "../data/routeWithSettings";
import { format, parse } from "date-fns";

interface VehicleSelectProps {
  route: IRouteWithSettings;
  filters: IRouteFiltersData;
  openWindow?: any;
  vehicleId?: string;
}

export const VehicleSelectComponent = (props: VehicleSelectProps) => {
  const [savedVehicleId, setSavedVehicleId] = useState("");

  const dateStart = parse(
    props.route?.settings?.dateStart,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  );

  const setProtoVehicle = (value: string) => {
    props.route.api.resetFilters(false);
    props.filters.vehicleType = value;
    props.filters.fuelTypes = props.route.availableVehicles.find(
      (v) => v.id === value
    ).fuelTypes;
    props.filters.availableFilters = props.route.api.getFiltersIdsByVehicleId(
      value
    );
    props.filters.defaultFuels =
      props.route.api.locatorSettings.show &&
      props.route.api.locatorSettings.show.defaultFuelIds;
    // const vehicle = props.route.vech.find(v => v.id === value);
    props.filters.applyFilters();

    props.route.selectedProtoVehicleId = value;
    setSavedVehicleId("");
  };

  const setSavedVehicle = (value: string) => {
    const vehicle = props.route.savedVehicles.find((v) => v.id === value);
    if (!vehicle) return;

    const protoVehicle = props.route.availableVehicles.find(
      (v) => v.id === vehicle.type
    );
    props.route.api.resetFilters(false);
    props.filters.vehicleType = protoVehicle.id;
    props.filters.fuelTypes = protoVehicle.fuelTypes;

    props.filters.restoreFitlers(
      vehicle.filters,
      vehicle.filtersMapper,
      protoVehicle.filterTypes
    );
    props.route.selectedVehicleId = value;
    setTimeout(() => {
      props.route.defaultSettings = {
        ...vehicle.routeSettings,
        dateBegin: new Date(),
      };
    }, 0);

    setSavedVehicleId(`_${value}`);
  };

  // Select vehicle if vehicleId exists
  useEffect(() => {
    if (props.route.api && props.vehicleId) {
      setSavedVehicle(props.vehicleId);
    }
  }, [props.route.api, props.vehicleId]);

  const selectVehicleIdHandler = (value: string) => {
    // console.log("selectVehicleIdHandler", value);

    if (value[0] === "#") {
      setProtoVehicle(value.slice(1));
    }
    if (value[0] === "_") {
      setSavedVehicle(value.slice(1));
    }
  };

  const menuItems = useMemo(() => {
    const protoVehicles = (props.route.availableVehicles || []).map((v) => ({
      text: v.name,
      id: `#${v.id}`,
    }));
    const savedVehicles = (props.route.savedVehicles || []).map((v) => ({
      text: v.name + (v.wialonName ? ` -> [wialon] ${v.wialonName}` : ""),
      id: `_${v.id}`,
    }));

    if (savedVehicles.length) {
      return savedVehicles;
    } else {
      return protoVehicles;
    }
  }, [props.route.availableVehicles.length, props.route.savedVehicles.length]);

  const activeVehicle = useMemo(() => {
    return props.route.savedVehicles.find((x) => x.id === props.vehicleId);
  }, [
    props.route.availableVehicles.length,
    props.route.savedVehicles.length,
    props.vehicleId,
  ]);

  const activeIndex =
    (savedVehicleId && menuItems.findIndex((i) => i.id === savedVehicleId)) ||
    0;

  return (
    <div className="route-settings-header">
      {props.openWindow && (
        <button className="edit-vehicle-button">Редактировать</button>
      )}
      <div
        className="route-settings-row vehicle-select"
        style={{ marginBottom: 0 }}
      >
        <p className="route-settings-name">Выбор транспортного средства</p>
        {!!activeVehicle && (
          <div className="sets-value">{activeVehicle.name}</div>
        )}
        {/* <Autocomplete
          defaultText={menuItems[activeIndex] && menuItems[activeIndex].text}
          suggests={menuItems}
          onAutocomplete={selectVehicleIdHandler}
        /> */}
      </div>
      <div className="route-settings-row vehicle-select">
        <p className="route-settings-name">Время старта</p>
        {!!activeVehicle && (
          <div className="sets-value">
            {format(dateStart, "dd.MM.yyyy HH:mm")}
          </div>
        )}
      </div>
    </div>
  );
};
