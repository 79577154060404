import { cn } from "@bem-react/classname";
import { WithTooltip } from "multigo-ui";
import React, { useContext } from "react";
import { PoiContext } from "./poiContext";
import "./style/fuelCards.scss";
const cN = cn("routecard-fuelcards");
import {
  FullPoiFuelCard,
  TarifPolProps,
} from "multigo-map-api/types/Controllers/Api/map/types";
import { FuelCard } from "multigo-map-api/types/Controllers/Api/types";

export const GenericCards: React.FC<{
  cards: FullPoiFuelCard[];
  tarifPolProps: TarifPolProps;
}> = (props) => {
  const poiContext = useContext(PoiContext);
  const { visibleParts } = poiContext;

  const activeFuelCard = poiContext.fuelCard;
  const { fuelName } = poiContext;
  const activeFuel = (poiContext.poiContent.fuels || []).find(
    (fuel) => fuel.fuelId === fuelName
  );
  const getPriceTrand = (fuelCard: FullPoiFuelCard) => {
    const tarifPrice = (
      (fuelCard.tarifByFuel || []).find(
        (tarif) => tarif.fuelId === fuelName
      ) || {
        tarifPrice: undefined,
      }
    ).tarifPrice;

    if (tarifPrice && props.tarifPolProps.enable) {
      if (activeFuel.fuelPrice < tarifPrice) {
        return "negative";
      }
      if (activeFuel.fuelPrice > tarifPrice) {
        return "positive";
      }
      return "neutral";
    }
    return "unknown";
  };

  if (!activeFuel && props.tarifPolProps.hideFuelCards) {
    return null;
  }

  return (
    <div className={cN("wrapper")}>
      {props.cards.map((fuelCard) => {
        const trand = getPriceTrand(fuelCard);
        const tarifPol =
          fuelCard.tarifByFuel &&
          fuelCard.tarifByFuel.find((tarif) => tarif.fuelId === fuelName);

        return (
          <div className={cN("card")} key={fuelCard.name}>
            <img src={fuelCard.imgUrl} className={cN("img")} />
            {visibleParts.hideTP !== 1 && props.tarifPolProps.enable && (
              <TarifPol
                card={fuelCard}
                fuelName={fuelName}
                trand={trand}
                tarifPolEnabled={Boolean(props.tarifPolProps.enable)}
              />
            )}
            <p>{fuelCard.name}</p>
            {visibleParts.hideTP !== 1 &&
              fuelCard.tarifByFuel &&
              trand !== "neutral" &&
              tarifPol && (
                <div className={cN("tarif", { trand })}>
                  {`${trand === "positive" ? "Скидка" : "Наценка"} ${
                    tarifPol.tarif
                  }`}
                </div>
              )}
            {visibleParts.hideTP !== 1 &&
              activeFuelCard &&
              fuelCard.id === activeFuelCard &&
              props.cards.length > 1 && (
                <div className={cN("recommend")}>Рекомендуем!</div>
              )}
          </div>
        );
      })}
    </div>
  );
};

const TarifPol: React.FC<{
  card: FullPoiFuelCard;
  fuelName: string;
  trand?: string;
  tarifPolEnabled: boolean;
}> = (props) => {
  const tarifPol = (props.card.tarifByFuel || []).find(
    (tarif) => tarif.fuelId === props.fuelName
  );

  const tarifZero = Math.abs(parseFloat(tarifPol.tarif)) < 0.01;
  return (
    <>
      {props.tarifPolEnabled ? (
        <div className={`percent-decorator big ${props.trand || ""}`}>
          <WithTooltip
            position="bottom"
            text={
              props.trand === "unknown"
                ? "для топливной карты не определена тарифная политика"
                : `тарифная политика ${tarifZero ? "цена стеллы" : tarifPol.tarif}`
            }
          >
            {props.trand === "unknown" ? "С" : "%"}
          </WithTooltip>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
