import * as React from "react";
import { useContext } from "react";

import { FuelPriceBlock } from "./FuelPriceBlock";
import { RouteListContext } from "../../../../utils/context";
import { ToRenderItem } from "../common";

const urls = {
  minimap: "https://cdn-img.multigo.ru/1/img/poi/minimap/",
  goYaMap: "https://multigo.ru/api/5/nav/to/poi/",
  brands: "https://cdn-img.multigo.ru/1/img/brand/",
  catIcons: "https://cdn-img.multigo.ru/1/img/category-icon/",
  services: "https://cdn-img.multigo.ru/1/img/service/",
  fcard: "https://cdn-img.multigo.ru/1/img/fuel_card/",
  flcard: "https://cdn-img.multigo.ru/1/img/loyalty_card/",
  campaign: "https://cdn-img.multigo.ru/1/img/campaign/",
  photoAzs: "https://cdn-img.multigo.ru/1/img/poi/",
  roadIcons: "https://cdn-img.multigo.ru/1/img/road-item-type/",
};

const _costFuel = (fuelVolume, price) => {
  if (fuelVolume && price) {
    return Math.floor(fuelVolume * price);
  }

  return 0;
};

export const TankBlock = (props: { item: ToRenderItem }) => {
  const {
    fuel_id,
    litersMax,
    color_type,
    fuelVolume,
    fuelVolumeInTank,
    price,
  } = props.item;
  const context = useContext(RouteListContext);
  const { visible } = context;

  return (
    <div className="mgo-tank-block">
      <div className="block-1">
        {litersMax && fuelVolume && fuelVolumeInTank !== undefined && (
          <div className="row">
            <span className="label">Остаток:</span>
            <span className="value">{`${
              fuelVolumeInTank - fuelVolume
            } л`}</span>
          </div>
        )}
        <div className="row">
          <FuelPriceBlock
            item={props.item}
            // fcolor={color_type > 0 ? color_type + 3 : color_type + 4}
            // fname={context.getFuelName(fuel_id)}
          />
        </div>
      </div>
      {fuelVolume != undefined && price != undefined && (
        <div className="block-2">
          <div className="row">
            <span className="label">Залить:</span>
            <span className="value">{`${fuelVolume} л`}</span>
          </div>
          {visible.hideCSumm !== 1 && (
            <div className="row">
              <span className="label">Сумма:</span>
              <span className="value ruble">{`${_costFuel(
                fuelVolume,
                price
              )}`}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
