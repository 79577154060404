import React from "react";

import "./style.scss";

const FloatBox: React.FC<{ close: () => void; title: string }> = props => {
  return (
    <div className="float-box" onClick={props.close}>
      <div
        className="float-box__inner"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="float-box__head">
          <span className="float-box__title">{props.title}</span>
          <button className="float-box__close" onClick={props.close} />
        </div>
        <div className="float-box__body">{props.children}</div>
      </div>
    </div>
  );
};

export default FloatBox;
