/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { useContext, useEffect, useMemo } from "react";

import { fuelColorFactory } from "../../../../../utils/helpers/fuelColorFactory";
import { defaultContextProps, RouteListContext } from "../utils/context";

import { RouteListItem } from "./RouteListItem";

import { RouteContent, RouteListRecord } from "multigo-map-api";
import { LocatorSettings } from "multigo-map-api/types/Controllers/Api/map/types";
import { useForceUpdate } from "../../../../../utils/hooks/useForceUpdate";

// interface RoutePanelProps {
//   isFooter: boolean;
//   curIndex: number;
//   list: RouteListRecord[];
// }
export interface RouteListProps {
  // list: RouteListRecord[];
  buildContent: RouteContent;
  fuel_id: number;
  onClickMoreHandler: (e: React.MouseEvent, idx: number) => void;
  getScrollContainerRef?: () => React.MutableRefObject<any>;
  activePOI: number;
  setActivePOI: (newPOI: number) => void;
  isViewAll: boolean;
  locatorSettings: LocatorSettings;
  colorMask: boolean[];
  excludedIds: string[];
  drawPoisOnMap: (poiIds: string[]) => void;
}

export const RouteList = (props: RouteListProps) => {
  const clickHandler = (newIndex: number) => {
    props.setActivePOI(newIndex);
  };

  const contextObj = {
    ...defaultContextProps,
    excludedIds: props.excludedIds,
    clickHandler,
    fuel_id: props.fuel_id,
    curIndex: props.activePOI,
    isViewAll: props.isViewAll,
    getScrollContainerRef: props.getScrollContainerRef,
    afterOnclickMore: props.onClickMoreHandler,
    tarifPolEnabled: Boolean(props.locatorSettings.tp.enable),
    getFuelColor: fuelColorFactory(
      props.buildContent.info.refuesList,
      props.locatorSettings.fuelPriceColors
    ),
    info: props.buildContent.info,
    visible: props.buildContent.visible || {},
    getLitersMax: () =>
      (props.buildContent.info.options.litersMax as number) || 200,
    getPoiById: (poiId: string) => {
      const idx = props.buildContent.list.findIndex((p) => p.poi_id === poiId);
      return poiId && idx
        ? [idx, props.buildContent.list[idx]]
        : [undefined, undefined];
    },
  };

  const [list, poiIds] = useMemo(() => {
    return props.buildContent.list.reduce(
      (acc, poi, index) => {
        if (
          !(
            poi.poi_id &&
            (contextObj.excludedIds.includes(poi.poi_id) || poi._hidden)
          ) &&
          (props.isViewAll || poi.inShortList)
        ) {
          poi.idx = index;
          acc[0].push(poi);
          if (poi.poi_id) {
            acc[1].push(poi.poi_id);
          }
        }
        return acc;
      },
      [[] as RouteListRecord[], [] as string[]] // фильтруем и одновременно создаем список poi, которые нао нарисовать на карте
    );
  }, [contextObj.excludedIds, props.isViewAll]);
  useEffect(() => {
    props.drawPoisOnMap(poiIds);
  }, [poiIds]); // рисуем иконки для POI на карте (они зависят от фильтров)

  return (
    <RouteListContext.Provider value={contextObj}>
      <RoutePanel list={list} visible={props.buildContent.visible} />
    </RouteListContext.Provider>
  );
};

export const RoutePanel = (props: {
  list: RouteListRecord[];
  visible: any;
}) => {
  const context = useContext(RouteListContext);
  const { isViewAll } = context;

  // Выводим список точек
  const linesState = {
    toll: {
      idx: 0,
      active: 0,
    },
    platon: {
      idx: 0,
      active: 0,
    },
  };
  let numPunct = 0;
  const numPunctChecker = (increase: boolean) => {
    if (increase) {
      numPunct++;
    }
    return numPunct;
  };
  return (
    <div
      // style={{ height: "700px", overflowY: "scroll" }}
      className={`route-list${context.isViewAll ? " recom" : ""}`}
    >
      <div className="line" />
      <div>
        {props.list.map((routeItem, idx) => {
          let shouldExists = true;
          if ([32, 41].includes(routeItem.type_id)) {
            if (props.visible.hideRTOs === 1) {
              if (routeItem.purpose !== "refuel") {
                shouldExists = false;
              }
            }
            if (props.visible.hideSecObjs === 1) {
              if (routeItem.poi_type !== 0) {
                shouldExists = false;
              }
            }
          }
          if (!shouldExists) return null;

          return (
            <RouteListItem
              key={idx}
              {...{
                numPunctChecker,
                item: routeItem,
                isViewAll: context.isViewAll,
                linesState,
                getScrollContainerRef: context.getScrollContainerRef,
                idx,
                curIndex: context.curIndex,
                clickHandler: context.clickHandler,
                afterOnclickMore: context.afterOnclickMore,
                isFooter: context.isFooter,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
