import { IAPIController } from "../Api";
import { GenericEvent, GenericTask, GenericValue } from "../generics";

import { DefaultResponse, RouteContent, RouteListRecord } from "../Api/types";
import { DrawRoute, IRouterController, RouterController } from "./router";

const TOLL_TYPES_LIST = [
  "PVP",
  "in_platon",
  "out_platon",
  "in_toll",
  "out_toll",
  "river"
];
export interface SaveRouteParams {
  routeId: string;
  name: string;
  desc?: string;
  type?: number;
  hideIds?: string[];
}

export class RouterBuilder {
  private routerController: IRouterController;

  private triggers: { [key: string]: (data: any) => void } = {};

  private api: IAPIController;
  private checkInterval: NodeJS.Timeout;

  constructor(setTriggers: (triggers: any) => void, api: IAPIController) {
    this.api = api;
    this.routerController = new RouterController();
    this.routerController.get = this.getGet();
    this.routerController.run = this.getRun();
    this.routerController.vrun = this.getVRun();
    this.routerController.check = this.getCheck();
    this.routerController.save = this.getSave();

    this.routerController.subscribeCheck = (routeId: string) =>
      this.checkRoute(routeId, "run");

    this.routerController.drawRoute = this.getDrawRoute();
    this.routerController.drawRoutePoiTask = this.getDrawRoutePoi();
    this.routerController.removeRoute = this.getRemoveRoute();
    this.routerController.leafletSettings = this.getLeafletSettings();
    this.routerController.poiClick = this.getPoiClick();
    this.routerController.setPaymentSegmentsState = this.getSetPaymentSegmentsState();
    this.routerController.getRoutePois = this.getPoiGetter();
    this.routerController.wialonExportTask = this.getWialonExportTask();
    this.subscribeRun();
    this.subscribeVRun();
    setTriggers(this.triggers);
  }

  public build = () => this.routerController;

  private getLeafletSettings = () => {
    const value = new GenericValue<any>(
      trigger => this.triggers.leafletSettings,
      {},
      1
    );
    value.subscribeSelf();
    return value;
  };

  private getPoiGetter = () => async (routeId: string) => {
    const res = await this.api.router.getPoiIcons(routeId);
    if (!res.err) {
      return res.data;
    }
  };

  private getPoiClick = () =>
    new GenericEvent<{ poiIndex: number; routeId: string }>(
      trigger => (this.triggers.poiClick = trigger)
    );

  private getDrawRoute = () =>
    new GenericTask<DrawRoute, string>(
      trigger => (this.triggers.drawRoute = trigger),
      1
    );

  private getRemoveRoute = () =>
    new GenericTask<string, boolean>(
      trigger => (this.triggers.removeRoute = trigger),
      1
    );

  private getRun = () =>
    new GenericTask<{ ll: string; options: string }, string>(
      trigger => (this.triggers.run = trigger),
      1
    );
  private getVRun = () =>
    new GenericTask<{ ll: string; vehicleId: string }, string>(
      trigger => (this.triggers.vrun = trigger),
      1
    );

  private getSetPaymentSegmentsState = () =>
    new GenericTask<
      { routeId: string; showToll: boolean; showPlaton: boolean },
      { routeId: string; showToll: boolean; showPlaton: boolean }
    >(trigger => (this.triggers.setPaymentSegmentsState = trigger), 1);

  private getWialonExportTask = () =>
    new GenericTask<
      { routeId: string; vehicleId: number; name?: string; desc?: string },
      { err?: string }
    >(trigger => (this.triggers.wialonExportTask = trigger), 1);

  private getCheck = () => {
    const task = new GenericTask<
      string,
      {
        udt: string;
        percent: number;
        end: number;
        statusLast: string;
        statusErr: string;
      }
    >(trigger => (this.triggers.check = trigger), 1);
    task.subscribeAsWorker(routeId => {
      this.checkRoute(routeId, "run");
    });
    return task;
  };

  private getSave = () => {
    const task = new GenericTask<
      SaveRouteParams,
      {
        shortId: string;
        shortUrl: string;
      }
    >(trigger => (this.triggers.save = trigger), 1);
    task.subscribeAsWorker(async params => {
      const res = await this.api.router.save({
        routeId: params.routeId,
        desc: params.desc,
        name: params.name,
        type: params.type,
        hideIds: params.hideIds ? params.hideIds.join(",") : ""
      });
      if (!res.err) {
        this.triggers.save(res.data);
      }
      return res.data;
    });
    return task;
  };

  private getGet = () => {
    const getTask = new GenericTask<string, RouteContent>(
      trigger => (this.triggers.get = trigger),
      1
    );
    getTask.subscribeAsWorker(async (routeId: string) => {
      try {
        const getRes = await this.api.router.get(routeId);
        if (!getRes.err) {
          // const pointsCache = await this.routerController.loadRoutePoints(
          //   routeId,
          //   getRes.data.list
          // );
          // this.routerController.setPointsCache(routeId, pointsCache);

          getRes.data.list
            .filter(poi =>
              ["start_point", "via_point", "stop_point"].includes(
                poi.point_type.toString()
              )
            )
            .forEach((poi, index) => {
              poi.text = `${index + 1}`;
            });
          const normalizedData = {
            ...getRes.data,
            list: getRes.data.list.map(this.addInShortListField)
          };
          this.removeOtherRoutes(routeId);
          this.routerController.setRoute(normalizedData);
          // this.routerController.setRoute(normalizedData);
          // this.routerController.drawRouteById(routeId);
          this.triggers.get(normalizedData);
        }
      } catch (e) {
        this.triggers.check({
          statusErr: e.toString(),
          err: Number.parseInt((e.toString().match(/\d+/) || [-1])[0], 10),
          percent: 100
        });
      }
    });
    return getTask;
  };
  private removeCheckInterval = () => {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = undefined;
    }
  };
  private subscribeGenericRun = (key: "run" | "vrun") => {
    this.routerController[key].subscribeAsWorker(async params => {
      if (!params) {
        this.removeCheckInterval();
        return;
      }
      const res = await this.api.router[key](params);
      if (!res.err) {
        const { routeId } = res.data;
        this.checkRoute(routeId, key);
      }
    });
  };
  private subscribeRun = () => this.subscribeGenericRun("run");

  private subscribeVRun = () => this.subscribeGenericRun("vrun");

  private checkRoute = (routeId: string, key: "run" | "vrun") => {
    this.checkInterval = setInterval(async () => {
      try {
        const checkRes = await this.api.router.check(routeId);
        const { data } = checkRes;
        this.triggers.check(data);
        if (data.end && !data.err) {
          clearInterval(this.checkInterval);
          this.checkInterval = undefined;
          this.loadRoadData(routeId, key);
        }
        if (data.err) {
          clearInterval(this.checkInterval);
        }
      } catch (err) {
        const errArr = err.toString().split(":");
        this.triggers.check({
          statusErr: errArr[2] ? errArr[2].slice(1) : "",
          err: errArr[1] ? parseInt(errArr[1].slice(1), 10) || 1 : 1,
          percent: 100
        });
        clearInterval(this.checkInterval);
        this.checkInterval = undefined;
      }
    }, 1000);
  };
  private handleCheckError = (err: {
    statusErr: string;
    err: number;
    percent: number;
  }) => {};

  private loadRoadData = async (routeId, key) => {
    // subsrcribe to get
    await this.routerController.get.require(routeId);
    this.triggers[key](routeId);
  };

  private addInShortListField = (poi: RouteListRecord) => {
    // const poiData = poi.data;

    poi.inShortList = !(
      (poi.type_id === 32 && !poi.recom) ||
      TOLL_TYPES_LIST.includes(poi.point_type as string)
    );
    return poi;
  };

  private removeOtherRoutes = (routeId: string) => {
    Object.keys(this.routerController.routes).forEach(
      routeKey =>
        routeKey !== routeId &&
        this.routerController.removeRoute.workersCount &&
        this.routerController.removeRoute.require(routeKey)
    );
  };
  private getDrawRoutePoi = () =>
    new GenericTask<{ routeId: string; poiIds: string[] }, string>(
      trigger => (this.triggers.drawRoutePoi = trigger),
      1
    );
}
