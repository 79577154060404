import { RouteContent } from "multigo-map-api";
import { LocatorSettings } from "multigo-map-api/types/Controllers/Api/map/types";
import { nanoid } from "nanoid";
import React from "react";
import { FuelType } from "src/components/RoutePanel/data/routeFilters";
import { IRouteWithSettings } from "../../../data/routeWithSettings";
import { countTimeStop, distanceKm, parseSeconds } from "../utils/converters";
import { PriceChart } from "./Chart";
import "./route-params.scss";
export const RouteParams: React.FC<{
  buildContent: RouteContent;
  fuelTypes: FuelType[];
}> = (props) => {
  const { info } = props.buildContent;
  // const getFuelType = (id: number) => {
  //   console.log("getFuelType", id);
  //   console.log("props.fuelTypes", props.fuelTypes);
  //   return (
  //     props.fuelTypes.find((type) => type.ids.includes(id)) || { name: "" }
  //   ).name;
  // };
  const { refuesList } = props.buildContent.info;

  const byFuels = (
    name: string,
    propName: string,
    withLiters = false,
    fixedNumb = 2
  ) => {
    const sum = refuesList.reduce((acc, v) => acc + (v[propName] || 0), 0);
    return [
      { text: name, value: sum.toFixed(fixedNumb) + (withLiters ? " л" : "") },
      ...(refuesList.length >= 2
        ? refuesList.map((refuel) => ({
            text: refuel.fuelName,
            value: (refuel[propName] || "") + (withLiters ? " л" : ""),
          }))
        : []),
    ].map((block, index) => (
      <div key={`${block.text}-${nanoid()}`} className="route-info__item">
        <div className="col_1" style={index ? { alignItems: "flex-end" } : {}}>
          {block.text}
        </div>
        <div className="col_2">{block.value}</div>
      </div>
    ));
  };

  return (
    <div className="wrapper">
      <div className="route-info__group route-info__plan">
        {isFinite(info.realTotalDist) && (
          <div className="route-info__item">
            <div className="col_1">Пробег всего:</div>
            <div className="col_2">{distanceKm(info.realTotalDist, true)}</div>
          </div>
        )}
        {isFinite(info.totalTime) && (
          <div className="route-info__item">
            <div className="col_1">Время в пути:</div>
            <div className="col_2">{parseSeconds(info.totalTime)}</div>
          </div>
        )}
        {isFinite(info.drivingTime) && (
          <div className="route-info__item">
            <div className="col_1">Время за рулём:</div>
            <div className="col_2">{parseSeconds(info.drivingTime, true)}</div>
          </div>
        )}
        {isFinite(info.avgPriceByRoute) && (
          <div className="route-info__item">
            <div className="col_1">Средняя цена топлива:</div>
            <div className="col_2">
              <span className="ruble">{info.avgPriceByRoute}</span>
            </div>
          </div>
        )}
        {props.buildContent.recommendCards && (
          <div className="recommended-cards">
            <div className="title"> Использовать на маршруте: </div>
            <div className="content">
              {props.buildContent.recommendCards.map((card) => (
                <div key={card.id} className="card">
                  <img className="card-img" src={card.imgUrl} />
                  <div className="card-name">{card.name}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {info && info.spread && (
        <PriceChart
          list={info.spread}
          totalDistance={info.realTotalDist}
          refuelsList={info.refuesList}
          fuelTypes={props.fuelTypes}
          fuelPrice={info.fuelPrice}
          routePercenGas={info.routePercenGas}
        />
      )}
      <div className="route-info__group route-info__plan">
        {/* {isFinite(info.numRefuel) && (
          <div className="route-info__item">
            <div className="col_1">Плановых АЗС:</div>
            <div className="col_2">{info.numRefuel}</div>
          </div>
        )} */}
        {/* <div className="route-info__item">
          <div className="col_1">Плановых АЗС:</div>
          <div className="col_2">{info.numRefuel}</div>
        </div> */}

        {byFuels("Плановых АЗС:", "numRefuel", false, 0)}

        <div className="route-info__item">
          <div className="col_1">Всего показано АЗС:</div>
          <div className="col_2">
            {props.buildContent.list.filter((poi) => poi.type_id === 32).length}
          </div>
        </div>
        {/* {isFinite(info.totalFuel) && (
          <div className="route-info__item">
            <div className="col_1">Залито топлива:</div>
            <div className="col_2">{info.totalFuel} л</div>
          </div>
        )} */}

        {byFuels("Залито топлива:", "totalFuel", true)}
        {byFuels("Остаток на финише:", "fuelEndPath", true)}

        {/* {isFinite(info.fuelEndPath) && (
          <div className="route-info__item">
            <div className="col_1">Остаток на финише:</div>
            <div className="col_2">{info.fuelEndPath} л</div>
          </div>
        )} */}
        {/* {isFinite(info.fuelPrice) && (
          <div className="route-info__item">
            <div className="col_1">Затраты на топливо:</div>
            <div className="col_2">
              <span className="ruble">{Math.floor(info.fuelPrice)}</span>
            </div>
          </div>
        )} */}
        {byFuels("Затраты на топливо:", "fuelPrice")}
        <div className="route-info__item">
          <div className="col_1">Экономия на топливе:</div>
          <div className="col_2" />
        </div>
      </div>
      <div className="route-info__group route-info__plan">
        {isFinite(info.numRest) && (
          <div className="route-info__item">
            <div className="col_1">Остановок на отдых:</div>
            <div className="col_2">{info.numRest}</div>
          </div>
        )}
        <div className="route-info__item">
          <div className="col_1">Время на отдых:</div>
          <div className="col_2">
            {parseSeconds(countTimeStop(props.buildContent.list, "rest"))}
          </div>
        </div>
        {isFinite(info.numSleep) && (
          <div className="route-info__item">
            <div className="col_1">Остановок на сон:</div>
            <div className="col_2">{info.numSleep}</div>
          </div>
        )}
        <div className="route-info__item">
          <div className="col_1">Время на сон:</div>
          <div className="col_2">
            {parseSeconds(
              countTimeStop(props.buildContent.list, "sleep"),
              true
            )}
          </div>
        </div>
      </div>
      <div className="route-info__group route-info__plan">
        {info.Platon && (
          <>
            <div className="route-info__item">
              <div className="col_1">&laquo;Платон&raquo; пробег:</div>
              <div className="col_2">{info.Platon.kmPlaton + " км"} </div>
            </div>

            <div className="route-info__item">
              <div className="col_1">&laquo;Платон&raquo; оплата:</div>
              <div className="col_2">
                <span className="ruble">
                  {Math.floor(info.Platon.pricePlaton).toLocaleString("ru-Ru")}
                </span>
              </div>
            </div>
          </>
        )}
        {info.Toll && (
          <div className="route-info__item">
            <div className="col_1">Платные дороги:</div>
            <div className="col_2">
              <span className="ruble">
                {Math.floor(info.Toll.priceToll).toLocaleString("ru-Ru")}
              </span>
            </div>
          </div>
        )}
      </div>
      {isFinite(info.totalPrice) && (
        <div className="route-info__group route-info__plan">
          <div className="route-info__item">
            <div className="col_1">Всего затрат:</div>
            <div className="col_2">
              <span className="total-price ruble">
                {Math.floor(info.totalPrice).toLocaleString("ru-Ru")}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
