export class GenericEvent<T> {
  protected _subscribers: Array<(data: T) => void> = [];

  constructor(setTrigger: (trigger: (event: T) => void) => void) {
    const trigger = (event: T) => {
      this._subscribers.forEach((subscriber) => {
        subscriber(event);
      });
    };
    setTrigger(trigger);
  }

  public subscribe(handler: (data: T) => void): { remove: () => void } {
    if (typeof handler !== 'function') {
      throw new Error('provided handler is not a function');
    }
    this._subscribers.push(handler);
    return { remove: this.createSubsriberRemover(handler) };
  }

  protected createSubsriberRemover = (handler) => {
    return () => {
      this._subscribers = this._subscribers.filter(s => s !== handler);
    };
  };
}
