/* eslint-disable react/no-this-in-sfc */
import * as React from "react";
import { MapComponent } from "./Components/Map";
import { ContextMenuData, InitData } from "./Controllers/Api/map/types";
import { Controller, IController } from "./Controllers/index";

export {
  GenericEvent,
  GenericTask,
  GenericValue
} from "./Controllers/generics";
interface SimpleController {
  setZoom: (zoom: number) => Promise<number>;
  setCenter: (center: [number, number]) => Promise<[number, number]>;
  setFilter: (newFilter: object) => Promise<boolean>;
  setLocatorFilter: (newFilter: {
    filter: object;
    vt: string;
  }) => Promise<boolean>;
  setFuel: (newFuel: number) => Promise<number>;
  initMap: (params: { apiKey: string }) => Promise<Partial<InitData>>;
  setUserMenu: (params: ContextMenuData) => Promise<ContextMenuData>;
  setMarkerMenu: (params: ContextMenuData) => Promise<ContextMenuData>;
  setMapMenu: (params: ContextMenuData) => Promise<ContextMenuData>;
  setUserCoordinates: (params: [number, number]) => Promise<[number, number]>;

  subscribeZoomChange: (subscriber: (data: any) => void) => void;
  subscribeCenterChange: (
    subscriber: (clickData: any) => void
  ) => { remove: () => void };
  subscriberUserDragEnd: (
    subscriber: (clickData: any) => void
  ) => { remove: () => void };
  subscribeMarkerClick: (
    subscriber: (clickData: any) => void
  ) => { remove: () => void };
  subscribeContextMenuClick: (
    subscriber: (clickData: any) => void
  ) => { remove: () => void };
}
export interface IMapAPI {
  MultigoMap: (props?: { apiKey: string }) => React.Component;
  controller: IController;
  simpleController: SimpleController;
  versions: any;
}

export class MapAPI implements IMapAPI {
  public get versions(): {
    version: string;
    description: string;
    dependencies: any;
    devDependencies: any;
  } {
    const packageInfo = require("../package.json");
    return {
      version: packageInfo.version,
      description: packageInfo.description,
      dependencies: packageInfo.dependencies,
      devDependencies: packageInfo.devDependencies
    };
  }

  public MultigoMap;

  public controller: Controller;

  public simpleController: SimpleController;

  private mapTriggers;

  constructor(props: { debug: boolean }) {
    let mapTriggers;
    this.controller = new Controller(props.debug, triggers => {
      mapTriggers = triggers;
      if (props.debug) {
        this.mapTriggers = triggers;
      }
    });

    this.simpleController = {
      setZoom: params => this.controller.map.zoom.require(params),
      setCenter: params => this.controller.map.center.require(params),
      setFilter: params => this.controller.map.setFilter.require(params),
      setLocatorFilter: params =>
        this.controller.map.setLocatorFilter.require(params),
      setFuel: params => this.controller.map.fuelsSwitchButton.require(params),
      setUserCoordinates: params =>
        this.controller.map.userCoordinates.require(params),

      initMap: params => this.controller.map.init.require(params),
      setUserMenu: params =>
        this.controller.map.contextMenu.data.userIconMenu.require(params),
      setMarkerMenu: params =>
        this.controller.map.contextMenu.data.markerMenu.require(params),
      setMapMenu: params =>
        this.controller.map.contextMenu.data.mapMenu.require(params),

      subscribeZoomChange: subscriber =>
        this.controller.map.zoom.subscribe(subscriber),
      subscribeCenterChange: subscriber =>
        this.controller.map.center.subscribe(subscriber),
      subscriberUserDragEnd: subscriber =>
        this.controller.map.userDragEnd.subscribe(subscriber),
      subscribeMarkerClick: subscriber =>
        this.controller.map.activeMarker.subscribe(subscriber),
      subscribeContextMenuClick: subscriber =>
        this.controller.map.contextMenu.click.subscribe(subscriber)
    };

    this.MultigoMap = (mapProps?: { apiKey: string }) => {
      return (
        <MapComponent
          debug={props.debug}
          apiKey={mapProps ? mapProps.apiKey : ""}
          mapTriggers={mapTriggers}
          controller={this.controller}
        />
      );
    };
  }
}

window["multigo-map-api"] = MapAPI;
