import { Fetcher, IFetcher } from "../fetcher";
import { DefaultResponse } from "../types";

export interface IGeocoder {
  forward: (
    q: string,
    params?: { latlng?: { lat: number; lng: number }; limit?: number }
  ) => Promise<
    DefaultResponse<{
      type: string;
      results: Array<{ ll: [number, number]; address: string }>;
    }>
  >;
  reverse: (latlng: {
    lat: number;
    lng: number;
  }) => Promise<DefaultResponse<{ address: string }>>;
  setKey: (key: string) => void;
}

export function Geocoder() {
  const fetcher: IFetcher = new Fetcher(false, "https://api7.multigo.ru/3/");
  let _key = "";
  this.setKey = (newKey: string) => {
    if (newKey) {
      _key = newKey;
    }
  };
  this.forward = (
    q: string,
    params?: { latlng?: { lat: number; lng: number }; limit?: number }
  ) => fetcher.get("service/geocoder", { key: _key, q, ...(params || {}) });
  this.reverse = (latlng: { lat: number; lng: number }) =>
    fetcher.get("service/rgeocoder", { key: _key, ...latlng });
}
