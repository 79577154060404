import { PUNCT_NAMES, LANDMARK, TOLL_NAMES } from "./consts";

export const getDistance = (item: { dist: number }) => {
  return `${Math.floor(item.dist / 1000).toString()} км`;
};

export const getItemClassName = (
  item,
  isViewAll,
  isActive,
  isFooter,
  isMobile
) => {
  let className = "route-list-item";
  // CSS классы оформления
  if (isMobile) {
    className += " mobile";
  }
  if (
    PUNCT_NAMES[item.type_id] ||
    TOLL_NAMES[item.type_id] ||
    item.admin_level === undefined
  ) {
    className += " shift";
  }
  if (item.admin_level) {
    className += " city";
  }
  if (PUNCT_NAMES[item.type_id]) {
    className += " punct";
  }

  // Ориентир (река или что-то ещё)
  if (LANDMARK[item.type_id]) {
    className += " landmark";
  }
  if (item.point_type === "river") {
    className += " river";
  }

  // Бликуем точки интереса, когда показываем все точки
  if (isViewAll && item.type_id == 32 && !item.recom) className += " t-32";

  // Слева, справа на маршруте
  if (item.type_id == 32 && item.side == "L") className += " left-side";
  if (item.type_id == 32 && item.side == "R") className += " right-side";

  // Рекомендации
  if (item.recom && item.type_id == 32) {
    if (item.purpose == "rest") className += ` poi-23`;
    if (item.purpose == "sleep") className += ` poi-32`;
    if (item.purpose == "refuel") className += ` poi-98`;
    if (isFinite(item.place_index) && item.place_index) {
      className += " reserve";
    }
  }

  // Активная POI
  if (isActive) className += " active";
  // Показывать footer карточки
  if (isActive && isFooter) className = className + " with-footer";

  //ПВП
  if (item.point_type === "PVP") {
    className += " toll";
  }
  return className;
};

export const getRoadClassName = linesState => {
  // Определяем вложенность при помощи индекса (Платное в плат. автодороге или наоборот)
  if (linesState.platon.idx > linesState.toll.idx) {
    if (linesState.toll.active > 0) return " toll";
    if (linesState.platon.active > 0) return " platon";
  } else {
    if (linesState.platon.active > 0) return " platon";
    if (linesState.toll.active > 0) return " toll";
  }
};
export const checkInLines = (linesState, item, idx) => {
  // Начало линии Платной дороги
  if (item.point_type == "in_toll") {
    linesState.toll.idx = idx;
    linesState.toll.active += 1;
  }
  // Начало линии Платон
  if (item.point_type == "in_platon") {
    linesState.platon.idx = idx;
    linesState.platon.active += 1;
  }
};

export const checkOutLines = (linesState, item, idx) => {
  // Конец линии Платной дороги
  if (item.point_type === "out_toll") linesState.toll.active -= 1;
  // Конец линии Платон
  if (item.point_type === "out_platon") linesState.platon.active -= 1;
};
