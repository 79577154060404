import React, { useContext } from "react";
import { cn } from "@bem-react/classname";
const cN = cn("routecard-navigation");
import PlainArrow from "../../assets/icons/plain-arrow-left.svg";
import { PoiContext } from "./poiContext";

import "./style/routeNavigation.scss";
type poiKeys = "current" | "prev" | "next";
export type NavigationPoints = {
  [key in poiKeys]: { dist: number; onClick?: () => void };
};
export type NavigationSchedule = {
  purpose: "sleep" | "rest" | "refuel";
  timeTimestamp: string;
  deltaTime?: number;
};

export const RouteNavigation: React.FC = () => {
  const poiContext = useContext(PoiContext);

  return (
    <div className={cN("wrapper")}>
      <div className={cN("button-panel")}>
        <RouteNavigationButton
          onClick={poiContext.points.prev && poiContext.points.prev.onClick}
          direction="prev"
          dist={
            poiContext.points.prev &&
            poiContext.points.current.dist - poiContext.points.prev.dist
          }
        >
          предыдущий
        </RouteNavigationButton>
        <RouteNavigationButton
          direction="current"
          dist={poiContext.points.current.dist}
        >
          по маршруту
        </RouteNavigationButton>
        <RouteNavigationButton
          onClick={poiContext.points.next && poiContext.points.next.onClick}
          direction="next"
          dist={
            poiContext.points.next &&
            poiContext.points.next.dist - poiContext.points.current.dist
          }
        >
          следующий
        </RouteNavigationButton>
      </div>
    </div>
  );
};

export default RouteNavigation;

const RouteNavigationButton: React.FC<
  React.PropsWithChildren<{
    dist: number;
    direction: string;
    onClick?: () => void;
  }>
> = props => (
  <div
    style={{ visibility: props.dist !== undefined ? "visible" : "hidden" }}
    className={cN("button", { direction: props.direction })}
    onClick={props.onClick}
  >
    {/* {props.direction !== "current" && (
      <PlainArrow className={cN("arrow", { direction: props.direction })} />
    )} */}
    <span className={cN("direction")}>{props.children} </span>
    <span className={cN("dist")}>{Math.floor(props.dist / 1000)} км</span>
  </div>
);
