export interface IRoutePoint {
  coords: { lat: number; lng: number };
  name: string;
  loc: string;
  type: PointType;
}
export type PointType = "start" | "middle" | "finish";
export class RoutePoint implements IRoutePoint {
  public get loc(): string {
    return `${this.coords.lat},${this.coords.lng}`;
  }
  public name: string;
  public coords: { lat: number; lng: number };
  public type = "middle" as PointType;
  public toString(): string {
    return `${this.type} ${this.name} ${this.coords}`;
  }
}
