import { cn } from "@bem-react/classname";
import { RefuelInfo } from "multigo-map-api/types/Controllers/Api/types";
import React, { useContext } from "react";
import { fuelColorFactory } from "utils/helpers/fuelColorFactory";
import { PoiWithScroll } from "../RoutePanel/components/BuiltRoute/RouteList/RouteListItem";
import { PoiContext } from "./poiContext";
import "./style/fuelColor.scss";

export const FuelColors: React.FC = () => {
  const poiContext = useContext(PoiContext);
  const { vCatData } = poiContext;
  const cN = cn("routecard-fuel-colors");
  const fuelBlockWidth = 100 / poiContext.fuelPriceColors.length;
  const hightPrice =
    (1 + 0.01 * poiContext.fuelPriceColors.slice(-1)[0].min) *
    poiContext.avgPrice;
  const lowPrice =
    (1 + 0.01 * poiContext.fuelPriceColors[0].max) * poiContext.avgPrice;

  return (
    <div className={cN("wrapper")}>
      <div className={cN("price-row")}>
        {Number.isFinite(lowPrice) && (
          <p className={cN("price-tag")} style={{ float: "left" }}>
            {(vCatData && vCatData.minPrice) || lowPrice.toFixed(2)}
          </p>
        )}
        {poiContext.fuelName ? (
          <p className={cN("fuel-tag")}>{poiContext.fuelName}</p>
        ) : (
          ""
        )}
        {Number.isFinite(hightPrice) && (
          <p className={cN("price-tag")} style={{ float: "right" }}>
            {(vCatData && vCatData.maxPrice) || hightPrice.toFixed(2)}
          </p>
        )}
      </div>
      <div
        className={cN("spectre")}
        style={{
          background: `linear-gradient(to right, ${poiContext.fuelPriceColors
            .map(color => color.bg)
            .join()})`
        }}
      >
        {poiContext.fuelPriceColors.map((fuelColor, index) => (
          <div
            className={cN("spectre-block")}
            style={{
              width: `${fuelBlockWidth}%`,
              backgroundColor: "transparent",
              color: fuelColor.color
            }}
            key={index}
          >
            {index === poiContext.fuelIndex ? poiContext.price : " "}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FuelColors;
