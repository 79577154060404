import * as React from "react";
import { useRef } from "react";
import {
  checkInLines,
  checkOutLines,
  getItemClassName,
  getRoadClassName
} from "../utils";
import { PUNCT_NAMES } from "../utils/consts";

import {
  renderFooter,
  renderItemHeader,
  ItemPoint,
  renderTollPlatonLine
} from "./components";
import { WarningCard } from "./components/items/warning";
import { RouteListRecord } from "multigo-map-api";
import { useIsMobile } from "../../../../../utils/hooks/useMobileDetect";

interface RouteListItemProps {
  numPunctChecker: (increase: boolean) => number;
  item: PoiWithScroll;
  isViewAll: boolean;
  linesState: {
    toll: {
      idx: number;
      active: number;
    };
    platon: {
      idx: number;
      active: number;
    };
  };
  idx: number;
  curIndex: number;
  clickHandler: (idx: number) => void;
  afterOnclickMore(e: any, idx: number);
  isFooter: boolean;
  getScrollContainerRef?: () => React.MutableRefObject<any>;
}
export interface PoiWithScroll extends RouteListRecord {
  scrollInList?: () => void;
  litersMax?: number;
}
export const RouteListItem = (props: RouteListItemProps) => {
  const numPunct = props.numPunctChecker(
    Boolean(PUNCT_NAMES[props.item.type_id])
  );
  const isMobile = useIsMobile();
  const poiRef = useRef(null);

  props.item.scrollInList = () => {
    try {
      if (!props.getScrollContainerRef) {
        return;
      }

      const containterRef = props.getScrollContainerRef();

      const listOffset = poiRef.current.parentElement.parentElement.offsetTop;
      const poiOffset = listOffset + poiRef.current.offsetTop;
      if (
        poiOffset < containterRef.current.scrollTop ||
        poiOffset > containterRef.current.scrollTop + window.innerHeight
      ) {
        const yStep =
          (listOffset +
            poiRef.current.offsetTop -
            40 -
            containterRef.current.scrollTop) /
          30;
        let stepCount = 0;
        const interval = setInterval(() => {
          if (stepCount === 30) {
            clearInterval(interval);
            return;
          }
          containterRef.current.scrollTop += yStep;
          stepCount += 1;
        }, 500 / 30);

        // for (let tick = 0; tick < 30; tick++) {

        //   containterRef.current.scrollTop+=yStep

        // }
        // containterRef.current.scrollTop =
      }

      // if (containterRef.current.scrollTop >)
      // if (poiRef.current && containterRef && containterRef.current) {
      //   // Проверяем, что div  с информацией находится на экране, если нет - скроллим
      //   const outScreenTop =
      //     containterRef.current.scrollTop >
      //     poiRef.current.offsetTop + poiRef.current.offsetHeight;
      //   const outScreenBot =
      //     poiRef.current.offsetTop + poiRef.current.offsetHeight >
      //     containterRef.current.offsetHeight + containterRef.current.scrollTop;

      //   if (outScreenTop || outScreenBot) {
      //     // containterRef.current.scrollTo(0, poiRef.current.offsetTop);
      //     containterRef.current.scrollTop = poiRef.current.offsetTop;
      //   }
      // }
    } catch (e) {}
  };

  // Канистра или место отдыха, ночлег "в поле"
  if (
    (props.item.point_type == "refuel" ||
      props.item.point_type == "rest" ||
      props.item.point_type == "sleep") &&
    props.item.purpose
  ) {
    return <WarningCard _ref={poiRef} idx={props.item.idx} item={props.item} />;
  }

  const className = getItemClassName(
    props.item,
    props.isViewAll,
    props.item.idx === props.curIndex,
    props.isFooter,
    isMobile
  );

  checkInLines(props.linesState, props.item, props.item.idx);
  const roadClassName = getRoadClassName(props.linesState) || "";
  checkOutLines(props.linesState, props.item, props.item.idx);

  // Клик по карточке списка
  const onClickItem = () => {
    props.clickHandler(props.item.idx);
  };

  const onClickMore = e => {
    e.preventDefault();
    props.afterOnclickMore(e, props.item.idx);
  };

  const renderOnClickMore = Boolean(props.item.point_type === "POI");
  return (
    <div
      ref={poiRef}
      onClick={onClickItem}
      className={`${className} ${roadClassName}`}
      key={props.item.idx}
    >
      {renderItemHeader({ item: props.item })}

      <ItemPoint item={props.item} numPunct={numPunct} />
      {props.isViewAll &&
        roadClassName &&
        renderTollPlatonLine(props.item.point_type)}
      {props.isFooter &&
        props.item.idx === props.curIndex &&
        renderFooter(
          props.item.idx,
          props.item.timeTimestamp,
          onClickMore,
          renderOnClickMore
        )}
    </div>
  );
};
