import * as React from "react";
import { PoiWithScroll } from "../RouteListItem";
// Шапка карточки

const words = {
  rest: {
    main: "плановый",
    reserve: "запасной",
    noun: "отдых"
  },
  refuel: {
    main: "плановая",
    reserve: "запасная",
    noun: "заправка"
  },
  sleep: {
    main: "плановый",
    reserve: "запасной",
    noun: "ночлег"
  }
};
export const renderItemHeader = (props: { item: PoiWithScroll }) => {
  const getHead = () => {
    const { purpose, place_index } = props.item;
    if (!purpose) {
      return null;
    }
    if (!Object.keys(words).includes(purpose)) {
      return place_index ? "запасная" : "плановая";
    }
    return `${words[purpose].noun} ${
      place_index ? words[purpose].reserve : words[purpose].main
    }`;
  };
  const head = getHead();
  if (head) {
    return <div className="card-head">{head}</div>;
  }
};
