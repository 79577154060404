import React, { useContext } from "react";
import { PoiContext } from "../poiContext";
import { cn } from "@bem-react/classname";
import { PoiName } from "./PoiName";
import "../style/mainBlock.scss";
import MainBlockIcon from "./MainBlockIcon";

const cN = cn("routecard-main");
type FieldTypes = "region" | "address" | "time" | "phone" | "email" | "site";
type Field = { type: FieldTypes; getter: (poi: any) => string };
const fields = [
  {
    type: "region",
    getter: poi =>
      [poi.country, poi.region, poi.district].filter(Boolean).join(", ")
  },
  { type: "address", getter: poi => poi.address },
  { type: "time", getter: poi => poi.schedule },
  { type: "phone", getter: poi => poi.phone },
  { type: "email", getter: poi => poi.email },
  { type: "site", getter: poi => poi.site }
] as Field[];

export const MainBlock: React.FC = () => {
  const poiContext = useContext(PoiContext);
  if (!poiContext.poiContent) {
    return null;
  }
  return (
    <div className={cN("wrapper")}>
      <PoiName />
      <div className={cN("fields-wrapper")}>
        {fields.map(field => (
          <FieldComponent
            type={field.type}
            text={field.getter(poiContext.poiContent)}
            key={field.type}
          />
        ))}
      </div>
      {/* <button className="routecard-main-link"> Я владелец данной АЗС</button> */}
    </div>
  );
};

const FieldComponent: React.FC<{ type: FieldTypes; text: string }> = props =>
  props.text ? (
    <div className={cN("field")}>
      <MainBlockIcon type={props.type} />
      {props.type === "site" ? (
        <a
          rel="noopener noreferrer"
          target="_blank"
          className={cN("field-link")}
          href={
            props.text.slice(3) === "http"
              ? props.text
              : `https://${props.text}`
          }
        >
          {props.text}
        </a>
      ) : (
        <p className={cN("field-text")}>{props.text}</p>
      )}
    </div>
  ) : null;
export default MainBlock;
