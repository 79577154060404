import React, { useState } from "react";
import { FuelColorLabel } from "./FuelColor";

import "./fuelColors.scss";
import { FuelPriceColor } from "multigo-map-api/types/Controllers/Api/map/types";
export interface FuelColorSpectreProps {
  colorMask: boolean[];
  setColorMask: (newMask: boolean[]) => void;
  colors: FuelPriceColor[];
  baseFilterColor: number;
}

export const FuelColorSpectre: React.FC<FuelColorSpectreProps> = props => {
  const onChangeFactory = (index: number) => (newValue: boolean) => {
    const newMask = [...props.colorMask];
    newMask[index] = newValue;
    props.setColorMask(newMask);
  };

  return (
    <div className="fuel-color-spectre-wrapper">
      <div className="fuel-color-spectre-text">Показать АЗС с уровнем цен</div>
      {props.colors.map((color, index) => (
        <FuelColorLabel
          color={color}
          key={index}
          checked={props.colorMask[index]}
          checkHandler={onChangeFactory(index)}
          length={props.colors.length}
        />
      ))}
    </div>
  );
};
