import * as React from "react";
import { useContext } from "react";

import { RouteListContext } from "../../../../utils/context";
import { ToRenderItem } from "../common";
import { WithTooltip } from "multigo-ui";
import { FuelCard } from "multigo-map-api/types/Controllers/Api/types";

export const FuelCardsBlock = (props: { item: ToRenderItem }) => {
  const { details } = props.item;
  const context = useContext(RouteListContext);
  const { tarifPolEnabled, visible } = context;
  const { fuels } = details;
  const activeFuel = (fuels || ([] as any[])).find((f) => f.selected);

  if (activeFuel && activeFuel.origPrice) {
    var tarifCard = activeFuel.fuelCard;
  }
  const getPercent = (fcard: FuelCard) => {
    if (fcard.tarif && activeFuel) {
      if (activeFuel.origPrice > fcard.tarifPrice) {
        return "positive";
      }
      if (activeFuel.origPrice < fcard.tarifPrice) {
        return "negative";
      }
      return "neutral";
    }
    return "unknown";
  };
  return (
    <div className="mgo-fuel-cards-block">
      {details &&
        details.fuelCards &&
        details.fuelCards.map((fcard, key) => {
          const percent = getPercent(fcard);
          const tarifZero = Math.abs(parseFloat(fcard.tarif)) < 0.01;
          return (
            <div key={key} className="block" title={fcard.name}>
              <div className={"inner"}>
                <img src={fcard.imgUrl} />
                {tarifPolEnabled && visible.hideTP !== 1 && (
                  <div
                    title={
                      percent === "unknown"
                        ? "для топливной карты не определена тарифная политика"
                        : `тарифная политика ${tarifZero ? "цена стеллы" : fcard.tarif}`
                    }
                    className={`percent-decorator ${percent}`}
                  >
                    <WithTooltip
                      position="right"
                      text={
                        fcard.tarif
                          ? `тарифная политика ${tarifZero ? "цена стеллы" : fcard.tarif}`
                          : "для топливной карты не определена тарифная политика"
                      }
                    >
                      {percent === "unknown" ? "С" : "%"}
                    </WithTooltip>
                  </div>
                )}
              </div>
              <div className="text">
                {fcard.name}
                {tarifCard &&
                  fcard.tarifSelected &&
                  details.fuelCards.length > 1 && (
                    <div className={"recomend"}>Рекомендуем!</div>
                  )}
              </div>
            </div>
          );
          // }
        })}
    </div>
  );
};
