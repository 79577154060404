import { Fetcher, IFetcher } from "../fetcher";
import { DefaultResponse } from "../types";
import {
  FullPoiData,
  FullPoiInfo,
  InitData,
  TileData,
  TilePoints
} from "./types";
import "./types";

export interface IMapAPI {
  init: (key: string) => Promise<Partial<InitData>>;
  getTilePoints: (coords: {
    x: number;
    y: number;
    z: number;
  }) => Promise<DefaultResponse<TilePoints>>;
  setFilter: (filter: object) => Promise<{}>;
  setLocatorFilter: (data: { filter: object; vt: string }) => Promise<{}>;
  getBrandSrc: (options: {
    brandId: number | string;
    prefixA: string;
    prefixB: string;
  }) => Promise<DefaultResponse<string>>;
  setFuel: (fuelId: number) => Promise<{}>;
  getPoiInfo: (
    objectId: string
  ) => Promise<DefaultResponse<{ object: FullPoiData }>>;
}

export function MapAPI(fetcher: IFetcher) {
  this.initCache = {} as {
    [key: string]: Promise<Partial<InitData>>;
  };
  this.init = async key => {
    const initKey = key || "multigo";
    if (this.initCache[initKey]) {
      return this.initCache[initKey];
    }
    this.initCache[initKey] = new Promise(async (resolve, reject) => {
      try {
        const response = await fetcher.get<Partial<InitData>>("map/init", {
          key: initKey
        });
        fetcher.setToken(response.data.token);
        delete response.data.token;
        resolve(response.data);
      } catch (err) {
        reject(err);
      }
    });
    return this.initCache[initKey];
  };

  this.getTilePoints = (coords: { x: number; y: number; z: number }) => {
    if (
      !(
        coords &&
        Number.isFinite(coords.x) &&
        Number.isFinite(coords.y) &&
        Number.isFinite(coords.z)
      )
    ) {
      throw new Error("TypeError: invalid coords type");
    }

    const { x, y, z } = coords;
    const url = `map/p4t/${z}/${y}/${x}`;
    return fetcher.protectedGet<TilePoints>(url);
  };

  this.setFilter = (filterObj: object) => {
    return fetcher.protectedGet("map/sf", {
      filter: encodeURIComponent(JSON.stringify(filterObj))
    });
  };

  this.setLocatorFilter = (data: { filter: object; vt: string }) => {
    return fetcher.protectedGet("map/locator/sf", {
      filter: encodeURIComponent(JSON.stringify(data.filter)),
      vt: data.vt
    });
  };

  this.getBrandSrc = (options: {
    brandId: number | string;
    prefixA: string;
    prefixB: string;
  }) => {
    const { brandId, prefixA, prefixB } = options;
    if (!(brandId && prefixA && prefixB)) {
      throw new Error("TypeError: invalid options type");
    }
    const url = `map/icon/brand/${brandId}/${prefixA}/${prefixB}`;
    return fetcher.protectedGet<string>(url);
  };

  this.setFuel = fuelId => {
    return fetcher.protectedGet("map/set/fuel", { fuelId });
  };

  this.getPoiInfo = (objectId: string) =>
    fetcher.protectedGet("map/poi/get", { objectId });
}
