import axios from "axios";
export const BASE_URL =
  (process.env.NODE_ENV === "development"
    ? "https://tc-debug.multigo.ru"
    : "https://terminal5.multigo.ru") + "/api/1/";

let apiToken = ""; //FIXME:
let activeTerminalId = "";

export interface ApiResponseProps {
  err: number;
  data?: any;
  errmsg?: string;
}
export const setApiCredentials = (credentials: {
  apiToken?: string;
  activeTerminalId?: string;
}) => {
  if (credentials.apiToken) {
    localStorage.setItem("apiToken", credentials.apiToken);
    apiToken = credentials.apiToken;
  }
  if (credentials.activeTerminalId) {
    activeTerminalId = credentials.activeTerminalId;
  }
};

export const removeApiToken = () => {
  localStorage.setItem("apiToken", "");
  apiToken = "";
  activeTerminalId = "";
};

interface AxiosConfigType {
  baseURL: string;
  headers: {
    "access-token"?: string;
  };
}

export const getAxiosConfig = () => {
  const axiosConfig: AxiosConfigType = {
    baseURL: BASE_URL,
    headers: {}
  };

  // если есть токен, добавить его в header
  if (apiToken) {
    axiosConfig.headers["access-token"] = apiToken;
  }

  return axiosConfig;
};

export const netPOST: (
  route: string,
  data?: object
) => Promise<ApiResponseProps> = async (route: string, data?: object) => {
  return new Promise(resolve => {
    axios
      .post(
        route,
        {
          ...(activeTerminalId && { terminalId: activeTerminalId }),
          ...data
        },
        // appending activeTerminalId field to all requests
        getAxiosConfig()
      )
      .then(response => {
        resolve(response.data as ApiResponseProps);
      })
      .catch((error: Error) => {
        /* tslint:disable:no-console */
        console.error(error);
        resolve({
          err: -1,
          errmsg: "Произошла ошибка клиента"
        } as ApiResponseProps);
      });
  });
};
