import { GenericEvent, GenericTask, GenericValue } from "../generics";
import { GenericController } from "./generics";

export interface ILayerController {
  userLayer: GenericValue<{ display: boolean }>;
  markersLayer: GenericValue<{ display: boolean }>;
  roadsLayer: GenericValue<{ display: boolean }>;
  activeTileId: GenericValue<number>;
  tileAdd: GenericEvent<{ x: number; y: number; z: number }>;
  tileRemove: GenericEvent<{ x: number; y: number; z: number }>;
  tileRedraw: GenericTask<void, void>;
}

export class LayersController extends GenericController
  implements ILayerController {
  public userLayer: GenericValue<{ display: boolean }>;

  public markersLayer: GenericValue<{ display: boolean }>;

  public roadsLayer: GenericValue<{ display: boolean }>;

  public activeTileId: GenericValue<number>;

  public tileAdd: GenericEvent<{ x: number; y: number; z: number }>;

  public tileRemove: GenericEvent<{ x: number; y: number; z: number }>;

  public tileRedraw: GenericTask<void, void>;

  constructor(setTriggers: any, defaultValues) {
    super();
    const layerTriggers = {} as any;
    ["userLayer", "markersLayer", "roadsLayer"].forEach(layerName => {
      this[layerName] = new GenericValue<{ display: boolean }>(
        trigger => {
          layerTriggers[layerName] = trigger;
        },
        { display: true }
      );
      // this[layerName].subscribeAsWorker((newValue: { display: boolean }) => {
      //   layerTriggers[layerName](newValue);
      // });
    });

    this.activeTileId = new GenericValue<number>(trigger => {
      layerTriggers.activeTileId = trigger;
    }, undefined);

    this.activeTileId.subscribeAsWorker(newValue => {
      layerTriggers.activeTileId(newValue);
    });

    this.tileAdd = new GenericEvent<{ x: number; y: number; z: number }>(t => {
      layerTriggers.tileAdd = t;
    });

    this.tileRemove = new GenericEvent<{
      x: number;
      y: number;
      z: number;
    }>(t => {
      layerTriggers.tileRemove = t;
    });

    this.tileRedraw = new GenericTask<void, void>(t => {
      layerTriggers.tileRedraw = t;
    }, 1);
    setTriggers(layerTriggers);
  }
}
