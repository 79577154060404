export const PUNCT_NAMES = {
  34: 'Старт',
  35: 'Промежуточная точка',
  36: 'Финиш',
};

export const LANDMARK = {
  14: 'Река',
};

export const TOLL_NAMES = {
  51: 'Платон въезд на участок',
  52: 'Платон съезд с участка',
  53: 'Платная атодорога въезд на участок',
  54: 'Платная атодорога съезд с участка',
};

export const OTHER_NAMES = {
  rest: 'Отдых',
  sleep: 'Ночлег',
  refuel: 'Канистра',
};
