import { format } from "date-fns";
import {
  ProtoVehicle,
  Vehicle,
} from "multigo-map-api/types/Controllers/Api/map/types";
import { ClassType } from "../../../utils/types/classType";
import { NumberProps, SelectProps } from "./props";
import { FuelType } from "./routeFilters";

type Fakeable<T> = T & { isFake?: true };
export interface ISettingsMixin {
  hasSettings: boolean;
  settings: { [key: string]: string | number };
  defaultSettings: { [key: string]: string | number | Date };
  settingsList: SettingsRow[];
  routerSets: Fakeable<RouterSet[]>;
  selectedProtoVehicleId: string;
  availableVehicles: ProtoVehicle[];
  getSettingsDict: (v: boolean) => { [key: string]: number | string };
}
export interface ISettingsAPI {}

interface BasicSet {
  type: number;
  prop: string;
  name: string;
  min?: number;
  max?: number;
  values?: number[] | Array<{ _id: number; name: string }>;
}

export interface SettingsRow extends BasicSet {
  fast?: number;
  value: string | number;
  handler: (newValue: string | number) => void;
}

interface RouterSet extends BasicSet {
  displayed?: boolean;
  visible: 1 | 0;
  defValue: number;
  fast?: number;
  ctrl: {
    enable: 0 | 1;
    if: string;
  };
}

export const SettingsMixin = <
  BaseClass extends ClassType<{ update: () => void; api: ISettingsAPI }>
>(
  base: BaseClass
) =>
  class MixedClass extends base implements ISettingsMixin {
    public get hasSettings(): boolean {
      return this._settings !== undefined;
    }
    public get settings(): { [key: string]: string | number } {
      return this._settings;
    }
    public set settings(newSettings: { [key: string]: string | number }) {
      Object.keys(newSettings).forEach((settingsKey) => {
        this._settings[settingsKey] = newSettings[settingsKey];
      });
      this.updateWithList();
    }

    public set defaultSettings(newSettings: {
      [key: string]: string | number;
    }) {
      this._defaultSettings = {
        ...newSettings,
        dateBegin: this.formatIfDate(new Date()),
      };
      this.settings = this._defaultSettings;
    }
    public get settingsList(): SettingsRow[] {
      return this._settingsList || [];
    }
    public set routerSets(routerSets) {
      if (!routerSets || routerSets.isFake) {
        return;
      }
      this._routerSets = routerSets;
      this._settings = {};
      routerSets.forEach((set: NumberProps | SelectProps) => {
        this._settings[set.prop] = set.defValue;
      });

      this.updateWithList();
    }
    public get routerSets(): Fakeable<RouterSet[]> {
      return this._routerSets;
    }

    public get selectedProtoVehicleId(): string {
      return this._selectedProtoVehicleId;
    }

    public set selectedProtoVehicleId(value: string) {
      this._selectedProtoVehicleId = value;
      const vehicle = (this._vehicles || []).find((v) => v.id === value);
      if (vehicle) {
        this.settings[vehicle.routerProp] = vehicle.routerValue;
        setTimeout(() => {
          this.updateWithList();
        }, 0);
      }
    }

    public get availableVehicles(): Fakeable<ProtoVehicle[]> {
      return this._vehicles || [];
    }

    public set availableVehicles(value: Fakeable<ProtoVehicle[]>) {
      // if (value.isFake || !value) {
      //   return;
      // }
      this._vehicles = value || [];
    }

    public _defaultSettings: { [key: string]: string | number } = {};

    public _settingsList: SettingsRow[];

    public _settings: {
      [key: string]: string | number;
    };
    public _routerSets: Fakeable<RouterSet[]>;

    public _selectedProtoVehicleId: string;
    public _vehicles: ProtoVehicle[];

    public updateSettingsList = () => {
      if (!this._routerSets) {
        this._settingsList = [];
        return;
      }
      this._settingsList = this._routerSets
        .filter((rs) => {
          const shouldRender = this.shouldRenderSet(rs);
          if (rs.displayed && !shouldRender) {
            this._settings[rs.prop] = undefined;
          }
          rs.displayed = shouldRender;
          return shouldRender;
        })
        .map((routerSet) => {
          if (this._settings[routerSet.prop] === undefined) {
            this._settings[routerSet.prop] = this.getDefaultValue(routerSet);
          }
          return {
            value: this.getSettingsValue(routerSet),
            prop: routerSet.prop,
            name: routerSet.name,
            handler: this.createHandler(routerSet.prop),
            type: routerSet.type,
            min: routerSet.min,
            max: routerSet.max,
            values: routerSet.values,
            fast: routerSet.fast || 0,
          };
        });
    };

    public getDefaultValue(routerSet: RouterSet): string | number {
      if (routerSet.type === 8) {
        return this.formatIfDate(new Date());
      }
      return this._defaultSettings[routerSet.prop] !== undefined
        ? this._defaultSettings[routerSet.prop]
        : routerSet.defValue;
    }

    public getSettingsDict(onlyUser?: boolean) {
      return this._routerSets.reduce((acc, routerSet) => {
        if (
          routerSet.prop.charAt(0) !== "_" &&
          ((onlyUser &&
            routerSet.visible &&
            (!routerSet.ctrl.enable || this.checkIfStrig(routerSet))) ||
            (!onlyUser &&
              (!routerSet.ctrl.enable || this.checkIfStrig(routerSet))))
        ) {
          const formattedValue = this.formatIfDate(
            this._settings[routerSet.prop]
          );

          acc[routerSet.prop] = formattedValue;
        }
        return acc;
      }, {});
    }

    public getDispalyedSettingsDict() {
      return this._routerSets.reduce((acc, routerSet) => {
        if (
          routerSet.prop.charAt(0) !== "_" &&
          routerSet.visible &&
          this.checkIfStrig(routerSet)
        ) {
          acc[routerSet.prop] = this._settings[routerSet.prop];
        }
        return acc;
      }, {});
    }

    public async updateProp(propName: string, value: number | string) {
      // if (this._settings[propName] === undefined) {
      //   throw new Error(`invalid propname: ${propName}`);
      // }

      this._settings[propName] = this.formatIfDate(value);
      this.updateWithList();
    }

    public createHandler(propName: string) {
      return async (value: string | number) => {
        await this.updateProp(propName, value);
      };
    }

    public shouldRenderSet(routerSet: RouterSet): boolean {
      if (!routerSet.visible) {
        return false;
      }
      if (!routerSet.ctrl.enable) {
        return true;
      }
      return this.checkIfStrig(routerSet);
    }

    public checkIfStrig(routerSet: RouterSet): boolean {
      if (!routerSet.ctrl || !routerSet.ctrl.if) {
        return false;
      }
      let ifString = routerSet.ctrl.if.replace(" ", "");
      Object.keys(this._settings)
        .sort((a, b) => (a.length > b.length ? 1 : -1))
        .forEach((propName) => {
          ifString = ifString.replace(
            new RegExp("it." + propName, "g"),
            this._settings[propName] !== undefined
              ? this._settings[propName].toString()
              : undefined
          );
        });
      if (ifString.match(/[|=0-9 ()&!<>]?/gi)) {
        try {
          // tslint:disable-next-line: no-eval
          return eval(ifString);
        } catch {
          return false;
        }
      }
      // throw new Error("Unsafe eval() for string: " + routerSet.ctrl.if);
      // tslint:disable-next-line: no-console
      console.error("Unsafe eval() for string: " + routerSet.ctrl.if);
      return false;
    }
    public updateWithList = () => {
      this.updateSettingsList();
      this.update();
    };
    public formatIfDate(value: any) {
      if (value && typeof value.toISOString === "function") {
        return format(value as Date, "yyyy-MM-dd'T'HH:mm:ss");
      }
      return isFinite(Number(value)) ? Number(value) : value;
    }
    private getSettingsValue(routerSet) {
      if (routerSet.type === 8 && this._settings[routerSet.prop] === 0) {
        return this.getDefaultValue(routerSet);
      }
      return this._settings[routerSet.prop] !== undefined
        ? this._settings[routerSet.prop]
        : this.getDefaultValue(routerSet);
    }
  };
