import * as React from "react";
import { useContext } from "react";

import { RouteListContext } from "../../../../utils/context";
import { getDistance } from "../../../../utils/index";

import { Rating } from "./Rating";
import { GoButton } from "./GoButton";
import { ToRenderItem } from "../common";

export const AddressBlock = (props: { item: ToRenderItem }) => {
  const context = useContext(RouteListContext);
  const {
    imgUrl,
    brandName,
    // _rating,
    // _rating_cnt,
    dist,
    // address,
    // yandexUrl,
    details
  } = props.item;
  return (
    <div className="mgo-address-block">
      <div className="block-1">
        <div className="name">
          <img src={imgUrl} />
          {brandName}
        </div>
        {details && details.address && (
          <div className="address">{details.address}</div>
        )}

        <Rating item={props.item} />
      </div>
      <div className="block-2">
        <GoButton
          dist={getDistance(props.item)}
          isMobile={context.isMobile}
          url={"" || context.getYandexUrl(props.item)}
        />
      </div>
    </div>
  );
};
