// import { Route } from "../../SavedRoutes/Data/Route";
import { RoutesAPI } from "../api";
import { SimpleRoute } from "../api/types";
import { IRoutePoint, RoutePoint } from "./point";

export interface IPlainRoute {
  points: IRoutePoint[];
  startPoint: IRoutePoint;
  finishPoint: IRoutePoint;
  routeApi: RoutesAPI;
  time: number;
  timeStr: string;
  distance: number;
  mapKey: string;
  rerender: () => void;
  clear: () => void;
  build: () => void;
  addPoint: (point: IRoutePoint, index?: number) => void;
  updatePoint: (
    index,
    latlng: { lat: number; lng: number },
    name?: string
  ) => void;
  deletePoint: (index: number) => void;
  changePointIndex: (prevIndex: number, nextIndex: number) => void;
  appendPoint: (point: IRoutePoint) => void;
  reverse: () => void;
  unmount: () => void;
  update: () => void;
  api: RoutesAPI;
  id: number | string;
}

export class PlainRoute implements IPlainRoute {
  public get points(): IRoutePoint[] {
    return this._points;
  }
  public set rerender(rerender: () => void) {
    this._rerender = rerender;
  }
  public get mapKey(): string {
    return this._mapKey;
  }

  public set mapKey(value: string) {
    this._mapKey = value;
    this.update();
  }

  public set routeApi(api: RoutesAPI) {
    this.api = api;
    this.api.unbindListeners();
    this.api.bindListeners();
    this.api.initMap();
  }

  public get routeApi(): RoutesAPI {
    return this.api;
  }

  // public get story():

  public get startPoint(): IRoutePoint {
    return this._points.find(point => point.type === "start");
  }
  public get finishPoint(): IRoutePoint {
    return this._points.find(point => point.type === "finish");
  }
  public get id(): number | string {
    return this._id;
  }
  public get time(): number {
    // in hours
    return this.routeData
      ? this.routeData.route_summary.total_time / 3600
      : NaN;
  }
  public get timeStr(): string {
    if (
      this.routeData &&
      this.routeData.route_summary &&
      this.routeData.route_summary.total_time
    ) {
      const timeInMinutes = Math.floor(
        this.routeData.route_summary.total_time / 60
      );
      const hours = Math.floor(timeInMinutes / 60);
      const minutes = timeInMinutes % 60;
      return `${hours} ч ${minutes} мин`;
    }
    return "";
  }
  public get distance(): number {
    // in kilometers
    return this.routeData
      ? this.routeData.route_summary.total_distance / 1000
      : NaN;
  }
  private get canBuild(): boolean {
    return (
      this._points[0] &&
      this._points[0].type === "start" &&
      this.points.slice(-1)[0].type === "finish"
    );
  }
  public api: RoutesAPI;
  private _mapKey: string;
  private _id = 0;
  private _points = [] as IRoutePoint[];
  private routeData: SimpleRoute;
  private _rerender: () => void;
  public unmount = () => {
    this.api.unbindListeners();
  };

  public build = async (options?: { rerender: boolean }) => {
    if (this.canBuild) {
      this.routeData = (await this.api.createSimpleRoute(this._points)).data;
      this.api.drawRoute(this.routeData);
    } else {
      this.api.deleteRoute(this._id);
    }
    if (options && options.rerender) {
      this.update();
    }
  };

  public clear() {
    this.api.unbindListeners();
  }

  public addPoint(newPoint: IRoutePoint, index?: number) {
    if (newPoint.type === "start") {
      this._points = [
        newPoint,
        ...this._points.filter(point => point.type !== "start")
      ];
    }
    if (newPoint.type === "finish") {
      this._points = [
        ...this._points.filter(point => point.type !== "finish"),
        newPoint
      ];
    }
    if (newPoint.type === "middle") {
      if (this.finishPoint) {
        if (index !== undefined) {
          this._points.splice(index, 0, newPoint);
        } else {
          this._points = [
            ...this.points.slice(0, -1),
            newPoint,
            ...this.points.slice(-1)
          ];
        }
      } else {
        this._points.push(newPoint);
      }
    }
    this.update();
    setTimeout(() => this.build({ rerender: true }), 0);
  }

  public appendPoint(newPoint) {
    if (this._points.length === 0) {
      newPoint.type = "start";
    } else {
      if (this._points.length > 1) {
        this._points.slice(-1)[0].type = "middle";
      }
      newPoint.type = "finish";
    }

    this._points.push(newPoint);
    this.build({ rerender: true });
  }

  public deletePoint(index: number) {
    this.popPoint(index);
    this.build({ rerender: true });
  }
  public reverse() {
    this._points.reverse();
    const [startPoint, finishPoint] = [this.startPoint, this.finishPoint];
    if (startPoint) {
      startPoint.type = "finish";
    }
    if (finishPoint) {
      finishPoint.type = "start";
    }
    this.build({ rerender: true });
  }

  public async updatePoint(
    index: number,
    latlng: { lat: number; lng: number },
    name?: string
  ) {
    const point = this.pointByIndex(index);
    this._points[index].coords = latlng;
    if (name) {
      point.name = name;
    } else {
      point.name = await this.routeApi.reverseGeocoder(latlng);
    }
    this.build({ rerender: true });
  }

  public changePointIndex(prevIndex: number, nextIndex: number) {
    if (prevIndex === nextIndex) {
      return;
    }

    const point = this.popPoint(prevIndex);
    if (nextIndex === 0) {
      if (this.startPoint) {
        this.startPoint.type = this._points.length === 1 ? "finish" : "middle";
      }
      point.type = "start";
    } else if (nextIndex === this._points.length) {
      if (this.finishPoint) {
        this.finishPoint.type = "middle";
      }
      point.type = "finish";
    } else {
      point.type = "middle";
    }

    this._points.splice(nextIndex, 0, point);
    this.build({ rerender: true });
  }
  public update() {
    if (this._rerender) {
      this._rerender();
    }
  }

  private popPoint(index: number) {
    const deletingPoint = this.pointByIndex(index);
    if (deletingPoint.type === "start" && this._points.length > 1) {
      this._points[1].type = "start";
    }
    if (deletingPoint.type === "finish" && this._points.length > 2) {
      this._points.slice(-2)[0].type = "finish";
    }
    this._points.splice(index, 1);
    return deletingPoint;
  }
  private pointByIndex(index: number) {
    if (!this._points[index]) {
      throw new Error(`Index Error: point with index ${index} not found`);
    }
    return this._points[index];
  }
}
