import { format, parse } from "date-fns";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../../../utils/hooks/useOnClickOutside";
import { TimeField } from "../../../../utils/sharedComponents/timeField";

import { Button } from "multigo-ui";
import Calendar from "react-calendar";
// import CalendarIcon from "../../../../assets/icons/calendar.svg";
import CalendarIcon from "../../../../assets/icons/calendar.svg";
export const DatetimeSelector = props => {
  const calendarRef = useRef(null);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const toggleCalendar = () => {
    setCalendarIsOpen(true);
  };
  useOnClickOutside(calendarRef, () => {
    setCalendarIsOpen(false);
  });

  const date =
    props.field.value === 0
      ? new Date()
      : parse(props.field.value, "yyyy-MM-dd'T'HH:mm:ss", new Date());
  const dateHandler = (newDate: Date) => {
    date.setUTCFullYear(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate()
    );
    props.field.handler(date);
    setCalendarIsOpen(false);
  };

  const timeHandler = time => {
    const [hours, minutes] = time.split(":");
    date.setHours(hours, minutes);
    props.field.handler(date);
  };

  const timeRef = useRef(null);
  const timeKeyDownHandler = async event => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      const delta = event.key === "ArrowUp" ? 1 : -1;
      const [hours, minutes] = props.field.value
        ? event.target.value.split(":").map(Number)
        : [0, 0];

      const selectionPos = event.target.selectionEnd;
      if (event.target.selectionEnd < 3) {
        date.setHours(hours + delta);
      } else {
        date.setMinutes(minutes + delta);
      }
      await props.field.handler(date);
      if (timeRef.current) {
        timeRef.current.setSelectionRange(selectionPos, selectionPos);
      }
    }
  };
  const buttonRef = useRef(null);
  const buttonY = (() => {
    if (buttonRef.current) {
      const { top: docTop } = document.body.getBoundingClientRect();
      const { top: elemTop } = buttonRef.current.getBoundingClientRect();
      return elemTop - docTop;
      // return buttonRef.current.getClientRects();
    }
    return 0;
  })();

  const getTimeValue = () => {
    if (props.field.value) {
      return `${
        date.getHours() >= 10 ? date.getHours() : "0" + date.getHours()
      }:${
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes()
      }`;
    }
    return "--:--";
  };

  return (
    <div className="route-settings-datetime">
      <p className="route-settings-name" ref={buttonRef}>
        {props.field.name}
      </p>

      <button
        onClick={toggleCalendar}
        className="route-settings-datetime__date"
      >
        <CalendarIcon
          width={16}
          height={16}
          style={{ float: "left", marginLeft: "8px" }}
        />
        <div style={{ float: "left", fontSize: "15px", paddingLeft: "6px" }}>
          {props.field.value ? date.toLocaleDateString() : "          "}
        </div>
      </button>
      {props.showTime && (
        <TimeField
          input={<input ref={timeRef} onKeyDown={timeKeyDownHandler} />}
          value={getTimeValue()}
          className="route-settings-datetime__time"
          onChange={timeHandler}
        />
      )}
      {calendarIsOpen && (
        <>
          <div
            ref={calendarRef}
            style={{ position: "absolute", zIndex: 1, width: "348px" }}
          >
            <Calendar value={date} locale="ru-RU" onChange={dateHandler} />
          </div>
        </>
      )}
    </div>
  );
};
