import * as React from "react";
import { format, parseISO } from "date-fns";

export const renderFooter = (
  idx,
  timeTimestamp,
  onClickMore,
  renderOnClickMore
) => {
  // Время прибытия
  // const time = timeTimestamp && format(new Date(timeTimestamp), "HH:mm");
  // const year = timeTimestamp && format(new Date(timeTimestamp), "dd.MM.yyyy");
  const time = timeTimestamp && format(parseISO(timeTimestamp), "HH:mm");
  const year = timeTimestamp && format(parseISO(timeTimestamp), "dd.MM.yyyy");

  return (
    <div className="item-footer mgo-cls">
      {timeTimestamp && (
        <div className="time-year mgo-left">
          <span className="time">{time}</span>
          <span className="year">{year}</span>
        </div>
      )}
      {renderOnClickMore && (
        <a className="mgo-right" onClick={onClickMore} href="#">
          Подробнее &raquo;
        </a>
      )}
    </div>
  );
};
