import { labeledStatement } from "@babel/types";
import React from "react";
import { parseSeconds } from "../../utils/converters";
import { Label } from "./Label";

enum RTOtype {
  work = "Управление авто",
  rest = "Отдых",
  sleep = "Сон"
}
export interface RTOObj {
  name: string;
  value: Array<{
    type: keyof typeof RTOtype;
    time: number;
  }>;
}
export const RTOSetting: React.FC<{
  obj: RTOObj;
  src?: number;
}> = props => (
  <>
    {props.obj.value.map((block, index) => (
      <div
        key={index}
        className={`route-info__item route-info__item-rto route-info__item-${block.type}`}
      >
        <div className="col_1">{RTOtype[block.type]}</div>
        <div className="col_2"> {parseSeconds(block.time)}</div>
        <div className="col_3">
          <Label label={props.src || 1} />
        </div>
      </div>
    ))}
  </>
);
