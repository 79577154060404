import React, { useContext } from "react";
import { PoiContext } from "./poiContext";
import { cn } from "@bem-react/classname";
const cN = cn("routecard-trassa");
import "./style/trassa.scss";

export const CardTrassa: React.FC = () => {
  const poiContext = useContext(PoiContext);

  const poi = poiContext.poiContent;
  if (!poi) {
    return null;
  }
  return (
    <dl className={cN("wrapper")}>
      <TrassaField
        name="километр трассы"
        value={poi.road_km && poi.road_km + " км"}
      />
      <TrassaField
        name="трасса"
        value={poi.road_km && poi.road_km + " км"}
        routeId={poi.road}
      />
      <TrassaField name="адрес" value={poi.address} />
    </dl>
  );
};

const TrassaField: React.FC<{
  name: string;
  value: string;
  routeId?: string;
}> = props => {
  if (!props.value) {
    return null;
  }
  return props.value ? (
    <>
      <dt className={cN("key")}>
        {props.name}
        {props.routeId && (
          <div className={cN("trassa")}>
            <div className="inner">{props.routeId}</div>
          </div>
        )}
      </dt>
      <dd className={cN("value")}>{props.value}</dd>
    </>
  ) : null;
};
export default CardTrassa;
