import * as React from "react";
import * as ReactDOM from "react-dom";

import pack from "../../../package.json";
import "multigo-ui/build/style.css";
import { LocatorApp } from "../../components";
const search = window.location.search.slice(1);
const params = search.split("&").reduce((acc, curr) => {
  const [k, v] = curr.split("=");
  acc[k] = v;
  return acc;
}, {}) as any;

console.log(`L2 v ${pack.version}`);
// console.log("Url params", params);

ReactDOM.render(
  <LocatorApp
    debug={params.debug !== undefined ? params.debug === "true" : undefined}
    mapKey={params.key || "multigo"}
    showControlPanel={true}
    routeId={params.routeId || ""}
    vehicleId={params.vehicleId || ""}
    points={params.points || ""}
    viewMode={params.viewMode === "1"}
    isMobile={params.isMobile === "true"}
  />,
  document.getElementById("root")
);
