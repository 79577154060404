import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";

import { Button } from "multigo-ui";

import AddIcon from "../../../assets/icons/add.svg";
import DotsIcon from "../../../assets/icons/dots.svg";
import ReverseIcon from "../../../assets/icons/reverse.svg";
import { useForceUpdate } from "../../../utils/hooks/useForceUpdate";
import { PointType, RoutePoint } from "../data/point";
import { IPlainRoute } from "../data/route";
import { ISettingsMixin } from "../data/settingsMixin";
import { EditPoint } from "./editPoint";
import { RouteQuickSettings } from "./routeQuickSettings";

interface PointListProps {
  points?: string;
  route: IPlainRoute;
  routeSets: ISettingsMixin;
  setObjectSelectorState: (state: { handler: (params: any) => void }) => void;
  applySettings: () => void;
}

const style = { width: "25px", height: "25px" };
const CHAR_OF_FIRST_POINT = 49; // 65 for ABC

export const PointsList = (props: PointListProps) => {
  const urlPoints = useMemo(() => {
    const pp = props.points.split(";").map((ll) => ll.split(","));
    const result = [];
    for (const p of pp) {
      if (Array.isArray(p) && p.length === 2) {
        result.push({
          lat: parseFloat(p[0]),
          lng: parseFloat(p[1]),
        });
      } else {
        return null;
      }
    }
    return result;
  }, [props.points]);

  const ref = useRef(null);
  const [draggingIndex, setDraggingIndex] = useState(undefined as number);
  const [insertingIndex, setInsertingIndex] = useState(undefined as number);

  const [dragCoords, setDragCoords] = useState([0, 0]);

  const [lastUpdate, setLastUpdate] = useState(+new Date());

  const mouseMove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const currentTime = +new Date();
    if (currentTime - lastUpdate > 16) {
      const { height, width, y } = ref.current.getBoundingClientRect();
      const delta = event.pageY - y;

      setDragCoords([event.pageX - width + 20, event.pageY - 20]);
      setLastUpdate(currentTime);
      setInsertingIndex(
        Math.max(Math.floor((delta * props.route.points.length) / height), 0)
      );

      rerender();
    }
  };

  const handleMouseDownFactory = (index: number) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { width } = ref.current.getBoundingClientRect();
    setDragCoords([event.pageX - width + 20, event.pageY - 20]);
    setDraggingIndex(index);
    setInsertingIndex(index);
    rerender();
  };

  const dragEndHandler = (event) => {
    props.route.changePointIndex(
      draggingIndex,
      Math.min(insertingIndex, props.route.points.length - 1)
    );
    setDraggingIndex(undefined);
    setInsertingIndex(undefined);
    rerender();
  };

  const rerender = useForceUpdate();

  const reverseRoute = () => {
    props.route.reverse();
  };
  const geocoder = (q, params) => props.route.routeApi.geocoder(q, params);

  // const getEditPoint = (type:string, index:number)=>
  const editHandlerCreator = (params?: {
    pointIndex?: number;
    type?: PointType;
  }) => (latlng, name: string) => {
    if (!name) {
      return;
    }

    if (params && isFinite(Number(params.pointIndex))) {
      props.route.updatePoint(params.pointIndex, latlng, name);
    } else {
      const point = new RoutePoint();
      point.type = (params && params.type) || "middle";
      point.name = name;
      point.coords = latlng;
      props.route.addPoint(point);
    }
  };

  const listRow = (point, index) => {
    if (!point) {
      return;
    }
    const suffix = (() => {
      switch (point.type) {
        case "start":
          return "green";
        case "finish":
          return "red";
        case "middle":
        default:
          return "grey";
      }
    })();

    const removePoint = () => {
      if (isFinite(index)) {
        props.route.deletePoint(index);
      }
    };
    return (
      <li key={index}>
        <div className={`point ${suffix}`}>
          {String.fromCharCode(
            CHAR_OF_FIRST_POINT +
              (isFinite(index)
                ? index
                : index > 0
                ? Math.max(props.route.points.length, 1)
                : 0)
          )}
        </div>
        {isFinite(index) && (
          <div className="dots">
            <DotsIcon
              width={8}
              height={24}
              onMouseDown={handleMouseDownFactory(index)}
            />
          </div>
        )}
        <EditPoint
          name={point.name}
          geocoder={geocoder}
          // setObjectSelectorState={props.setObjectSelectorState}
          editPointHandler={editHandlerCreator({
            type: point.type,
            pointIndex: index,
          })}
        />
        <div className="remove-point">
          <AddIcon width={24} height={24} onClick={removePoint} />
        </div>
      </li>
    );
  };

  useEffect(() => {
    const addPoints = async (points) => {
      const rrg = (ll) => props.route.routeApi.reverseGeocoder(ll);

      for (let i = 0; i < points.length; i += 1) {
        const ll = points[i];

        let ptype: PointType = "middle";
        if (i === 0) ptype = "start";
        else if (i === points.length - 1) ptype = "finish";

        const point = new RoutePoint();
        point.type = ptype;
        // point.name = `${ll.lat}, ${ll.lng}`;
        point.name = await rrg(ll);
        point.coords = ll;
        props.route.addPoint(point);
      }
    };

    if (
      props.route.routeApi &&
      props.route.points.length === 0 &&
      urlPoints !== null
    ) {
      addPoints(urlPoints);
    }
  }, [urlPoints, props.route.routeApi]);

  return (
    <div className="tabs-content">
      <div className="scroll-content">
        <RouteQuickSettings route={props.routeSets} />

        <div ref={ref}>
          <ul className="route-points-list" onMouseMove={mouseMove}>
            {!props.route.startPoint && listRow({ type: "start" }, -Infinity)}

            {props.route.points.map((point, index) => {
              if (index === draggingIndex) {
                return null;
              }
              return (
                <React.Fragment key={index}>
                  {draggingIndex !== undefined &&
                    ((index < draggingIndex && index === insertingIndex) ||
                      (index >= draggingIndex &&
                        index === insertingIndex + 1)) && <hr key="hr" />}
                  {listRow(point, index)}
                </React.Fragment>
              );
            })}
            {!props.route.finishPoint && listRow({ type: "finish" }, Infinity)}
            {props.route.points.length <= 2 && (
              <div className="reverse-icon" onClick={reverseRoute}>
                <ReverseIcon width={16} heigth={16} />
              </div>
            )}

            {draggingIndex !== undefined &&
              props.route.points.length > 0 &&
              insertingIndex >= props.route.points.length - 1 && (
                <hr key="hr" />
              )}

            {props.route.startPoint && props.route.finishPoint && (
              <li>
                <div className="point add-point">
                  <AddIcon width={24} height={24} />
                </div>

                <EditPoint
                  geocoder={(q, params) => {
                    return props.route.routeApi.geocoder(q, params);
                  }}
                  // setObjectSelectorState={props.setObjectSelectorState}
                  editPointHandler={editHandlerCreator()}
                />
              </li>
            )}
          </ul>
        </div>

        {props.route.time && props.route.distance ? (
          <div className="route-build-status" style={{ marginBottom: 20 }}>
            <p className="route-build-status__name">Время в пути:</p>
            <p className="route-build-status__value">{props.route.timeStr}</p>
            <p className="route-build-status__name">Расстояние:</p>
            <p className="route-build-status__value">
              {props.route.distance.toFixed(1)} км
            </p>
          </div>
        ) : (
          ""
        )}

        <div className="route-build-ctrl-run">
          <Button
            disabled={
              !(
                !props.route.buildStatus &&
                props.route.startPoint &&
                props.route.finishPoint
              )
            }
            size="xl"
            primary={true}
            onClick={props.applySettings}
            style={{ width: "172px" }}
          >
            построить
          </Button>
        </div>

        {draggingIndex !== undefined && (
          <div
            onMouseMove={mouseMove}
            onMouseUp={dragEndHandler}
            className="route-points-list"
            style={{
              position: "fixed",
              width: `${ref.current.getBoundingClientRect().width}px`,
              height: "48px;",
              top: `${dragCoords[1]}px`,
              left: `${dragCoords[0] + 348}px`,
              opacity: 0.8,
              background: "rgb(3, 120, 190)",
            }}
          >
            {listRow(props.route.points[draggingIndex], draggingIndex)}
          </div>
        )}
      </div>
    </div>
  );
};
