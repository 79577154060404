import * as polyline from "@mapbox/polyline";
import { IController } from "multigo-map-api";
import {
  FilterSet,
  FullPoiData,
} from "multigo-map-api/types/Controllers/Api/map/types";
import {
  FullPoiInfo,
  LocatorSettings,
} from "multigo-map-api/types/Controllers/Api/map/types";
import { DefaultResponse } from "multigo-map-api/types/Controllers/Api/types";
import { SaveRouteParams } from "multigo-map-api/types/Controllers/Router/routerBuilder";
import { checkOutLines } from "../components/BuiltRoute/utils";
import { IRoutePoint } from "../data/point";
import { RoutePoint } from "../data/point";
import { RouteFiltersData } from "../data/routeFilters";
import {
  IRouteWithSettings,
  RouteWithSettings,
} from "../data/routeWithSettings";
import { SendToWialon } from "../data/types";
import { SimpleRoute } from "./types";

export interface GeocoderParams {
  latlng?: { lat: number; lng: number };
  limit?: number;
}

export interface IRoutesAPI {
  drawRoute: (route: SimpleRoute) => string;
  deleteRoute: (roadId: string) => void;
  bindListeners: () => void;
  unbindListeners: () => void;
  initMap: () => void;
  getPoiInfo: (
    poiId: string
  ) => Promise<DefaultResponse<{ object: FullPoiData }>>;
  mapKey: string;
  locatorSettings: LocatorSettings;
  setCenter: (latlng: [number, number]) => Promise<[number, number]>;
  setZoom: (newZoom: number) => Promise<number>;
  setViewPort: (viewPort: { center: [number, number]; zoom: number }) => void;
  reverseGeocoder: (latlng: { lat: number; lng: number }) => Promise<string>;
  removeRouteById: (routeId: string) => void;
  geocoder: (
    q: string,
    params?: GeocoderParams
  ) => Promise<Array<{ ll: [number, number]; address: string }>>;
  saveRouteToShorLink: (
    params: SaveRouteParams
  ) => Promise<{ shortId: string; shortUrl: string }>;
  sendToWialon?: SendToWialon;
  createSimpleRoute(points: IRoutePoint[]);
}
export interface IFilterAPI {
  getFilterSets: () => FilterSet[];
  setLocatorFilters: (fitlers: any, vt: string) => void;
  setLocatorFiltersMapper?: (mapper: number[]) => void;
}

export class RoutesAPI implements IRoutesAPI, IFilterAPI {
  public get locatorSettings(): any {
    try {
      return this.controller.map.data.locator;
    } catch (e) {
      return undefined;
    }
  }
  public mapKey: string;
  public sendToWialon: SendToWialon | undefined;

  protected route: IRouteWithSettings;
  protected listeners: Array<{ remove: () => void }> = [];
  protected controller: IController;

  private _filters: RouteFiltersData;

  private _locatorSettings: LocatorSettings;
  private checkListener: { remove: () => void };
  private getListener: { remove: () => void };

  constructor(
    controller: IController,
    route: IRouteWithSettings,
    filters: RouteFiltersData
  ) {
    this.controller = controller;
    this.route = route;
    this._filters = filters;

    // подрядок важен, т.к. this.route.routeApi = this; запускает инициализацию карты
    this._filters.routeApi = this;

    this.route.routeApi = this;

    if (this.controller.router.wialonExportTask.workersCount) {
      this.sendToWialon = (
        routeId: string,
        routeName?: string,
        desc?: string
      ) => {
        const vehicle = this.route.selectedVehicle;

        if (vehicle && vehicle.wialonId) {
          return this.controller.router.wialonExportTask.require({
            vehicleId: vehicle.wialonId,
            routeId,
            name: routeName,
            desc,
          });
        }
      };
    }
  }

  public getPoiInfo(poiId: string) {
    return this.controller.api.map.getPoiInfo(poiId);
  }
  public drawRoute(route: SimpleRoute) {
    if (!route && this.controller.map.roads.removeRoad.workersCount) {
      this.controller.map.roads.removeRoad.require(this.route.id);
    }
    const latlngs = polyline.decode(route.route_geometry, 6);
    if (this.controller.map.roads.createRoad.workersCount) {
      this.controller.map.roads.createRoad.require({
        id: this.route.id,
        latlngs,
        viaPoints: route.via_points,
      });
    }
    return "";
  }
  public async reverseGeocoder(latlng: { lat: number; lng: number }) {
    try {
      const res = await this.controller.api.geocoder.reverse(latlng);
      if (!res.err) {
        return res.data.address;
      }
    } catch (e) {}
    return `${latlng.lat.toFixed(6)}, ${latlng.lng.toFixed(6)}`;
  }
  public async geocoder(q: string, params?: GeocoderParams) {
    const res = await this.controller.api.geocoder.forward(q, params);
    if (!res.err) {
      return res.data.results;
    }
    return [];
  }

  public deleteRoute(roadId) {
    this.updateContextMenu();
    this.controller.map.roads.removeRoad.workersCount &&
      this.controller.map.roads.removeRoad.require(roadId);
    return "";
  }

  public setCenter(latlng) {
    return (
      this.controller.map.center.workersCount &&
      this.controller.map.center.require(latlng)
    );
  }
  public setZoom(newZoom: number) {
    return (
      this.controller.map.zoom.workersCount &&
      this.controller.map.zoom.require(newZoom)
    );
  }
  public setViewPort(viewPort: { center: [number, number]; zoom: number }) {
    this.controller.map.setViewPort.workersCount &&
      this.controller.map.setViewPort.require({
        ...viewPort,
        zoom: Math.max(this.controller.map.zoom.value, viewPort.zoom),
      });
  }
  public createSimpleRoute(points: IRoutePoint[]) {
    return this.controller.api.geometry.getSimpleRoute({
      ll: points.map((point) => point.loc).join(";"),
    }) as Promise<{
      data: SimpleRoute;
      err: number;
    }>;
  }
  public async initMap() {
    await this.initSettings();
    this.updateContextMenu();
  }

  public async runRoute(params?: { ll: string; options: string }) {
    this.controller.router.run.require(params);
    this.checkListener = this.createCheckListener();
  }

  public async stopRouteBuild() {
    if (this.getListener) {
      this.getListener.remove();
    }
    this.getListener = undefined;
    if (this.checkListener) {
      this.checkListener.remove();
    }
    this.checkListener = undefined;
    this.resetFilters();
    this.controller.router.run.workersCount &&
      this.controller.router.run.require(undefined);
  }

  public async getRoute(routeId: string, viewMode: boolean) {
    this.checkListener = this.createCheckListener();
    this.controller.router.check.require(routeId, viewMode);
  }

  public bindListeners() {
    this.listeners.push(this.subscribeMapContext());

    this.listeners.push(
      this.controller.map.roads.pointCreated.subscribe(async (payload) => {
        const point = new RoutePoint();
        point.coords = payload.latlng;
        point.name = await this.reverseGeocoder(payload.latlng);
        this.route.addPoint(point, Math.max(payload.insertIndex, 1));
      })
    );

    this.listeners.push(
      this.controller.map.roads.pointMoved.subscribe((payload) => {
        this.route.updatePoint(payload.pointId, payload.latlng);
      })
    );

    // this.listeners.push(
    //   this.controller.map.mapLoaded.subscribe(async () => {
    //     await this.controller.map.layers.markersLayer.setValue({
    //       display: false
    //     });
    //   })
    // );

    this.listeners.push(
      this.controller.router.poiClick.subscribe(
        (params: { poiIndex: number; routeId: string }) => {
          this.route.activePOI = params.poiIndex;
        }
      )
    );

    this.listeners.push(
      this.controller.map.activeMarker.subscribe(({ _id }: { _id: string }) => {
        this.route.setActiveMarker(_id);
      })
    );
  }
  public drawRouteById(routeId: string, isViewAll?: boolean) {
    this.controller.router.drawRouteById(routeId, isViewAll);
  }
  public drawPoisOnMap(routeId: string, poiIds: string[]) {
    this.controller.router.drawRoutePoi(routeId, poiIds);
  }

  public removeRouteById(routeId: string) {
    this.controller.router.removeRoute.require(routeId);
    this.updateContextMenu();
    // this.controller.map.setLocatorFilter.require([]);
    this._filters.applyFilters();
  }
  public setMarkersDisplayState = (newState: boolean) => {
    if (this.controller.map.layers.markersLayer.workersCount) {
      this.controller.map.layers.markersLayer.require({ display: newState });
    }
  };

  public unbindListeners() {
    this.listeners.forEach((listener) => {
      listener.remove();
    });
  }
  public setLocatorFilters = (filter: any, vt: string) => {
    this.controller.map.setLocatorFilter.require({ filter, vt });
  };
  public setActiveMarker(_id: string) {
    if (
      !this.controller.map.activeMarker.value ||
      this.controller.map.activeMarker.value._id !== _id
    ) {
      this.controller.map.activeMarker.setValue({ _id });
    }
  }

  public saveRouteToShorLink = (params: SaveRouteParams) =>
    this.controller.router.save.require(params);

  public getFiltersIdsByVehicleId(vehicleId: string) {
    return (!this.controller.map.data.locator.vehicleTypes as any).isFake
      ? this.controller.map.data.locator.vehicleTypes.find(
          (v) => v.id === vehicleId
        ).filterTypes
      : [];
  }

  public getFilterSets() {
    try {
      return this.controller.map.data.locator.filterSets;
    } catch (e) {
      return [];
    }
  }

  public resetFilters(applyFilters?: boolean) {
    this._filters.filterSets = this.controller.map.data.locator.filterSets;
    this._filters.useMultiFuels =
      this.locatorSettings.show && this.locatorSettings.show.useMultiFuels > 0;
    const zeroVehicle = this.route.availableVehicles[0];
    if (zeroVehicle) {
      this._filters.fuelTypes = zeroVehicle.fuelTypes;
      this._filters.vehicleType = zeroVehicle.id;
    }
    this._filters.availableFilters = this.getFiltersIdsByVehicleId(
      zeroVehicle ? zeroVehicle.id : undefined
    );

    this._filters.defaultFuels =
      this.locatorSettings.show && this.locatorSettings.show.defaultFuelIds;
    if (applyFilters) {
      this._filters.applyFilters();
    }
  }

  private createCheckListener = () => {
    const checkListener = this.controller.router.check.subscribe((data) => {
      const formatted = data;
      formatted.percent = Math.floor(formatted.percent);
      this.route.buildStatus = formatted;
    });

    this.getListener = this.createGetListener();
    return checkListener;
  };
  private createGetListener = () =>
    this.controller.router.get.subscribe(async (data) => {
      this.checkListener.remove();
      this.checkListener = undefined;
      this.getListener.remove();
      this.getListener = undefined;
      this.deleteRoute(this.route.id);
      this.controller.router.drawRouteById(data._id);

      this.controller.map.redraw.workersCount &&
        this.controller.map.redraw.require();
      this.removeContextMenu();
      this.route.buildContent = data;
      this.route.update();
    });

  private updateContextMenu() {
    this.controller.map.contextMenu.data.mapMenu.setValue({
      style: {
        menu: { width: 150 },
        row: { height: 30 },
      },
      items: [
        { name: "маршрут отсюда", action: "route:from" },
        { name: "маршрут через", action: "route:through" },
        { name: "маршрут сюда", action: "route:to" },
      ],
    });
  }
  private removeContextMenu() {
    this.controller.map.contextMenu.data.mapMenu.setValue({
      style: {
        menu: { width: 150 },
        row: { height: 30 },
      },
      items: [],
    });
  }

  private subscribeMapContext = () =>
    this.controller.map.contextMenu.click.subscribe(async (clickData) => {
      if (clickData.menuId === "mapMenu") {
        const point = new RoutePoint();
        point.coords = clickData.payload.latlng;
        point.name = await this.reverseGeocoder(clickData.payload.latlng);
        await this.reverseGeocoder(clickData.payload.latlng);
        switch (clickData.menuAction) {
          case "route:from":
            point.type = "start";
            break;
          case "route:to":
            point.type = "finish";
            break;
          case "route:through":
          default:
            point.type = "middle";
        }
        this.route.addPoint(point);
      }
    });
  private async initSettings() {
    this.controller.router.leafletSettings.workersCount &&
      this.controller.router.leafletSettings.require({
        route: { color: "#666666", weight: 3 }, // black
        routeVCat: {
          1: { color: "#666666", weight: 3 }, // black
          2: { color: "#ff0000", weight: 5 }, // red
        },
        Platon: { color: "#3399ff", weight: 4 }, // blue
        Toll: { color: "#bd10e0", weight: 4 }, // purple
      });

    if (this.controller.map.data) {
      await this.initRouteSets();
    } else {
      const settingsListener = this.controller.map.init.subscribe(() => {
        settingsListener.remove();
        this.initRouteSets();
      });
    }
  }

  private async initRouteSets() {
    this._locatorSettings = this.controller.map.data.locator;
    this.route.routerSets = this.controller.map.data.locator.routerSets;
    this.route.availableVehicles = this.controller.map.data.locator.vehicleTypes;
    this.route.savedVehicles = this.controller.map.data.locator.vehicles;

    const zeroVehicleId =
      (this.route.savedVehicles[0] && this.route.savedVehicles[0].type) ||
      (this.route.availableVehicles[0] && this.route.availableVehicles[0].id);
    if (zeroVehicleId) {
      this.route.selectedProtoVehicleId = zeroVehicleId;
    }
    if (this.route.savedVehicles[0]) {
      const vehicle = this.route.savedVehicles[0];
      const protoV = this.route.availableVehicles.find(
        (v) => v.id === zeroVehicleId
      );
      this.route.selectedVehicleId = vehicle.id;
      this.route.defaultSettings = { ...vehicle.routeSettings, dateBegin: 0 };
      this.resetFilters(false);
      if (protoV) {
        this._filters.vehicleType = protoV.id;
        this._filters.restoreFitlers(
          vehicle.filters,
          vehicle.filtersMapper,
          protoV.filterTypes
        );
      }
    } else {
      this.resetFilters(true);
    }
  }

  private toggleMarkers() {
    this.controller.map.layers.markersLayer.setValue({
      display: !this.controller.map.layers.markersLayer.value.display,
    });
  }
}
