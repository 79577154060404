import { cn } from "@bem-react/classname";
import { WithTooltip } from "multigo-ui";
import React, { useContext } from "react";
import { PoiContext } from "./poiContext";
import "./style/fuelPrices.scss";
const cN = cn("routecard-fuelprice");

export const FuelPrice: React.FC = () => {
  const poiContext = useContext(PoiContext);
  const { visibleParts } = poiContext;

  const poi = poiContext.poiContent;
  //   poiContext.fuelName

  const fuelCards = poi.fuelCards || [];
  const { fuelCard, fuelName } = poiContext;
  return (
    <div className={cN("wrapper")}>
      {poi.fuels.map((fuel, index) => {
        const tarifPols = fuelCards.reduce((acc, fCard) => {
          const tarif =
            fCard.tarifByFuel &&
            fCard.tarifByFuel.find((tarif) => tarif.fuelId === fuel.fuelId);
          if (tarif) {
            acc += `${tarif.tarif} по карте ${fCard.name} \n`;
          }
          return acc;
        }, "");
        let activeTarif;
        if (fuelName === fuel.fuelId) {
          const activeTarifPol = fuelCards.find(
            (fCard) =>
              fCard.tarifByFuel &&
              fCard.tarifByFuel.find((tarif) => tarif.fuelId === fuel.fuelId) &&
              fCard.id === fuelCard
          );
          if (activeTarifPol) {
            activeTarif = activeTarifPol.tarifByFuel.find(
              (t) => t.fuelId === fuel.fuelId
            );
          }
        }
        const price = fuel.fuelPrice ? fuel.fuelPrice.toFixed(2) : "?";
        return (
          <div className={cN("block")} key={fuel.fuelId}>
            <div
              className={cN("id", {
                active: fuel.fuelId === poiContext.fuelName,
              })}
            >
              {fuel.fuelId}
              {visibleParts.hideTP !== 1 && activeTarif && (
                <div
                  className={`percent-decorator ${
                    activeTarif.tarifPrice < fuel.fuelPrice
                      ? "positive"
                      : activeTarif.tarifPrice > fuel.fuelPrice
                      ? "negative"
                      : "neutral"
                  }`}
                >
                  <WithTooltip
                    position="left"
                    text={`тарифная политика ${activeTarif.tarif}`}
                  >
                    %
                  </WithTooltip>
                </div>
              )}
            </div>
            <div className={cN("price")}>
              {tarifPols ? (
                <WithTooltip
                  position={index % 2 ? "left" : "right"}
                  text={tarifPols}
                >
                  {price}
                </WithTooltip>
              ) : (
                price
              )}
            </div>
            {fuel.fuelName ? (
              <div className={cN("name")}>{fuel.fuelName || " "}</div>
            ) : null}
            {fuel.fuelDate ? (
              <div className={cN("date")}>
                {fuel.fuelDate !== "Invalid date"
                  ? fuel.fuelDate.length > 10
                    ? fuel.fuelDate.slice(0, 10)
                    : fuel.fuelDate
                  : ""}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
