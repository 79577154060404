import React, { useContext } from "react";
import { getDistance } from "../../../../utils";
import { RouteListContext } from "../../../../utils/context";
import { cursorTo } from "readline";
import { renderFooter } from "../../footer";
import { RouteListRecord } from "multigo-map-api";
import { PoiWithScroll } from "../../../RouteListItem";
import { useIsMobile } from "../../../../../../../../utils/hooks/useMobileDetect";

export interface WarningCardProps {
  item: RouteListRecord;
  idx: number;
  _ref: React.MutableRefObject<any>;
}
type tsubs = {
  img: string;
  text: string;
  className: string;
  footerObj: string;
};
const cardContent = {
  rest: {
    img: "https://cdn-img.multigo.ru/1/img/category-icon/23/0/travel",
    text: "Не нашли места отдыха",
    className: "poi-23",
    footerObj: "мест отдыха",
  },
  refuel: {
    img: "https://cdn-img.multigo.ru/1/img/category-icon/98/0/travel",
    text: "Пустой бак",
    className: "poi-98",
    footerObj: "АЗС",
  },
  sleep: {
    img: "https://cdn-img.multigo.ru/1/img/category-icon/32/0/travel",
    text: "Не нашли ночлег",
    className: "poi-32",
    footerObj: "мест ночлега",
  },
};
export const WarningCard: React.FC<WarningCardProps> = (props) => {
  const context = useContext(RouteListContext);
  const subs = cardContent[props.item.purpose] as tsubs;
  const [nearestIdx, nearestPoi] = context.getPoiById(
    props.item.nearest_id
  ) as [number, PoiWithScroll];

  const isMobile = useIsMobile();

  // console.log(nearestPoi);

  return (
    <div
      ref={props._ref}
      className={`route-list-item shift attention ${
        context.isFooter && context.curIndex === props.idx ? "with-footer" : ""
      } ${subs.className}${isMobile ? " mobile" : ""}`}
      onClick={() => {
        context.clickHandler(props.idx);
      }}
    >
      <div className="card-head">
        <div className="title-attention">Внимание</div>
      </div>
      <div className="mgo-attention-item-card">
        <div className="mgo-attention-item-card__row">
          <div className="mgo-attention-item-card__left">
            <div className="mgo-attention-item-card__name">
              <img src={subs.img} />
              {subs.text}
            </div>
          </div>
          <div className="mgo-attention-item-card__right">
            <div className="mgo-attention-item-card__km">
              {getDistance(props.item)}
            </div>
          </div>
        </div>
        {nearestPoi && (
          <div
            onClick={() => context.clickHandler(nearestIdx)}
            className="mgo-attention-item-card__near"
          >
            {/* Ближайшее к данной точке место отдыха */}
            Ближайший объект в категории "{nearestPoi.details?.category?.name}"
            <div className="mgo-attention__near-info">
              <div className="mgo-attention__near-row">
                <div className="mgo-attention__near-name">
                  {nearestPoi.name}
                </div>
                <div className="mgo-attention__near-km">
                  {getDistance(nearestPoi)}
                </div>
              </div>
              {nearestPoi.details && nearestPoi.details.address && (
                <div className="mgo-attention__near-row">
                  <div className="mgo-attention__near-address">
                    {nearestPoi.details.address}
                  </div>
                </div>
              )}
            </div>
            находится на расстоянии{" "}
            {getDistance({ dist: nearestPoi.dist_to_can })}
          </div>
        )}
      </div>
      {!nearestPoi && (
        <div className="warning-item-footer">
          В радиусе {context.info.options.searchAZSwhenCan} км {subs.footerObj}{" "}
          нет!
        </div>
      )}
      {context.isFooter &&
        props.idx === context.curIndex &&
        renderFooter(
          props.idx,
          props.item.timeTimestamp,
          (e) => {
            e.preventDefault();
            context.afterOnclickMore(e, props.idx);
          },
          false
        )}
    </div>
  );
};

export default WarningCard;
