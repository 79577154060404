import React, { useState } from "react";
import {
  FullPoiInfo,
  FullPoiData
} from "multigo-map-api/types/Controllers/Api/map/types";
import { DefaultResponse } from "multigo-map-api/types/Controllers/Api/types";

const CACHE_SIZE = 30;

export const usePoiCache = (
  poiGetter: (
    poiId: string
  ) => Promise<DefaultResponse<{ object: FullPoiData }>>
) => {
  const [poiCache, setPoiCache] = useState([] as FullPoiData[]);

  return async (poiId: string) => {
    const cachedPoi = poiCache.find(poi => poi.poi_id === poiId);
    if (cachedPoi) {
      return cachedPoi;
    } else {
      const newPoiRes = await poiGetter(poiId);
      if (!newPoiRes.err) {
        const newPoint = newPoiRes.data.object;
        setPoiCache([newPoint, ...poiCache].slice(0, CACHE_SIZE));
        return newPoint;
      }
    }
  };
};
