import * as React from "react";
import { useEffect, useMemo, useState } from "react";

import { Tabs } from "multigo-ui";
import { ISettingsMixin } from "../data/settingsMixin";
import { getFieldComponent } from "./fields";
interface RouteSettingsProps {
  style?: React.CSSProperties;
  route: ISettingsMixin;
}

export const RouteSettings = (props: RouteSettingsProps) => {
  const [activeTabId, setActiveTabId] = useState(0);
  const tabHandler = (newTabId) => {
    setActiveTabId(Number(newTabId));
  };
  const settingsTabs = useMemo(() => {
    return (props.route.settingsList || []).reduce((tabs, curr, index) => {
      if (curr.type === 10) {
        if (tabs.slice(-1)[0]) {
          tabs.slice(-1)[0].maxIndex = index;
        }
        tabs.push({
          name: curr.name,
          minIndex: index + 1,
          maxIndex: props.route.settingsList.length,
          id: tabs.length + 1,
        });
      }
      return tabs;
    }, []);
  }, [props.route.settingsList]);

  useEffect(() => {
    if (settingsTabs.length && !activeTabId) {
      setActiveTabId(settingsTabs[0].id);
    }
  }, [settingsTabs.length]);

  const activeTab = settingsTabs.find((tab) => tab.id === activeTabId);
  const sliceRange = activeTab
    ? [activeTab.minIndex, activeTab.maxIndex]
    : [0, settingsTabs.length];

  return (
    <>
      {settingsTabs.length ? (
        <>
          <Tabs
            primary={false}
            activeId={activeTabId}
            list={settingsTabs.map(({ id, name }) => ({ id, name }))}
            handler={tabHandler}
          />
        </>
      ) : null}
      <div className="tabs-content" style={props.style}>
        <div
          className="scroll-content"
          style={{ height: "calc(100% - 62px)" }}
        >
          {props.route.settingsList
            .slice(...sliceRange)
            .map((field, index) => getFieldComponent(field, index))}
        </div>
      </div>
    </>
  );
};
