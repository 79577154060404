import * as React from "react";
import { useContext } from "react";

import { RouteListContext } from "../../../../utils/context";
import { getDistance } from "../../../../utils/index";

import { Rating } from "./Rating";
import { GoButton } from "./GoButton";
import { ToRenderItem } from "../common";

export const NoFiltersMark = (props: { item: ToRenderItem }) => {
  return (
    <div className="mgo-nofilter-mark">
      <div className="mgo-nofilter-mark-msg">
        Объект выбран без учета фильтра
      </div>
    </div>
  );
};
