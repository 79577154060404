import { Slider } from "multigo-ui";
import * as React from "react";

export const NumberSelector = props => {
  const precision =
    (props.field.max - props.field.min) % 1
      ? (props.field.max - props.field.min).toString().length - 2
      : 0;
  const handler = (value: number) => {
    props.field.handler(Number(value.toFixed(precision)));
  };
  return (
    <>
      <p className="route-settings-name">{props.field.name}</p>
      <Slider
        value={props.field.value}
        onChange={handler}
        max={props.field.max}
        min={props.field.min}
        step={10 ** -precision}
      />
    </>
  );
};
