/* eslint-disable react/prop-types,max-len */
import * as React from "react";
import "../css/rating.scss";
import { PoiWithScroll } from "../../../RouteListItem";

export const Rating = (props: { item: PoiWithScroll }) => {
  const rating = props.item.details ? props.item.details.rating : undefined;
  if (!rating) {
    return null;
  }
  const stars =
    rating &&
    rating.count &&
    rating.value &&
    Math.min(Math.floor(rating.value * 2), 10);

  return (
    <>
      {stars ? (
        <div className="rating">
          <div
            className="mgo-stars gray-9"
            style={{ backgroundPositionY: `${-14 * stars - 1}px` }}
          >
            {rating.count ? `(${rating.count})` : ""}
          </div>
        </div>
      ) : null}
    </>
  );
};
