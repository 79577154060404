import { any } from "prop-types";
import { createContext } from "react";
import { getBrandName } from "./contentGetters";

export const getBrandImageUrl = (obj) => {
  return obj.type_id == 14
    ? `${URLS.roadIcons}${obj.type_id}/map`
    : obj.admin_level
    ? getAdminLevelUrl(obj)
    : getBrandUrl(obj, "travel");
};

export const defaultContextProps = {
  tarifPolEnabled: false,
  isMobile: false,
  isFooter: true,
  isViewAll: true,
  curIndex: null,
  info: {} as any,
  visible: {} as any,
  getFuelName: (fuelId) => "",
  getBrandName,
  getFuelColor: (vcat: number, value: number) => ({
    bg: "#ffffff",
    color: "#000000",
    index: undefined,
  }),
  getBrandImageUrl,
  getPvPImageUrl: (id) => `${URLS.roadIcons}${id}/map`,
  getRoadImageUrl: (id) => `${URLS.roadIcons}${id}/list`,
  getServiceImageUrl: (id) => `${URLS.services}${id}/service28`,
  getFuelCardImageUrl: (id) => `${URLS.fcard}${id}/site`,
  getLitersMax: () => 200,
  getYandexUrl: (item) => "",
  setSecondToHours: () => "",
  getPoiById: (poiId: string) => [undefined, undefined],
  getDistance: () => "",
  afterOnclickItem: (...props: any) => {},
  afterOnclickMore: (...props: any) => {},
  clickHandler: (newIndex: number) => {},
  getScrollContainerRef: undefined as () => React.MutableRefObject<any>,
};
export const RouteListContext = createContext(defaultContextProps);

const URLS = {
  minimap: "https://cdn-img.multigo.ru/1/img/poi/minimap/",
  goYaMap: "https://multigo.ru/api/5/nav/to/poi/",
  brands: "https://cdn-img.multigo.ru/1/img/brand/",
  catIcons: "https://cdn-img.multigo.ru/1/img/category-icon/",
  services: "https://cdn-img.multigo.ru/1/img/service/",
  fcard: "https://cdn-img.multigo.ru/1/img/fuel_card/",
  flcard: "https://cdn-img.multigo.ru/1/img/loyalty_card/",
  campaign: "https://cdn-img.multigo.ru/1/img/campaign/",
  photoAzs: "https://cdn-img.multigo.ru/1/img/poi/",
  roadIcons: "https://cdn-img.multigo.ru/1/img/road-item-type/",
};

/*
 Вывод бренда АЗС, если его нет - выводится общая иконка
   */
export const getBrandUrl = (obj, prefix = "travel") => {
  const brand_id = obj.brand || obj.poi_brand;
  const cat_id = obj.cat_id || obj.poi_category_id;
  const { sub_id } = obj;

  // Топливо
  if (brand_id) {
    return `${URLS.brands + brand_id}/kmml`;
  }

  // Остальные категории
  if (cat_id) {
    if (sub_id) {
      return `${URLS.catIcons + cat_id}/${sub_id}/${prefix}`;
    }
    return `${URLS.catIcons + cat_id}/0/${prefix}`;
  }
};

/*
 * Вывод типа населёенного города
 */
export const getAdminLevelUrl = (obj) => {
  const prefix = "";
  // Населённые пункты, чем меньше значение, тем больше иконка
  if (obj && obj.admin_level == 1) {
    return `${URLS.roadIcons}38/${prefix || "map_admin_level_1"}`;
  }
  if (obj && obj.admin_level == 2) {
    return `${URLS.roadIcons}38/${prefix || "map_admin_level_2"}`;
  }
  if (obj && obj.admin_level == 4) {
    return `${URLS.roadIcons}42/${prefix || "trouter4"}`;
  }
  if (obj.admin_level == 5) {
    return `${URLS.roadIcons}42/${prefix || "trouter5"}`;
  }
  if (obj && obj.admin_level == 6) {
    return `${URLS.roadIcons}42/${prefix || "trouter6"}`;
  }
  if (obj && obj.admin_level > 6) {
    return `${URLS.roadIcons}42/${prefix || "trouter8"}`;
  }

  // Населённые пункты из категорий
  if (obj && obj.cat_id == 50) {
    return `${URLS.catIcons}50/${obj.sub_id}/adminLevel`;
  }
};
