import * as React from "react";
import { format, addSeconds, parseISO } from "date-fns";

export const TimeBlock = (props: {
  item: { timeTimestamp?: string; deltaTime?: number };
}) => {
  if (!props.item.timeTimestamp) {
    return null;
  }
  // Время прибытия, убытия
  const schedule = {
    timeIn: format(parseISO(props.item.timeTimestamp), "HH:mm"),
    yearIn: format(parseISO(props.item.timeTimestamp), "dd.MM.yyyy"),
    timeOut: format(parseISO(props.item.timeTimestamp), "HH:mm"),
    yearOut: format(parseISO(props.item.timeTimestamp), "dd.MM.yyyy"),
    stayIn: ""
  };

  // В точке останавливаются
  if (props.item.deltaTime) {
    const newtimeDelta = addSeconds(
      parseISO(props.item.timeTimestamp),
      props.item.deltaTime
    );
    schedule.timeOut = format(newtimeDelta, "HH:mm");

    schedule.yearOut = format(newtimeDelta, "dd.MM.yyyy");
    schedule.stayIn =
      props.item.deltaTime > 3540
        ? `${Math.floor((props.item.deltaTime + 60) / 3600).toString()} ч`
        : `${Math.floor(props.item.deltaTime / 60)} мин`;
  }

  return (
    <div className="mgo-time-block">
      <div className="block-1">
        <div className="time icon-in">{schedule.timeIn}</div>
        <div className="date">{schedule.yearIn}</div>
      </div>
      <div className="block-2">
        {schedule.stayIn && (
          <div>
            <div className="label">Время стоянки</div>
            <div className="time">{`${schedule.stayIn}`}</div>
          </div>
        )}
      </div>
      <div className="block-3">
        <div className="time icon-out">{schedule.timeOut}</div>
        <div className="date">{schedule.yearOut}</div>
      </div>
    </div>
  );
};
