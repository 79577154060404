import { Fetcher, IFetcher } from "./fetcher";
import { Geocoder, IGeocoder } from "./geocoder";
import { GeometryAPI, IGeometryAPI } from "./geometry";
import { IMapAPI, MapAPI } from "./map";
import { IRouterAPI, RouterAPI } from "./router";

import "./types";

export interface IAPIController {
  map: IMapAPI;
  geometry: IGeometryAPI;
  router: IRouterAPI;
  geocoder: IGeocoder;
}

export function APIController(debug: boolean) {
  const fetcher = new Fetcher(debug) as IFetcher;
  this.router = new RouterAPI(fetcher);
  this.geometry = new GeometryAPI(fetcher);
  this.map = new MapAPI(fetcher);
  this.geocoder = new Geocoder();
}
