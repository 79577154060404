import * as React from "react";
import "./css/punct.scss";
import { PoiWithScroll } from "../../RouteListItem";
import { getDistance } from "../../../utils";
import { PUNCT_NAMES } from "../../../utils/consts";
import { useMobileDetect } from "../../../../../../../utils/hooks/useMobileDetect";

export const PunctItemCard = (props: {
  item: PoiWithScroll;
  imgUrl: string;
  pinName: number | string;
  contextInfo: any;
}) => {
  const startInfo = props.contextInfo?.startStop?.start || [];
  const endInfo = props.contextInfo?.startStop?.end || [];

  const getName = () => {
    if (props.item.point_type === "river") {
      return `р. ${props.item.name}`;
    }
    return PUNCT_NAMES[props.item.type_id]
      ? (PUNCT_NAMES[props.item.type_id] as string).toUpperCase()
      : props.item.name;
  };
  const mobileDetector = useMobileDetect();
  return (
    <div
      className={
        "mgo-punct-item-card" + (mobileDetector.isMobile() ? " mobile" : "")
      }
    >
      <div className="point">
        {props.item.admin_level || props.item.type_id == 14 ? (
          <img alt="" src={props.imgUrl} />
        ) : (
          <div className="mgo-pin">{props.pinName || ""}</div>
        )}
      </div>
      <div className="mgo-punct-block">
        <div className="block-1">
          <div className="name">{getName()}</div>
        </div>

        {props.item.type_id === 34 && (
          <div className="block-2 start-end">
            {startInfo.map((x) => (
              <span key={x.fuelId}>
                {x.fuelId} {x.val} л.
              </span>
            ))}
          </div>
        )}
        {props.item.type_id === 36 && (
          <div className="block-2 start-end">
            {endInfo.map((x) => (
              <span key={x.fuelId}>
                {x.fuelId} {x.val} л.
              </span>
            ))}
          </div>
        )}

        {!PUNCT_NAMES[props.item.type_id] && (
          <div className="block-2">
            {props.item.road_id && (
              <div className="road">
                <div className="mgo-trassa-name">
                  <div className="inner">{props.item.road_id}</div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="block-3">
          <div className="km">{getDistance(props.item)}</div>
        </div>
      </div>
    </div>
  );
};
