export const iconsData = {
  start_point: {
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/34/start"
  },
  via_point: {
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/35/middle"
  },
  stop_point: {
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/36/finish"
  },
  trouter5: {
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/42/trouter5"
  },
  trouter6: {
    iconSize: [36, 36],
    iconAnchor: [18, 18],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/42/trouter6"
  },
  in_region: {
    iconUrl:
      "https://cdn-img.multigo.ru/1/img/road-item-type/38/map_admin_level_2",
    iconSize: [24, 24],
    iconAnchor: [12, 12]
  },
  river: {
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/14/map"
  },
  in_platon: {
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/51/map"
  },
  out_platon: {
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/52/map"
  },
  in_toll: {
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/53/map"
  },
  out_toll: {
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/54/map"
  },
  PVP: {
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    iconUrl: "https://cdn-img.multigo.ru/1/img/road-item-type/18/map"
  }
};
