import { FilterSet } from "multigo-map-api/types/Controllers/Api/map/types";
import { IFilterAPI } from "../api";

interface ChipProps {
  selectorName: string;
  chipClass?: string;
  list: SingleChip[];
  selected: Array<string | number>;
  chipsSetter: (chips: any[]) => void;
}
interface SingleChip {
  id: number | string;
  name: string;
}

export interface FuelType {
  ids: number[];
  name: string;
}

export interface IRouteFiltersData {
  filterSets: FilterSet[];
  vehicleType: string;
  applyFilters: () => void;
  chipBlocks: ChipBlock[];
  updater: (() => void) | undefined;
  updateChips: (DictContextProps) => void;
  routeApi: IFilterAPI;
  useMultiFuels: boolean;
  availableFilters: number[];
  fuelTypes: FuelType[];
  defaultFuels: string[];
  restoreFitlers: (
    savedFilters: Array<{ type: number; filter: { [key: string]: number[] } }>,
    filterMapper: number[],
    availableFilters?: number[]
  ) => void;
}

export interface RouteFilter {
  name: string;
  type: number;
  en: number;
  filter: {
    [key: string]: {
      availableObjs: Array<{ id: number; n: string }>;
      selectedIds: number[];
    };
  };
}
export interface ChipBlock {
  name: string;
  type: number;
  hide: boolean;
  showFilters: boolean;
  controllable: boolean;
  chips: Array<ChipProps & { fieldName: string }>;
  setHideState?: (newState: boolean) => void;
}

export class RouteFiltersData implements IRouteFiltersData {
  public set filterSets(filterSets: FilterSet[]) {
    if (filterSets && !("isFake" in filterSets)) {
      this._filters = filterSets.map(filterSet => ({
        name: filterSet.name || "тип №" + filterSet.type,
        type: filterSet.type,
        en: filterSet.en,
        filter: Object.keys(filterSet.filter).reduce((acc, curr) => {
          acc[curr] = {
            availableObjs: filterSet.filter[curr],
            selectedIds:
              filterSet.en > 2 ? filterSet.filter[curr].map(f => f.id) : []
          };
          return acc;
        }, {})
      }));
      this.updateChips();
    }
  }
  public get fuelTypes(): FuelType[] {
    return this._fuelTypes;
  }
  public set fuelTypes(value: FuelType[]) {
    this._fuelTypes = value;
  }

  public set updater(updater: () => void) {
    this._updater = updater;
  }
  public set routeApi(api: IFilterAPI) {
    this._api = api;
  }
  public get chipBlocks(): ChipBlock[] {
    return this._chipBlocks;
  }

  public get useMultiFuels(): boolean {
    return this._useMultiFuels;
  }
  public set useMultiFuels(value) {
    this._useMultiFuels = value;
    this.update();
  }

  public set defaultFuels(defaultFuelNames: string[]) {
    let shouldUpdate = false;
    this._filters.forEach(filterBlock => {
      if (Object.keys(filterBlock.filter).includes("__fuelIds")) {
        filterBlock.filter.__fuelIds.selectedIds = defaultFuelNames
          .map(
            fuelName =>
              (
                filterBlock.filter.__fuelIds.availableObjs.find(
                  obj => obj.n === fuelName
                ) || ({} as any)
              ).id
          )
          .filter(Boolean);
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      this.updateChips();
    }
  }

  public set availableFilters(value: number[]) {
    this._availableFilters = value;
    this.filterSets = this._api.getFilterSets();
    this.updateChips();
  }
  public get vehicleType(): string {
    return this._vehicleType;
  }
  public set vehicleType(value: string) {
    this._vehicleType = value;
  }

  public static fitlerNames = {
    _state: "Состояние",
    ownership: "Формы собственности",
    brand: "Бренды",
    "_ex#fuel_cards": "Топливные карты",
    "_ex#loyalty_cards": "Карты лояльности",
    "_ex#services": "Сервисы",
    road_id: "Трассы",
    __fuelIds: "Топливо"
  };
  private _fuelTypes: FuelType[];

  private _api: IFilterAPI;
  private _useMultiFuels: boolean;
  private _updater: () => void;
  private _filters: RouteFilter[] = [];
  private _chipBlocks: ChipBlock[];

  private _availableFilters: number[];
  private _vehicleType: string;

  public updateChips = () => {
    const update = () => {
      this.applyFilters();
      this.update();
    };
    this._chipBlocks = this._filters
      .map((filterBlock, filterIndex) => {
        const chips = this.getChips(filterBlock, filterIndex);
        return {
          name: filterBlock.name,
          type: filterBlock.type,
          hide: filterBlock.en === 4 || filterBlock.en === 2,
          showFilters: filterBlock.en < 3,
          controllable: filterBlock.en > 0,
          setHideState(newValue: boolean) {
            this.hide = newValue;
            update();
          },
          chips
        };
      })
      .filter(this.filterAvailableFilters);
    this.update();
  };

  public applyFilters = () => {
    const filterData = this._chipBlocks.reduce(
      (acc, chipBlock, index) => {
        if (!chipBlock.hide) {
          acc.filters.push({
            type: chipBlock.type,
            filter: chipBlock.chips.reduce((chipAcc, curr) => {
              chipAcc[curr.fieldName] = curr.selected;
              return chipAcc;
            }, {})
          });
          acc.mapper.push(index);
        }
        return acc;
      },
      { filters: [], mapper: [] }
    );

    this._api.setLocatorFilters(filterData.filters, this.vehicleType);
    if (this._api.setLocatorFiltersMapper) {
      this._api.setLocatorFiltersMapper(filterData.mapper);
    }
  };

  public restoreFitlers(
    savedFilters: Array<{
      type: number;
      filter: { [key: string]: number[] };
    }>,
    filterMapper: number[],
    availableFilters?: number[]
  ) {
    this._availableFilters = availableFilters;

    this._filters
      .filter(this.filterAvailableFilters)
      .forEach((filterSet, filterSetIndex) => {
        const filterInList = filterMapper.includes(filterSetIndex);
        if (filterInList) {
          const savedFilter =
            savedFilters[filterMapper.indexOf(filterSetIndex)];
          Object.keys(savedFilter.filter).forEach(filterKey => {
            if (filterSet.filter[filterKey]) {
              filterSet.filter[filterKey].selectedIds =
                savedFilter.filter[filterKey];
            }
          });
        }
        this.updateFilterEn(filterSet, filterInList);
      });
    this.updateChips();
    this.applyFilters();
  }
  private filterAvailableFilters = (filterBlock: { type: number }) =>
    this._availableFilters
      ? this._availableFilters.includes(filterBlock.type)
      : true;

  private updateFilterEn(filterSet: RouteFilter, shouldShow: boolean) {
    if (!shouldShow && (filterSet.en === 1 || filterSet.en === 3)) {
      filterSet.en += 1;
    }
    if (shouldShow && (filterSet.en === 2 || filterSet.en === 4)) {
      filterSet.en -= 1;
    }
  }
  private getChips = (filterBlock, filterIndex) =>
    Object.keys(filterBlock.filter).map((key, chipIndex) => {
      const name = RouteFiltersData.fitlerNames[key];
      const list = filterBlock.filter[key].availableObjs.map(({ id, n }) => ({
        id,
        name: n
      }));
      const chipBlock = {
        selectorName: name,

        list,
        fieldName: key,
        selected: filterBlock.filter[key].selectedIds,
        chipsSetter: undefined
      };
      this.setChipSetter(chipBlock, filterIndex, key, list);
      if (key === "__fuelIds") {
        this.updateFuelChipblock(
          chipBlock,
          filterBlock.filter[key].selectedIds,
          list
        );
      }
      return chipBlock;
    });

  private setChipSetter(
    chipBlock: ChipProps,
    filterIndex: number,
    key: string,
    list: Array<{ id: number; name: string }>
  ) {
    chipBlock.chipsSetter = (chipsList: any[]) => {
      chipBlock.selected = chipsList as number[];
      if (key === "__fuelIds") {
        this.updateFuelChipblock(chipBlock, chipsList, list);
      }
      this._filters[filterIndex].filter[
        key
      ].selectedIds = chipBlock.selected as number[];
      this.applyFilters();
      this.update();
    };
  }
  private updateFuelChipblock = (
    chipBlock: ChipProps,
    selectedIds: number[],
    list: Array<{ id: number; name: string }>
  ) => {
    const availableFuelIds = list.map(({ id }) => id);
    const availableSelectedIds = selectedIds.filter(id =>
      availableFuelIds.includes(id)
    );
    if (availableSelectedIds.length) {
      const firstId = availableSelectedIds[0];
      if (this._fuelTypes) {
        const activeType = this._fuelTypes.find(ftype =>
          ftype.ids.includes(firstId)
        );
        if (activeType) {
          chipBlock.selected = availableSelectedIds.filter(selectedId =>
            activeType.ids.includes(selectedId)
          );
          chipBlock.list = list.filter(item =>
            activeType.ids.includes(item.id)
          );
          chipBlock.selectorName = `${RouteFiltersData.fitlerNames.__fuelIds} ${activeType.name}`;
          return;
        }
      }
    }
    chipBlock.list = list;
    chipBlock.selectorName = RouteFiltersData.fitlerNames.__fuelIds;
    chipBlock.selected = availableSelectedIds;
  };
  // private getChipSetter = (filterIndex, key, chipIndex) => private;
  private update = () => {
    if (this._updater) {
      this._updater();
    }
  };
}
