import { format } from "date-fns";
/**
 * Форматируемый вывод дыты - time date
 * @param {string} date
 * @param {string} formatTime
 * @param {string} formatDate
 *
 */
export const formatDate = (
  date,
  formatTime = "HH:mm",
  formatDate = "dd.MM.yyyy"
) => {
  return `${format(date, formatTime)} ${format(date, formatDate)}`;
};

/**
 * Округление до нужного знака после запятой
 * @param {number} x - число
 * @param {number} n - знаков после запятой
 */
export const roundPlus = (x: number, n = 2) => {
  if (isNaN(x) || isNaN(n)) return undefined;
  const m = Math.pow(10, n);
  return Math.round(x * m) / m;
};

/**
 * Метры в километры
 * @param {integer} date
 * @param {boolean} asRound
 * @param {boolean} asMetrics
 * @param {boolean} meters
 *
 */
export const distanceKm = (
  dist: number,
  asRound = false,
  asMetrics = true,
  meters = true
) => {
  const withMetric = (str: string, value = "км") => {
    return `${str}${asMetrics ? ` ${value}` : ""}`;
  };

  if (dist === 0) {
    return withMetric("" + dist);
  }
  if (meters === true && dist < 1000) {
    return withMetric(dist.toLocaleString("ru-Ru"), "м");
  }
  if (dist < 1000000) {
    return withMetric(
      asRound
        ? "" + Math.round(dist / 1000).toLocaleString("ru-Ru")
        : (dist / 1000).toFixed(2)
    );
  }
  return withMetric(Math.round(dist / 1000).toLocaleString("ru-Ru"));
};

/**
 *
 * Секунды в минуты, часы, дни, byHours - режим только в часах.
 * @param {integer} t
 * @param {bollean} byHours
 *
 */
export const parseSeconds = (
  t: number,
  byHours = false,
  putMinutes = false
) => {
  if (t === 0) {
    return t;
  }

  //  < 1 минуты
  if (t < 60) {
    return t + " сек";
  }

  //  < 1 часа
  if (t < 3600) {
    const minutes = Math.round(t / 60);
    if (byHours && putMinutes) {
      // больше, меньше 30 минут
      if (minutes < 30) {
        return "менее 1ч";
      } else {
        return "1 ч";
      }
    } else {
      return minutes + " мин";
    }
  }

  //  < 1 суток
  if (t < 86400) {
    if (byHours && !putMinutes) {
      return Math.round(t / 3600) + " ч";
    } else {
      const hours = Math.floor(t / 3600);
      const minutes = Math.round((t % 3600) / 60);
      return (
        (hours > 0 ? hours + " ч" : "") +
        (minutes ? " " + minutes + " мин" : "00 мин")
      );
    }
  }

  // > 1 суток
  if (t > 86400) {
    const days = Math.floor(t / 86400);
    const hours = Math.floor((t % 86400) / 3600);
    const minutes = Math.round(((t % 86400) % 3600) / 60);
    if (byHours) {
      const hours = Math.round(t / 3600);
      return hours + " ч" + (putMinutes ? ` ${minutes} мин` : "");
    } else {
      return (
        (days > 0 ? days + " д " : "") +
        (hours > 0 ? " " + hours + " ч" : "00 ч") +
        (minutes ? " " + minutes + " мин" : "00 мин")
      );
    }
  }
};

// /**
//  * Подсчёт количества POI заданного типа маршрутe
//  *
//  * @param {array} list
//  * @param {integer} id
//  *
//  */
export const countPoi = (list, id) => {
  return list.filter((poi) => poi.cat_id === id || poi.poi_category_id === id)
    .length;
};

/**
 * Подсчёт времени на сон, отдых
 *
 * @param {array} list
 * @param {integer} id
 *
 */
export const countTimeStop = (list, purpose) => {
  return list.reduce((acc, poi) => {
    if (
      poi.purpose === purpose &&
      poi.place_index === 0 &&
      poi.recom &&
      poi.deltaTime
    ) {
      return acc + poi.deltaTime;
    }
    return acc;
  }, 0);
};

// /**
//  * Существование свойства
//  *
//  * @param {array} prop
//  *
//  */
// export const hasProperty = prop => {
//     var result = true;
//     if (prop === undefined) {
//         result = false;
//     }
//     return result;
// };

// /**
//  *
//  *  Максимум минимум из массива
//  *
//  * @param {array} numArray
//  * @param {string} key
//  *
//  */

// export const getMinMaxOfArray = (numArray, key) => {
//     if (key == "max") {
//         return Math.max.apply(null, numArray);
//     } else {
//         return Math.min.apply(null, numArray);
//     }
// };

// /**
//  *
//  *  Средняя цена расхода на топливо
//  *
//  * @param {array} prices
//  * @param {string} key
//  *
//  */

// export const averagePrice = prices => {
//     var avg = 0;

//     prices.map(price => {
//         avg = avg + price;
//     });

//     if (avg) {
//         return avg / prices.length;
//     }

//     return avg;
// };
