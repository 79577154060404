import { access } from "fs";
import { RouteInfo } from "multigo-map-api";
import { RefuelInfo } from "multigo-map-api/types/Controllers/Api/types";
import React from "react";
import { Chart } from "react-google-charts";
import { FuelType } from "src/components/RoutePanel/data/routeFilters";
import { distanceKm } from "../utils/converters";
// График
export const PriceChart = ({
  list,
  totalDistance,
  fuelPrice,
  refuelsList,
  fuelTypes,
  routePercenGas,
}: {
  list: RouteInfo["spread"];
  totalDistance: number;
  fuelPrice: number;
  refuelsList: RefuelInfo[];
  fuelTypes: FuelType[];
  routePercenGas: number;
}) => {
  const options = {
    hAxis: {
      title: "Расстояние, км",
      minValue: +Infinity,
      maxValue: -Infinity,
    },
    vAxis: {
      title: "Цена, руб",
      minValue: +Infinity,
      maxValue: -Infinity,
    },
    legend: "none",
    crosshair: {
      color: "green",
      trigger: "selection",
    },
    // curveType: "function",
    pointSize: 3,
  };
  const updateAxis = (key: string, value) => {
    if (value) {
      options[key].minValue = Math.min(options[key].minValue, value);
      options[key].maxValue = Math.max(options[key].maxValue, value);
    }
  };
  const data = list.map((obj) => {
    const km = Number[distanceKm(obj.dist, true).replace("км", "")];
    const price = obj.price;
    updateAxis("hAxis", km);
    updateAxis("vAxis", price);
    return [km, price];
  });

  const getFuelType = (id: number) => {
    return (fuelTypes.find((type) => type.ids.includes(id)) || { name: "" })
      .name;
  };
  const chartData = [["km", "price"], ...data];
  const row = (
    col1: string,
    col2: string,
    isRuble: boolean = false,
    style?: React.CSSProperties
  ) => (
    <div className="route-info__item">
      <div className="col_1" style={style}>
        {col1}
      </div>
      <div className="col_2">
        {isRuble && col2 ? <span className="ruble">{col2}</span> : col2}
      </div>
    </div>
  );
  const getMeanPrice = () => {
    return data.reduce(
      (acc, curr, idx) => {
        if (curr[1]) {
          acc[0] += curr[1];
          acc[1] += 1;
        }
        if (idx === data.length - 1 && acc[1]) {
          acc[2] = acc[0] / acc[1];
        }
        return acc;
      },
      [0, 0, 0]
    );
  };
  const perKm = (
    (refuelsList.reduce((acc, curr) => acc + curr.fuelPrice, 0) * 1000) /
    totalDistance
  ).toFixed(2);
  const routePercenGasStr =
    routePercenGas !== undefined ? `${routePercenGas}%` : "";

  const getRowsByFuel = (text: string, propName: string) => {
    const values = refuelsList.map((refuel) => ({
      value: refuel[propName] || "",
      fuelName: refuel.fuelName,
      fuelId: refuel.vcat,
    }));
    return values.map((value) =>
      React.cloneElement(
        row(
          `${text}${refuelsList.length >= 2 ? ` (${value.fuelName})` : ""}`,
          value.value,
          true
        ),
        { key: value.fuelId }
      )
    );
  };

  return (
    <div className="chart-block">
      <h2 className="chart-block__title">График цен по маршруту</h2>
      <div className="chart-block__info">
        {getRowsByFuel("Минимальная цена", "minPrice")}
        {getRowsByFuel("Максимальная цена", "maxPrice")}
        {getRowsByFuel("Средняя цена топлива", "avgPrice")}
        {row("Средняя цена расхода на 1 км", perKm, true, {
          letterSpacing: -0.5,
        })}
        {refuelsList &&
          refuelsList.length >= 2 &&
          row("Процент маршрута на газе", routePercenGasStr, false, {
            letterSpacing: -0.5,
          })}
      </div>
      <Chart
        chartType="LineChart"
        data={chartData}
        options={options}
        width="100%"
        height="300px"
      />
    </div>
  );
};
